body {
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #161513;
    background-color: #FBF9F8;
  }

.upper-div-customer-review{
    width: 100vw;
    height: auto;
    background-color: #32515E;
    padding: 8vh 6vw;
    color: #FFF;
    position: relative;
    z-index: 1;
}
.upper-div-customer-review::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    background-image: url(../image/customer/become-customer-background-overlay.png);
    background-size :  110% 100%;
    background-position-y: bottom;
    background-position-x: 40%;
    background-repeat: no-repeat;
    opacity: 0.5; 
    z-index: -1; 
}
.Customer-Review-heading{
    font-size:  32.256px;
    line-height: 38.7072px;
    font-weight: 400;
    width: 50vw;
}
.side-heading-become-customer-para{
    font-size: 14.336px;
    line-height: 20.0704px;
    font-weight: 400;
}

@media (max-width:600px) {
    body {
        font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
        color: #161513;
        background-color: #FBF9F8;
      }
    
    .upper-div-customer-review{
        width: 100vw;
        height: 35vh;
        background-color: #32515E;
        padding: 4vh 5vw;
        color: #FFF;
    }
    .upper-div-customer-review::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; 
        background-image: url(../image/customer/become-customer-background-overlay.png);
        background-size :  100%  100%;
        background-position-y: center;
        background-position-x: 0;
        background-repeat: no-repeat;
        opacity: 0.5; 
        z-index: -1; 
    }
    .Customer-Review-heading{
        font-size:  28.8px;
        line-height: 34.56px;
        font-weight: 400;
        width: 90vw;
    }
    .side-heading-become-customer-para{
        font-size: 12.8px;
        line-height: 17.92px;
        font-weight: 400;
    }
    
    
}





/* 2 */

.customer-review-cards-container{
    width: 100vw;
    height: auto;
    padding: 8vh 7vw;
    background-color: #F5F4F2;
}
.customer-review-cards-container-heading-main{
    font-size:  21.504px;
    line-height: 25.8048px;
    font-weight: 800;
}
.customers-review-card-wrapper{
    display: flex;
    gap: 20px;
}
.customer-review-card-1{
    background-color: #E4E1DD;
    width: 40vw;
    height: auto;
    margin-top: 1vh;
    border-radius: 8px;
}
.customer-review-card-1-img{
    background-color: #fff;
    width: 100%;
    height: 45%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.customer-review-content-wraper-of-card{
    padding: 3vh 2vw;
}
.customer-review-card-1-all-heading{
    font-size: 16.128px;
    line-height: 19.3536px;
    font-weight: 700;
}
.customer-review-card-1-all-para{
    font-size: 14.336px;
    line-height: 20.0704px;
    font-weight: 400;
}
.customer-review-card-1-all-btns{
    font-size: 12.544px;
    line-height: 15.0528px;
    font-weight: 500;
    border: none;
    background-color: var(--primary-color);
    padding: 13px 20px;
    color: #FFF;
    border-radius: 4px;
    outline: none;
    position: relative; 
}
.customer-review-card-1-all-btns:hover{
    background-color: #161513;
}
.customer-review-last-para{
    font-size: 12.544px;
    line-height: 17.5616px;
    font-weight: 400;
    padding-top: 10vh;
    margin-bottom: 5vh;
}
.customer-review-card-1-all-btns:focus::before {
    content: ""; 
    position: absolute;
    top: 2px; 
    left: 2px;
    right: 2px;
    bottom: 2px;
    border: 2px dotted rgb(255, 255, 255); 
    pointer-events: none; 
}

@media (max-width:600px) {
    .customer-review-cards-container{
        width: 100vw;
        height:auto;
        padding: 4vh 5vw;
        background-color: #F5F4F2;
    }
    .customer-review-cards-container-heading-main{
        font-size: 19.2px;
        line-height: 23.04px;
        font-weight: 800;
    }
    .customers-review-card-wrapper{
        display: flex;
        gap: 20px;
        flex-direction: column;
    }
    .customer-review-card-1{
        background-color: #E4E1DD;
        width: 90vw;
        height: auto;
        margin-top: 1vh;
        border-radius: 10px;
    }
    .customer-review-card-1-img{
        background-color: #fff;
        width: 100%;
        height: 40%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .customer-review-content-wraper-of-card{
        padding: 3vh 3vw;
    }
    .customer-review-card-1-all-heading{
        font-size:  14.4px;
        line-height: 17.28px;
        font-weight: 700;
    }
    .customer-review-card-1-all-para{
        font-size: 14.8px;
        line-height: 20.72px;
        font-weight: 400;
    }
    .customer-review-last-para{
        font-size: 12.544px;
        line-height: 17.5616px;
        font-weight: 400;
        margin-top: 10vh;
        margin-bottom: 5vh;
    }
}