body {
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #161513;
    background-color: #fff;
  }

.parent-investor-div{
  width: 100vw;
  height: 85vh;
  background-image: url(../image/developer-img/event-main-img.jpg);
  background-color: #E1AD74;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5vw;
  padding-right: 5vw;
}
.main-heading-of-investor{
    font-size: 48px;
    line-height: 57.6px;
    font-weight: 400;
}
.investor-btns1{
    font-size:13.75px;
    line-height: 42px;
    font-weight: 700;
    color: #fff;
    background-color: var(--primary-color);
    border: none;
    border-radius: 4vw;
    width: 8vw;
    height: 6.5vh;
    transition: all ease 1s;
}
.investor-btns1:hover{
  background-color: #377956;
}
.child-two-of-invesotr{
  background-color: #fff;
  width: 22vw;
  height: 60vh;
  padding-left: 2vw;
  padding-top: 6vh;
  padding-right: 1vw;
  border-radius: 9px;
}
.bold-investor-text{
  font-size:  24px;
  line-height: 28px;
  font-weight: 600;
}
.bold-investor-heading{
  font-size: 56px;
  line-height: 56px;
  font-weight: 800;
}
.bold-investor-para{
  font-size:  20px;
  line-height: 24px;
  font-weight: 600;
  width: 8.5vw;
  padding: 2px 2px;
  height: 4vh;
  background-color: #61815A;
  color: #FFF;
}
.para-and-bold-value{
  display: flex;
  justify-content: space-between;
}
.para-normal-inv{
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  text-align: start;
}
.para-bold-inv{
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
  text-align: end;
}
.small-para-inv{
  font-size:  12px;
  line-height: 26px;
  font-weight: 400;
}
.link-of-inv{
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-decoration: none;
  color: rgb(0, 104, 140) ;
}
.link-of-inv:hover{
  
  text-decoration:
  underline solid rgb(0, 104, 140);
}
.gradient-div-wrapper{
  background: linear-gradient(to bottom, #fff 45%, #E6E3E0 45%, #E6E3E0 100%);
  height: 120vh;
  width: 100vw;
  padding-left: 5vw;
  padding-top: 8vh;
  padding-right: 5vw;
}
.gradient-img{
  background-image: url(../image/developer-img/sec-div-bg-of-lineargradiaent.png);

}
.div-wraper-heading{
  margin-top: 3vh;
  font-size:  36px;
  line-height:  41px;
  font-weight: 400;
}
.wrapper-card-five{
  margin-top: 5vh;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

}
.inv-wrapper-card-1{
  width: 17vw;
  height: 27vh;
  background-color: #61815A;
  background-image: url(../image/developer-img/svg-bg.png);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.inv-div-card-links{
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
  color : #ebebeb;
  text-decoration: none;
}
.inv-div-card-links:hover{
  text-decoration: underline 2px solid #FFF;
}
.img-of-card-1{
  width: 40px;
  height: 70px;
}
.inv-div-side-by-side{
  margin-top: 15vh;
  display: flex;
  gap: 15vw;
}
.one-div-of-bottom-heading1{
  font-size: 24px;
  line-height:  28px;
  font-weight: 800;
}
.one-div-of-bottom-heading2{
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}
.one-div-of-bottom-link{
  font-size: 16px;
  line-height:  20px;
  font-weight: 400;
  color: rgb(0, 104, 140) ;
  text-decoration: none;
}
.one-div-of-bottom-link:hover{
  text-decoration: underline solid rgb(0, 104, 140);
}
.value-changing-div{
  width: 100vw;
  height: auto;
  background-color: #F6F6F6;
  padding: 10vh 5vw;
}
.value-changing-heading{
  font-size:  24px;
  line-height: 28px;
  font-weight: 800;
}
.value-changing-div-cards{
  display: flex;
  gap: 45px;
 
}
.value-cahnging-div-card-1{
  padding: 4px 4px;
  width: 15vw;
  height: auto;
}
.dynamic-heading-of-changing-div , .dynamic-heading-of-changing-div-arrow{
  font-size:  24px;
  line-height: 31px;
  font-weight: 800;
  color: rgb(95, 125, 79);
}
.dynamic-heading-normal-para{
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: rgb(49, 45, 42);
}
.dynamic-heading-of-changing-div-arrow::before{
  content: "<";
  font-size: 1.3rem; 
  color: inherit;
  display: inline-block;

}
.sec-last-div{
  width: 100vw;
  height: auto;
  background-color: #fff;
  padding: 10vh 5vw;
}
.sec-last-div-heading{
  font-size: 24px;
  line-height: 28px;
  font-weight: 800;
}
.sec-last-flex-div{
  display: flex;
  gap: 20px;
  margin-top: 3vh;
 
}
.sec-last-first-div , .sec-last-sec-div{
  width: 50vw;
  height: auto;
  display: flex;
  padding-top: 2vh;

}
.sec-last-first-div-img{
  width: 50%;
  height: 85%;
  background-size: cover;
 border-top-left-radius: 10px;
 border-bottom-left-radius: 10px;
}
.ul-heading{
  padding-top: 6vh;
  background-color: #E6E3E0;
  width: 50%;
  height: 85%;
  list-style: none;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.ul-heading li{
  margin-top: 2vh;
}
.last-div-of-investor{
  width: 100vw;
  height: auto;
  background-color: #6F8080;
  padding: 6vh 4vw;
}
.heading1-of-last-div{
  font-size: 22px;
  line-height: 24px;
  font-weight: 800;
  color: #FFF;
}
.heading2-of-last-div{
  margin-top: 4vh;
  font-size: 18px;
  line-height: 24px;
  font-weight: 800;
  color: #FFF;
}
.sing-up-email-box{
  display: flex;
  justify-content: space-between;
  padding-right: 8vw;
}

.last-para-of-investor1{
  margin-top: -3vh;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #FFF;
  margin-left: 2vw;
}
.last-para-of-investor2{
  margin-top: -3vh;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #FCDD9C;
  margin-left: 2vw;
}
.content-and-email-box{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.email-input-of-investor{
  width: 60vw;
  height: 8vh;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0 2vw;
  outline: none; 
  transition: border 0.3s ease;
}

.sign-in-btn-investor{
  border: 1px solid #FFF;
  margin-left: 0.5vw;
  width: 10vw;
  height: 7.6vh;
  color: #FFF;
  background-color: transparent;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  transition: all ease 1s;
}
.sign-in-btn-investor:hover{
  background-color: #fff;
  color: #000;
}
.email-input-of-investor:focus{
  border: 2px dotted #cfa31d;
}
.check-box-investor{
  display: flex;
  gap: 25px;
  color: #FFF;
}
.checkbox-input {
  -webkit-appearance: none;  
  -moz-appearance: none;
  appearance: none;
  width: 13px;
  height: 13px;
  border: 1px solid white; 
  border-radius: 0.5px; 
  background-color: transparent;
  transition: all 0.3s ease; 
  cursor: pointer;
  margin-right: 10px; 
}

.checkbox-input:checked {
  background-color: white; 
  border: 2px dotted rgb(227, 141, 56); 
}
.checkbox-input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5); 
}
.checkbox-label{
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
}
.unsubscribe{
  margin-left: 19.5vw;
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  color: #FCDD9C;
}
.last-flat-img-investor{
  width: 100vw;
  height: 1.5vh;
  background-image: url(../image/developer-img/flat-event.jpg);
}

@media (max-width:600px) {
  body {
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #161513;
    background-color: #fff;
  }

.parent-investor-div{
  width: 100vw;
  height: auto;
  background-image: url(../image/developer-img/event-main-img.jpg);
  background-color: #E1AD74;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: start;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
}
.main-heading-of-investor{
    font-size: 38px;
    line-height: 42px;
    font-weight: 400;
}
.investor-btns1{
    font-size:12.75px;
    line-height: 36px;
    font-weight: 700;
    color: #fff;
    background-color: var(--primary-color);
    border: none;
    border-radius: 4vw;
    width: 30vw;
    height: 5.5vh;
    transition: all ease 1s;
}
.child-two-of-invesotr{
  background-color: #fff;
  width: 80%;
  height: auto;
  margin-top: 2vh;
  margin-right: 15vw;
  padding-left: 2vw;
  padding-top: 2vh;
  padding-right: 2vw;
  padding-bottom: 2vh;
  border-radius: 5px;
}
.bold-investor-text{
  font-size:  21px;
  line-height: 28px;
  font-weight: 600;
}
.bold-investor-heading{
  font-size: 38px;
  line-height: 40px;
  font-weight: 800;
}
.bold-investor-para{
  font-size:  18px;
  line-height: 24px;
  font-weight: 600;
  width: 30vw;
  padding: 2px 2px;
  height: 3.5vh;
  background-color: #61815A;
  color: #FFF;
}
.para-and-bold-value{
  display: flex;
  justify-content: space-between;
}
.para-normal-inv{
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  text-align: start;
}
.para-bold-inv{
  font-size: 16px;
  line-height: 32px;
  font-weight: 700;
  text-align: end;
}
.small-para-inv{
  font-size:  12px;
  line-height: 26px;
  font-weight: 400;
}
.link-of-inv{
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-decoration: none;
  color: rgb(0, 104, 140) ;
}
.link-of-inv:hover{
  
  text-decoration:
  underline solid rgb(0, 104, 140);
}
.gradient-div-wrapper{
  background: linear-gradient(to bottom, #fff 45%, #E6E3E0 45%, #E6E3E0 100%);
  height: auto;
  width: 100vw;
  padding-left: 5vw;
  padding-top: 8vh;
  padding-right: 5vw;
}
.gradient-img{
  background-image: url(../image/developer-img/sec-div-bg-of-lineargradiaent.png);
}
.div-wraper-heading{
  margin-top: 3vh;
  font-size:  36px;
  line-height:  41px;
  font-weight: 400;
}
.wrapper-card-five{
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

}
.inv-wrapper-card-1{
  width: 90%;
  height: 23vh;
  background-color: #61815A;
  background-image: url(../image/developer-img/svg-bg.png);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.inv-div-card-links{
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
  color : #ebebeb;
  text-decoration: none;
}
.inv-div-card-links:hover{
  text-decoration: underline 2px solid #FFF;
}
.img-of-card-1{
  width: 40px;
  height: 70px;
}
.inv-div-side-by-side{
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  gap: 15vw;
  padding-bottom: 2vh;
}
.one-div-of-bottom-heading1{
  font-size: 24px;
  line-height:  28px;
  font-weight: 800;
}
.one-div-of-bottom-heading2{
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}
.one-div-of-bottom-link{
  font-size: 16px;
  line-height:  80px;
  font-weight: 400;
  color: rgb(0, 104, 140) ;
  text-decoration: none;
}

.value-changing-div{
  width: 100vw;
  height: auto;
  background-color: #F6F6F6;
  padding: 4vh 5vw;
}
.value-changing-heading{
  font-size:  24px;
  line-height: 28px;
  font-weight: 800;
}
.value-changing-div-cards{
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100vw;
  height: auto;
}
.value-cahnging-div-card-1{
  padding: 4px 4px;
  width: 100%;
  height: auto;
}
.dynamic-heading-of-changing-div , .dynamic-heading-of-changing-div-arrow{
  font-size:  24px;
  line-height: 31px;
  font-weight: 800;
  color: rgb(95, 125, 79);
}
.dynamic-heading-normal-para{
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: rgb(49, 45, 42);
}
.dynamic-heading-of-changing-div-arrow::before{
  content: "<";
  font-size: 1.3rem; 
  color: inherit;
  display: inline-block;

}
.sec-last-div{
  width: 100vw;
  height: auto;
  background-color: #fff;
  padding: 5vh 5vw;
}
.sec-last-div-heading{
  font-size: 21px;
  line-height: 28px;
  font-weight: 800;
}
.sec-last-flex-div{
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 3vh;
  height: auto;
 
}
.sec-last-first-div , .sec-last-sec-div{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 2vh;

}
.sec-last-first-div-img{
  width: 100%;
  height: auto;
  background-size: cover;
 border-top-left-radius: 10px;
 border-bottom-left-radius: 10px;
}
.ul-heading{
  padding-top: 2vh;
  background-color: #E6E3E0;
  width: 100%;
  height: auto;
  list-style: none;
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 2vh;
}
.ul-heading li{
  margin-top: 0vh;
}
.last-div-of-investor{
  width: 100vw;
  height: auto;
  background-color: #6F8080;
  padding: 4vh 4vw;
}
.heading1-of-last-div{
  font-size: 21px;
  line-height: 24px;
  font-weight: 800;
  color: #FFF;
}
.sing-in-2-div-investor{
  width: 100%;
  height: auto;
}

.heading2-of-last-div{
  margin-top: 2vh;
  font-size: 18px;
  line-height: 24px;
  font-weight: 800;
  color: #FFF;
}
.sing-up-email-box{
  display: flex;
  justify-content: start;
  flex-direction: column;
  padding-right: 8vw;
}

.last-para-of-investor1{
  margin-top: -3vh;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #FFF;
  margin-left: 0vw;
  padding-left: 3vh;
}
.last-para-of-investor2{
  margin-top: -2.6vh;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #FCDD9C;
  margin-left: 0vw;
  padding-left: 3.5vh;
}
.content-and-email-box{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.email-input-of-investor{
  width: 90vw;
  height: 5vh;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0 2vw;
  outline: none; 
  transition: border 0.3s ease;
}

.sign-in-btn-investor{
  border: 1px solid #FFF;
  margin-left: 0.5vw;
  width: 25vw;
  height: 5vh;
  color: #FFF;
  background-color: transparent;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  transition: all ease 1s;
  margin-top: 2vh;
}

.check-box-investor{
  display: flex;
  gap: 10px;
  flex-direction: column;
  color: #FFF;
}
.checkbox-input {
  -webkit-appearance: none;  
  -moz-appearance: none;
  appearance: none;
  width: 13px;
  height: 13px;
  border: 1px solid white; 
  border-radius: 0.5px; 
  background-color: transparent;
  transition: all 0.3s ease; 
  cursor: pointer;
  margin-right: 10px; 
}
.checkbox-input:checked {
  background-color: white; 
  border: 2px dotted rgb(227, 141, 56); 
}
.checkbox-input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5); 
}
.checkbox-label{
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
}
.unsubscribe{
  margin-left: 0vw;
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  color: #FCDD9C;
}
.last-flat-img-investor{
  width: 100vw;
  height: 1.5vh;
  background-image: url(../image/developer-img/flat-event.jpg);
}
  
}