.nav-events-pagination .page-item {
  margin: 0 5px;
}

.nav-events-pagination .page-link {
  color: rgb(0, 107, 143);
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.nav-events-pagination .page-item.active .page-link {
  color: #000;
  background-color: transparent;
}

.nav-events-pagination .page-item.disabled .page-link {
  color: #ccc;
  pointer-events: none;
}

.nav-events-pagination .page-link:first-child,
.nav-events-pagination .page-link:last-child {
  border: none;
}

.nav-events-pagination .nav-events-arrow {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0.3rem;
}

.nav-events-pagination .nav-events-arrow img {
  height: 24px;
  width: 24px;
}

.nav-events-pagination .nav-events-arrow:disabled {
  background-color: transparent;
  cursor: not-allowed;
}

.nav-event-type {
  font-size: 12px;
  color: #767472;
  font-weight: 700;
  margin-bottom: 1rem;
}

.nav-event-title {
  color: #006b8f !important;
  font-size: 18px;
}

.nav-event-date {
  color: #47423e;
  font-size: 16px;
}

.nav-event-separator {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.nav-event-register {
  padding: 0rem 0.5rem;
  font-size: 14px;
}

.nav-event-share {
  cursor: pointer;
}
.nav-event-share img {
  height: 18px;
  width: 18px;
}

.event-item {
  margin-bottom: 4rem;
}

.nav-events-dropdown {
  background: transparent;
  height: 3.5em;
  min-width: 200px;
  margin-left: 20px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #000;
  border-radius: 5px;
  color: #000;
  font-weight: 700;
  text-align: left;
}

.nav-event-show span {
  font-size: 20px;
  font-weight: 700;
}
.nav-event-show {
  margin-bottom: 2rem;
  padding-left: 0rem;
}

.nav-events-dropdown:hover {
  background-color: white;
  border-color: #ced4da;
}

.results-text {
  color: #000;
  font-size: 20px;
  padding-left: 0rem;
}

.dropdown-menu {
  min-width: 200px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  padding: 6px 12px;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

.nav-events-box {
  padding-left: 0px;
}



.nav-events-filter-items ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    gap: 1rem;
    padding-left: 0rem;
  }
  .nav-events-filter-items ul li {
    list-style: none;
    background-color: tranparent;
    cursor: pointer;
    color: #000;
    padding: 5px;
  }

  .nav-events-filter-items ul li:hover {
    background-color: #f8f9fa;
  }
  
  .nav-events-filter-items ul li:hover span:first-child {
    border-bottom: 1px solid #000;
  }
  
  .nav-events-filter-items ul li:hover span:last-child {
    border-bottom: 3px solid #000;
  }
  
  .nav-events-filter-items ul li span {
    margin-left: 1rem;
    margin-right: 0.5rem;
  }

  
 
.search-Lattice-events h2 {
    padding: 2rem;
    padding-left: 4.25rem;
    padding-bottom: 0;
    font-size: 2rem;
    margin-bottom: 0rem;
  }
  .search-Lattice-events p {
    padding-left: 4.25rem;
    text-align: left;
    font-size:  1rem;
    color:#006b8f;
  }


  @media only screen and (max-width: 850px){
    .nav-events-wrapper {
        padding-left:1rem;
      }

      .nav-events-filter-items ul {
        padding-left: 1rem;
      }

      .search-Lattice-events h2 {
        padding: 1rem;
        text-align: left;
      }
      .search-Lattice-events p {
        padding-left: 1rem;
        text-align: left;
      }

      .nav-events-filter-items ul li span {
        margin-left: 0rem;
        margin-right: 0.5rem;
    }

    .nav-event-show {
        padding-left: 1rem;
      }

      .results-text {
        padding-left: 1rem;
      }
}

.nav-events-bg{
  background-color: #fff;
}
