
  .coming-soon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background: linear-gradient(135deg, #1e3c72, #2a5298);
  }
  
  .coming-soon-content {
    max-width: 600px;
    padding: 20px;
  }
  
  .coming-soon-content h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    color: #ffffff;
  }
  
  .coming-soon-content p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #dcdcdc;
  }
  
  .countdown-timer {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .timer-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .timer-box .time {
    font-size: 2.5rem;
    font-weight: bold;
    color: #ffd700;
  }
  
  .timer-box .label {
    font-size: 1rem;
    color: #e0e0e0;
    text-transform: uppercase;
  }
  