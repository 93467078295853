.navbar-newnav .nav-newitem {
  margin-right: 1rem;
}

.navbar-newnav .new-nav {
  font-size: 0.85rem !important;
}

.newnavbar{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar-newitems-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.navbar-newnav {
  display: inline-block;
  white-space: nowrap;
  padding-left: 0.8rem;
}

.navbar-newnav .nav-newitem {
  display: inline-block;
}

@media (max-width: 768px) {
  .navbar-newitems-wrapper {
    overflow-x: auto;
    width: 100%;
  }

  .navbar-newnav {
    margin: 0;
    padding: 0;
  }

  .nav-newitem {
    display: inline-block;
    margin-right: 10px;
  }

  .new-newnav {
    white-space: nowrap;
  }
}

.customer-story-banner {
  background: url(../image/customer-story-background.avif);
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 22vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.customer-story-banner-wrapper {
  position: relative;
  background-color: #0a1f22;
}

.customer-story-content {
  color: #fff;
  max-width: 50%;
  position: absolute;
  top: 20%;
  left: 6rem;
}

.customer-story-heading {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

[class*="customer-story-heading"]:after {
  content: "";
  display: block;
  width: 40px;
  height: 4px;
  margin: 0.39285em 0 0.423em;
  background: #f0cc71;
}

.customer-story-para {
  font-size: 1.11rem !important;
  margin-bottom: 1rem;
}

.customer-story-btn {
  color: #161513;
  background-color: #fff;
  border-radius: 5px;
  padding: 0.5rem;
}

@media (max-width: 1250px) {
  .customer-story-banner {
    min-height: 28vw;
  }
}
@media (max-width: 1172px) {
  .customer-story-banner {
    min-height: 33vw;
  }
}
@media (max-width: 950px) {
  .customer-story-banner {
    min-height: 40vw;
  }
}
@media (max-width: 890px) {
  .customer-story-content {
    color: #fff;
    max-width: 90%;
    position: relative;
    top: 0rem;
    left: 1rem;
    padding: 1rem;
  }
}

@media only screen and (max-width: 770px) {
  .customer-story-banner {
    height: 33vw;
  }
}

.customer-story-info {
  padding: 50px;
}

.customer-story-info {
  position: relative;
  z-index: 1; /* Content layer */
}

.customer-story-info::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../image/rw-pattern16.avif);
  background-size: fill;
  background-position: center;
  opacity: 0.05;
  z-index: -1;
  pointer-events: none;
  mix-blend-mode: initial;
}

.customer-box {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #ddd;
  padding: 20px;
  text-align: left;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  color: #0a1f22;
  width: 300px;
}

.customer-box h5 {
  font-size: 3rem;
  font-weight: 800;
  display: block;
  color: #2c5266 !important;
  width: 100%;
  padding: 0;
  margin: 0;
}

.customer-box p {
  font-size: 1rem;
  font-weight: 800;
  display: block;
  width: 100%;
  color: #2c5266 !important;
}

.customer-flex {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 1rem;
  flex-wrap: wrap;
  width: 100%;
}

.customer-story-learn {
  background-color: #587b86;
  color: #fff;
  padding: 1.5rem 1rem;
}

.customer-story-learn p {
  font-weight: 800;
  margin: 0;
}
.customer-story-learn button {
  font-size: 0.85rem;
  padding: 0.5rem 1rem;
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: transparent;
  color: #fff;
}

@media only screen and (max-width: 720px) {
  .customer-flex {
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .customer-story-learn p {
    font-size: 0.9rem;
  }
}

.customer-closer-look h2 {
  padding: 2rem;
  padding-left: 4.25rem;
  font-size: 2rem;
}

.customer-stories-search-accordion {
  width: 100%;
  max-width: 300px;
  margin-left: 4.25rem;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}
.customer-stories-search-accordion-2 {
  display: none;
  width: 100%;
  max-width: 300px;
  margin-left: 4.25rem;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.customer-stories-search-bar {
  padding: 10px;
}

.customer-stories-search-input {
  width: 100%;
  padding: 8px 10px;
  border-radius: 4px;
  outline: none;
}

.customer-stories-search-icon {
  margin-left: 10px;
  font-size: 16px;
  color: #888;
}

.customer-stories-accordion-section {
  border-bottom: 1px solid #ddd;
}

.customer-stories-accordion-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.customer-stories-accordion-header-active {
  background-color: #f5f5f5;
}

.customer-stories-accordion-content {
  padding: 10px;
  background-color: #fafafa;
  border-top: 1px solid #eee;
}

.customer-stories-accordion-checkbox-label {
  display: block;
  font-size: 14px;
  background-color: #fbf9f8;
  padding: 10px;
}

.customer-stories-accordion-checkbox-label:hover {
  background-color: #e9e6e3;
  cursor: pointer;
}

.customer-stories-accordion-checkbox {
  margin-right: 8px;
}

.customer-stories-accordion-toggle-icon {
  font-size: 12px;
}
.customer-stories-accordion-toggle-icon img {
  height: 20px;
  width: 20px;
}

.customer-story-card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 2rem;
  margin-bottom: 2rem;
}

.customer-story-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 5px 12px 0px rgba(22, 21, 19, 0.2);
  max-width: 300px;
  flex: 1 1 calc(33.333% - 20px);
  padding: 20px;
  text-align: start;
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow: auto;
}

.customer-story-card-logo {
  max-width: 100px;
  margin: 0 auto 10px;
}

.customer-story-card img {
  height: 50px;
  width: 50px;
}

.customer-story-card-title {
  font-size: 1.125em;
  font-weight: bold;
  margin: 10px 0;
}

.customer-story-card-info {
  font-size: 0.75em;
  color: #7a736e;
  margin-bottom: 0;
}

.customer-info-2 {
  margin-bottom: 1rem;
}

.customer-story-card-button {
  margin-top: auto;
  padding: 10px 6px;
  font-size: 0.75rem;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 90px;
  background: transparent;
  border: 1px solid #000;
}

/* Responsive design */
@media (max-width: 768px) {
  .customer-story-card {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .customer-story-card {
    flex: 1 1 100%;
  }
}

.customer-filter-flex {
  gap: 3rem;
  display: flex;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.customer-filter-flex-2 {
  gap: 1rem;
  display: flex;
  justify-content: start;
  align-items: start;
  width: 100%;
}

.customer-story-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 13%;
}
.customer-view-more p {
  color: #7a736e;
}

.customer-view-more {
  text-align: center;
  cursor: pointer;
}

.customer-stories-filter-accordion {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70vh;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  overflow-y: auto;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.customer-stories-filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  border-radius: 10px 10px 0 0;
}

.customer-stories-filter-header h4 {
  margin: 0;
  font-size: 16px;
}

.customer-stories-filter-done {
  background-color: #1b1b1c;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}

.filter-text {
  pointer-events: none;
}

.customer-stories-search-wrapper {
  display: flex;
  position: relative;
  border: 1px solid #7a736e;
  border-radius: 4px;
  padding: 0.6em;
  background-color: #fff;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.customer-stories-search-icon {
  margin-right: 1rem;
  font-size: 1.2rem;
}

.customer-stories-search-icon img {
  height: 22px;
  width: 22px;
}

.customer-stories-search-input {
  border: none;
  outline: none;
  flex: 1;
  font-size: 1rem;
  padding: 0;
}

@media (max-width: 1100px) {
  .customer-story-card-list {
    flex-wrap: wrap;
    justify-content: center;
    align-items: start;
  }

  .customer-story-filter {
    margin-right: 1rem;
  }
}

.filter-items ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;
  gap: 1rem;
  padding-left: 0rem;
}
.filter-items ul li {
  list-style: none;
  background-color: #3a3632;
  cursor: pointer;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.filter-items ul li span {
  margin-left: 1rem;
  margin-right: 0.5rem;
}

.clear-all {
  cursor: pointer;
  color: #006b8f;
}

@media (max-width: 850px) {
  .filter-text {
    pointer-events: visible;
    cursor: pointer;
  }

  .customer-filter-flex {
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }

  .customer-stories-new-accordian {
    display: none;
  }

  .customer-stories-search-accordion {
    max-width: 100%;
    margin-left: 0;
    padding: 1rem;
    border: none;
    background-color: transparent;
    box-shadow: none;
  }

  .customer-closer-look h2 {
    padding: 1rem;
    text-align: center;
    font-size: 2rem;
  }

  .filter-text {
    padding-left: 1rem;
  }

  .customer-filter {
    width: auto;
  }

  .customer-stories-accordion-content {
    max-width: 100%;
    background: white;
    overflow-y: auto;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    max-height: 0;
    padding: 0 16px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid #ddd;
  }

  .customer-stories-accordion-content.active {
    opacity: 1;
    max-height: 300px;
    padding: 16px;
  }

  .done-button {
    display: inline-block;
    margin-top: 16px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }

  .done-button:hover {
    background-color: #0056b3;
  }

  .customer-stories-search-accordion-2 {
    display: none;
    width: 100%;
    max-width: 300px;
    margin-left: 4.25rem;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }

  .filter-items ul {
    padding-left: 1rem;
  }

  .customer-stories-search-bar {
    padding: 0px;
  }
}
