body {
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #161513;
    background-color: #fff;
  }



.upper-div-event{
    width: 100vw;
    height: 62vh;
    background-color: #3C4544;
    background-image: url(../image/developer-img/event-bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
    padding-left: 5vw;
    padding-top: 2vh;
   
}

.upper-div-content{
    width: 50%;
    height: 100%;
    color: #fff;
}
.main-heading-of-event{
   padding-top: 8vh;
   font-size: 32.256px; 
   line-height:  38.7072px; 
   font-weight: 400;
}
.side-heading-event-para{
    font-size:  16.128px ; 
    line-height: 22.5792px;
    font-weight: 400;
}

.Link-of-event{
    position: relative;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    top: 6px;
  }

  .Link-of-event:hover{
    text-decoration:
    underline solid rgb(255, 255, 255);
  }

  .Link-of-event .arrow-eventpage svg {
    position: absolute;
    top: -2px ;
    margin-left: -5px;  
  
  }
  @media (max-width:600px) {
    .Link-of-event {
        display: none;
      }
  }

  .sec-div-of-event-outer{
    width: 100vw;
    height: 55vh;
    background-color: #2A2F2F;
    padding-left: 5vw;
    padding-top: 6vh;
    color: #fff;
  }
  .sec-heading{
    font-size:21.504px;
    line-height:25.8048px;
    font-weight:800;
    margin-bottom: 2vh;
  }
  .sec-div-inner-div{
    width: 30%;
    height: 78%;
    background-color: #464F4F;
    border-radius: 8px;
    padding-left: 1.5vw;
    padding-top: 3vh;
  }
  .sec-div-inner-div-heading{
    font-size: 12.544px;
    line-height: 17.5616px;
    font-weight: 700;
    width: 4.3vw;
    height: 3.8vh;
    background-color: #4F7D7B;
    border-radius: 8px;
    padding: 4px 8px;
   
  }
  .sec-div-inner-div-bold-para{
    font-size:  16.128px;
    line-height: 19.3536px;
    font-weight: 700;
    padding-top: 1vh;
  }
  .sec-div-inner-div-normal-para{
    font-size:14.336px;
    line-height: 20.0704px;
    font-weight: 400;
  }
  .third-div-of-event{
    width: 100vw;
    height: 23vh;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
  }
  .third-div-event-heading{
    font-size: 23.296px;
    line-height: 27.9552px;
    font-weight:  400;
  }
  .social-media-icon{
   display: flex;
   gap: 75px;
  }


  @media (max-width:786px) {

    body {
      font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
      color: #161513;
      background-color: #fff;
    }
  
  
  
  .upper-div-event{
      background-position-y: bottom;
      padding-left: 4vw;
      padding-top: 0vh;
  } 
  .upper-div-content{
      width: 100%;
      height: 100%;
      color: #fff;
  }
  .main-heading-of-event{
     padding-top: 4vh;
  }
    .sec-div-of-event-outer{
      width: 100vw;
      height: auto;
      background-color: #2A2F2F;
      padding-left: 0vw;
      padding-top: 2vh;
      padding: 6vh 6vw;
      color: #fff;
    }
    .sec-div-inner-div{
      width: 100%;
      height: 100%;
      background-color: #464F4F;
      border-radius: 8px;
      padding-left: 0vw;
      padding-top: 0vh;
      padding: 3vh 3vw;
    }
    .sec-div-inner-div-heading{
      font-size: 12.544px;
      line-height: 17.5616px;
      font-weight: 700;
      width: 18vw;
      height: 2.8vh;
      background-color: #4F7D7B;
      border-radius: 8px;
      padding: 4px 8px;
     
    }
    .sec-div-inner-div-bold-para{
      font-size:  16.128px;
      line-height: 19.3536px;
      font-weight: 700;
      padding-top: 1vh;
    }
    .third-div-of-event{
      height: auto;
      flex-direction: column;
    }
    .social-media-icon{
     display: flex;
     flex-wrap:wrap ;
     gap: 55px;
    }
    
  }