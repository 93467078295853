html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
}
body {
    background-color: #000000;
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #ffffff;
    overflow-x: hidden;
  }
.main{
    min-height: 100vh;
    background-color:#F1EFED; 
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #000000;
}
.image-aboutpage{
    width: 100vw;
    height: 65vh;
    background-image: url(./image/About-bg-img.jpg);
    background-size: 100% 100%;
    position: relative;
}
.image-aboutpage h1{
    width: 50vw;
    padding-top: 2vh;
    padding-left: 2vh;
    font-size:28px;
    line-height:36px;
    vertical-align:baseline;
    letter-spacing:normal;
    font-weight:500;
}
    .image-aboutpage span{
    position: absolute;
    top: 37%;
    left: 5%;
    }
.about-heading{
    width: 100vw;
    height: 10vh;
    text-align: start;
    font-size:21px;
    line-height:25.8048px;
    vertical-align:baseline;
    letter-spacing:normal;
    font-weight:800;
    padding-left: 8vw;
    padding-top: 6vh;
    padding-right: 6vw;
}

.Link-of-about{
  position: relative;
  left: 5vw;
  font-size: 16px;
  top: 22px;
  color: #000000;
}

.Link-of-about:hover{
  text-decoration:
  underline solid rgb(22, 21, 19);
}

.Link-of-about .arrow-aboutpage svg {
  position: absolute;
  top: -9px ;
  margin-left: 30px;  
  color: white !important;

}
.about-links{
  color: inherit;
  text-decoration: none;
}

.card-about {
    width: 100vw;
    height: 75vh;
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    padding-left: 7%;
    padding-right: 7%;
    position: relative;
    margin-top: 2.5vh;
}

.card1 {
   
    padding: 5vh;
    /* background: linear-gradient(90deg, #343996 0%, #9664a7 35%, #704fa0 66%, #423e98 100%);    width: 48%;  */
    background-color: #fff;
    height: 70%;
    margin: 1vh;
    border-radius: 10px;
    box-shadow: 0px 1px 16px 0 rgba(0, 0, 0, .18);
}

.card2 {
    /* background-color:#121d1e ; */
    padding: 5vh;
    /* background: linear-gradient(90deg, #343996 0%, #9664a7 35%, #704fa0 66%, #423e98 100%); */
    width: 48%; 
    background-color: #fff;
    height: 70%;
    margin: 1vh;
    border-radius: 10px; 
    box-shadow: 0px 1px 16px 0 rgba(0, 0, 0, .18);
}
.card1 h3 , .card2 h3 {
    font-size:21px;
    line-height:25.8048px;
    vertical-align:baseline;
    letter-spacing:normal;
    font-weight:800;
    margin-left: 1vw;
    margin-bottom: 2.5vh;
} 

.about-grid {
    padding-top: 1%;
    padding-bottom: 15vh;
    width: 100vw;
    height: 75vh;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 2 columns */
    grid-template-rows: repeat(2, 1fr);    /* 2 rows */
    padding-left: 4.3vw;
    padding-right: 7vw;
   
  }
  .about-wrapper h1{
    position: absolute;
    top: 2%;
    left: 7.8%;
    font-size:28.672px;
    line-height:34.4064px;
    vertical-align:baseline;
    letter-spacing: normal;
    word-spacing:0px;
    font-weight:800;
    font-style:normal;
    }
  
  .grid-item {
    width: 25vw;
    height: 10vh;
    padding: 20px;
    margin-right: 2vw;
    margin-left: 2vw;
    display: flex;
    flex-direction: column;
    margin-top: 15vh;
  
  }
  .grid-item-heading {
    border-bottom: 3px solid #1c1c1c; 
    padding-bottom: 10px;
    margin-bottom: 15px;
    cursor: pointer;
    text-align: start;
    transition: color 0.3s ease;
    font-size:21.504px;
    line-height:26.88px;
    vertical-align:baseline;
    letter-spacing:normal;
    word-spacing:0px;
    font-weight:800;
    font-style:normal;
  }
  
  .grid-item-heading:hover {
    color: #006B8F; 
    border-color: #006B8F; 
  }
  
  .description {
    font-size: 16px;
    text-align: start;
    color: #666;
  }

  .about-wrapper{
    position: relative;
    margin-bottom: 10vh;
  }

  .about-partner{
   position: relative;
    width: 100vw;
    height: 15vh;
    background-color:#053242;

  }
  .about-partner h1 {
    color: #ffffff;
    font-size:28px;
    line-height:25.088px;
    vertical-align:baseline;
    letter-spacing:normal;
    word-spacing: 0px;
    font-weight: 800;
    font-style:normal;
    position: absolute;
    top: 35%;
    left: 20%;
  }

.partner-btn{
  display: flex;
  justify-content: center;  
  align-items: center;   
  position: absolute;   
  background-color: #fff;
  top: 32%;
  left: 70%;
  height: 5vh;
  border: none;
  border-radius: 3px;
  font-size:14px;
  font-weight: 400;
}



  /* Responsive Styles for Mobile */
@media (max-width: 768px) {
  /* Prevent Horizontal Scrolling */
  body, html {
    overflow-x: hidden; /* Disable horizontal scrolling */
    width: 100%;
  }

  /* General Wrapper Adjustments */
  .main, .image-aboutpage, .card-about, .about-grid, .about-partner {
    width: 100%; 
    box-sizing: border-box; 
    margin: 0; 
    padding: 0; 
  }

  /* Image and Heading Section */
  .image-aboutpage {
    height: 40vh; 
    background-position: center;
    background-position-x: -160vw;
    background-position-y: -7vh;
    background-size: 276% 100%;
    background-color: rgba(0, 0, 0, 0.4); 
  }

  .image-aboutpage h1 {
    font-size: 18px; 
    line-height: 24px;
    width: 100vw; 
    height: 18.3vh;
    position: absolute;
    left: -5vw;
    top: 7vh;
    overflow-y: hidden;
    color: #fff; 
    background-color: rgba(0, 0, 0, 0.4); /* Text background overlay */
    text-align: center; 
  }


  /* Card Section */
  .card-about {
    flex-direction: column; 
    width: 90%;
    height: 90vh; 
    padding-left: 2vw;
    margin-left: 4vw;
  }
  .about-heading{
    font-size: 16px;
    margin-bottom: 20vh;
  }

  .card1, .card2 {
    width: 100%; /* Make cards fit screen */
    margin: 1rem 0; 
    
  }
  .about-wrapper h1 {
    position: absolute;
    top: 8vh;
  }
  /* About Grid Section */
  .about-grid {
    width: 100%;
    height: auto;
    margin-top: 17vh;
    display: grid;
    grid-template-columns: 1fr; 
    grid-template-rows: repeat(4, auto);   
    padding: 0.5rem 2rem; 
  }
  .about-grid :last-child{
    margin-bottom: 15vh;
  }
  .about-grid :first-child{
    margin-top: 8vh;
  }

  .grid-item {
    width: 100%; /* Fit items to the screen */
    height: 7vh;
    padding-right: 15vw;
  }

  .grid-item-heading {
    font-size: 18px; /* Adjust font size */
    text-align: center; /* Align heading text */
    border-bottom: 2px solid #1c1c1c; /* Adjust border size */
    padding-bottom: 0.5rem;
  }

  .description {
    font-size: 14px; /* Adjust font size for smaller screens */
    color: #666;
  }

  /* About Partner Section */
  .about-partner {
    position: relative;
    height: 16.5vh; 
    background-color: #053242; 
    display: flex;
    flex-direction: column;  
  }

  .about-partner h1 {
    font-size: 20px; /* Adjust font size */
    line-height: 24px;
    position: absolute;
    left: 5vw;
    top: 2.5vh;
  }

  .partner-btn{
    top: 10vh;
    left: 5vw;
    height: 4vh;
  }
  
}
