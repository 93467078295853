body {
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #161513;
    background-color: #fff;
  }

  /* 1-div */

.become-partners-first-div{
    width: 100vw;
    height: auto;
    display: flex;
    background-image: url(../image/partner/become-partner-overlay-bg-img.webp);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    gap: 10vw;
    background-color: #1F3A4B;
    color: #FFF;
    padding-top: 16vh;
    padding-left: 6vw;
    padding-right: 5vw;
    align-items: center;
    justify-content: space-between;
}
.become-partners-first-div-child-1{
    width: 50vw;
    height: 80%;
}
.become-partners-first-div-heading{
    font-size:  32.256px;
    line-height: 38.7072px;
    font-weight: 400;
}
.become-partners-first-div-paragraph{
    font-size: 14.336px;
    line-height: 20.0704px;
    font-weight: 400;
}
.become-partners-first-div-child-2{
    width: 30vw;
    height: 80%;
    padding-right: 7vw;
}
.become-partners-first-div-child-2-img{
    background-color: #fff;
    width: 25vw;
    height: 30vh;
    margin-bottom: 1vh;
    border-radius: 8px;
}

@media (max-width:600px) {

    body {
        font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
        color: #161513;
        background-color: #fff;
      }
    
.become-partners-first-div{
    height: auto;
    flex-direction: column;
    gap: 10vw;
    padding-top: 0vh;
    padding-left: 0vw;
    padding-right: 0vw;
    padding: 4vh 4vw;
}
.become-partners-first-div-child-1{
    width: 100vw;
    height: 80%;
    padding-top: 5vh;
    padding-left: 5vw;
    padding-right: 4vw;
}
.become-partners-first-div-heading{
    font-size:  30px;
    line-height: 36px;
    font-weight: 400;
}

.become-partners-first-div-child-2{
    width: 100vw;
    height: 80%;
    padding-right: 4vw;
    padding-left: 4vw;
}
.become-partners-first-div-child-2-img{
    width: 90vw;
    height: auto;
}

}

/* 2-div */

.become-partners-sec-div-dynamic-content{
    width: 100vw;
    height: auto;
    background-color: #FFFFFF;
 
}
.become-partners-dynamic-wrapper{
    width: 100%;
    height: 100%;
    padding-left: 6vw;
    padding-right: 6vw;
    padding-top: 6vw;
}
.become-partners-dynamic-island{
    display: flex;
    gap: 25px;
    border-bottom: 2px solid #daddad;
    position: relative;
}
.become-partner-dynamic-island-item-1{
    margin-bottom: 1.5vh;
    border: none;
    background-color: transparent;
    font-size: 14.336px;
    line-height: 20.0704px;
    font-weight: 700;
    position: relative;
    border-bottom: 2px solid transparent;
}
.become-partner-dynamic-island-item-1::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -13px; 
    height: 2px;
    background-color: transparent;
    transition: background-color 0.3s ease;
}
.become-partner-dynamic-island-item-1:hover {
  text-decoration: underline 1px solid #000000;
}
.become-partner-dynamic-island-item-1.active-section::after {
    background-color: green;
}
.become-partners-dynamic-content-area{
    padding-top: 8vh;
}
.become-partners-sec-div-all-card-wrapper{
    display: flex;
    gap: 25px;
    margin-top: 2vh;
}
.become-partners-sec-div-cards-card-1{
    width: 20vw;
    height: 45vh;
    background-color: #F1EFED;
    border-radius: 8px;
}
.become-partners-card-1-all-img{
   width: 100%;
   height: 58%;
   background-color: #fff;
   border-top-left-radius: 8px;
   border-top-right-radius: 8px;
   border: none;
   margin-bottom: 1.5vh;
   outline: none;
}
.become-partners-card-1-content-wrapper{
    padding: 0 2vw;
    padding-bottom: 2vh;
}
.become-partners-card-1-all-heading{
    font-size: 14.336px;
    line-height: 17.2032px;
    font-weight: 800;
}
.become-partners-card-1-all-para{
    font-size: 14.336px;
    line-height:  20.0704px;
    font-weight: 400;
}
.become-partners-card-of-list-2-wrapper{
    display: flex;
    gap: 25px;
    width: 100%;
    height: 100%
}
.become-partners-sec-div-list-2-card-1{
    width: 50% ;
    height: 35vh;
    margin-top: 5vh;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1);
    padding: 3vh 3vw;
    border-radius: 8px;
}
.become-partners-list-2-card-1-all-heading{
    font-size: 16.128px;
    line-height: 19.3536px;
    font-weight: 700;
}
.become-partners-list-2-card-1-all-para{
    font-size: 14.336px;
    line-height: 24.0704px;
    font-weight: 400;
    position: relative;
    list-style: none;
}
.become-partners-list-2-card-1-all-para::before {
    content: "●"; 
    color: black;
    font-weight: bold;
    display: inline-block;
    width: 1em; 
    margin-right: 10px;
}
.become-parteners-dyanmic-content-2-div{
    width: 100vw;
    height: auto;
    background-color:#F1EFED;
    margin-top: 7vh;
    position: relative;
    left: -6vw;
    padding: 6vh 6vw;
}
.become-parteners-dyanmic-content-2-div-card-wrapper{
    display: flex;
    gap: 25px;
}
.become-parteners-dyanmic-content-2-div-card-1{
    display: flex;
    gap: 2px;
    width: 30vw;
    height: 20vh;
    padding-top: 4vh;
}
.become-partners-side-by-side-img1{
    width: 3vw;
    height: 5vh;
}
.become-partners-side-by-side-content-heading{
    font-size: 25.088px;
    line-height: 30.1056px;
    font-weight: 800;
}
.become-partners-side-by-side-content-img-div{
    width: 100%;
    height: 30vh;
}
.become-partners-1-list-extended-box-2{
    width: 100vw;
    height: 80vh;
   display: flex;
   gap: 25px;
    margin-top: 15vh;
    padding-right: 5vw;
}
.become-partners-1-list-extended-child-1{
    padding: 4vh 2vw;
    width: 45%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1);
    border-radius: 8px;
}
.become-partners-1-list-extended-child-1-all-main-heading{
    font-size: 21.504px;
    line-height: 25.8048px;
    font-weight: 600;
}
.become-partners-1-list-extended-child-1-all-para-heading{
    font-size: 14.336px;
    line-height: 22.0704px;
    font-weight: 700;
    margin-top: 15px;
}
.become-partner-list-1-last-div-para-btn{
    background-color: #D6E5E5;
    width: 100vw;
    height: 15vh;
    position: relative;
    left: -6vw;
    display: flex;
    gap: 25px;
    justify-content: center;
    align-items: center;
}
.become-partner-list-1-last-div-heading{
    font-size:17.92px;
    line-height: 25.088px;
    font-weight: 800;
}
.become-partner-list-1-last-div-btn{
    border: none;
    background-color: var(--primary-color);
    width: 10vw;
    height: 5.5vh;
    font-size: 13px;
    line-height: 15.0528px;
    font-weight: 600;
    color: #FFF;
    border-radius: 4px;
    outline: none;
    position: relative; 
}
.become-partner-list-1-last-div-btn:hover{
    background-color:#161513;
}
.become-partner-list-1-last-div-btn:focus::before {
    content: ""; 
    position: absolute;
    top: 2px; 
    left: 2px;
    right: 2px;
    bottom: 2px;
    border: 1.5px dotted rgb(255, 255, 255); 
    pointer-events: none; 
}
.become-partner-list-2-last-div-para-btn{
    background-color: #D6E5E5;
    width: 100vw;
    height: 15vh;
    position: relative;
    left: -6vw;
    display: flex;
    gap: 25px;
    justify-content: center;
    align-items: center;
    margin-top: 8vh;
}

@media (max-width:600px) {

    
.become-partners-sec-div-dynamic-content{
    width: 100vw;
    height: auto;
    background-color: #FFFFFF;
 
}
.become-partners-dynamic-wrapper{
    width: 100%;
    height: 100%;
    padding-left: 6vw;
    padding-right: 6vw;
    padding-top: 6vw;
}
.become-partners-dynamic-island{
    gap: 10px;
}
.become-partner-dynamic-island-item-1{
    margin-bottom: 1.4vh;
    font-size: 12px;
    line-height: 19px;
    font-weight: 700;
}
.become-partner-dynamic-island-item-1::after {
    bottom: -14px; 
}
.become-partners-dynamic-content-area{
    padding-top: 8vh;
}
.become-partners-sec-div-all-card-wrapper{
    display: flex;
    gap: 25px;
    flex-direction: column;
    margin-top: 2vh;
}
.become-partners-sec-div-cards-card-1{
    width: 90%;
    height: auto;
    
}

.become-partners-card-1-content-wrapper{
    padding: 0 2vw;
    padding-bottom: 2vh;
}
.become-partners-card-1-all-heading{
    margin-top: 2vh;
}

.become-partners-card-of-list-2-wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.become-partners-sec-div-list-2-card-1{
    width: 100% ;
}
.become-parteners-dyanmic-content-2-div{
    width: 100vw;
    height: auto;
    background-color:#F1EFED;
    margin-top: 7vh;
    position: relative;
    left: -6vw;
    padding: 6vh 6vw;
}
.become-parteners-dyanmic-content-2-div-card-wrapper{
    display: flex;
    flex-direction: column;
}
.become-parteners-dyanmic-content-2-div-card-1{
    display: flex;
    gap: 10px;
    width: 100%;
    height: 15vh;
    padding-top: 4vh;
}
.become-partners-side-by-side-img1{
    width: 7vw;
    height: 5vh;
}
.become-partners-side-by-side-content-heading{
    font-size: 21px;
    line-height: 26px;
    font-weight: 800;
}
.become-partners-side-by-side-content-img-div{
    width: 100%;
    height: 30vh;
}
.become-partners-1-list-extended-box-2{
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 10vh;
    padding-right: 5vw;
}
.become-partners-1-list-extended-child-1{
    width: 90%;
    height: 100%;
    padding: 4vh 5vw;
}
.become-partner-list-1-last-div-para-btn{
    padding: 2vh 4vw;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: start;
    align-items: start;
    height: auto;
}
.become-partner-list-1-last-div-heading{
    font-size:16px;
    line-height: 20px;
    font-weight: 800;
}

.become-partner-list-1-last-div-btn{
    border: none;
    background-color: var(--primary-color);
    width: 35vw;
    height: 5vh;
    font-size: 12px;
    line-height: 15.0528px;
    font-weight: 600;
    color: #FFF;
    border-radius: 4px;
    outline: none;
    position: relative; 
}
.become-partner-list-2-last-div-para-btn{
    padding: 2vh 4vw;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: start;
    align-items: start;
    margin-top: 8vh;
}
    
}

.become-partners-list-1-third-div{
    width: 100vw;
    height: auto;
    background-color: #FBF9F8;
    position: relative;
    margin-left: -6vw;

}
.become-partners-list-1-3-div-heading{
    font-size: 21.504px;
    line-height: 25.8048px;
    font-weight: 800;
    padding: 4vh 6vw;
}
/* .become-partners-list-1-3-div-cards-wrapper{
    display: flex;
    gap: 55px;
    width: 100%;
    height: 25vh;
    padding: 0vh 6vw;
}
.become-partners-list-1-3-div-card-1{
    background-color: #fff;
    border-radius: 8px;
    width: 28vw;
    height: 100%;
} */
.become-partners-list-1-3-div-cards-2-wrapper{
    display: flex;
    gap: 25px;
    width: 100vw;
    height: auto;
    padding: 4vh 6vw;
    /* margin-top: 15vh; */
}
.become-partners-list-1-3-div-card-2{
    width: 30vw;
    height: auto;
    background-color: #fff;
    padding: 6vh 2vw;
    border-bottom: 4px solid #47423E;
}
.become-partners-card-2-img-logos{
    width: 12vw;
    height: auto;
    margin-bottom: 6vh;
}
.become-partners-card-2-of-div-3-all-special-para{
    font-size: 21.504px;
    line-height: 30.1056px;
    font-weight: 400;
}
.become-partners-card-2-of-div-3-all-normal-para{
    font-size: 16.128px;
    line-height: 22.5792px;
    font-weight: 400;
}
.large-quotes {
    font-size: 1.5em; 
    vertical-align: top; 
}
.large-dash {
    font-size: 1em; 
    margin-right: 5px; 
    display: inline-block; 
    vertical-align: middle; 
}
.become-partner-list-1-sec-last-div{
    width: 100vw;
    height: auto;
    background-color: #F1EFED;
    padding: 4vh 6vw;
    margin-left: -6vw;
    position: relative;
}
.become-partners-list-1-sec-last-div-heading{
    font-size: 21.504px;
    line-height: 25.8048px;
    font-weight: 800;
    margin-bottom: 4vh;
}
.become-partner-list-1-sec-last-div-logo-wrapper{
    width: 100vw;
    height: auto;
    display: flex;
    column-gap: 13vw;
    row-gap: 55px;
    flex-wrap: wrap;
    padding: 4vh 8vw;
}
.become-partner-list-1-sec-last-div-logo-1{
    width: 12vw;
    height: auto;
}
.become-partners-end-div{
    width: 100%;
    height: 15vh;
    border-radius: 8px;
    background-color: #BFD3DC;
    margin-top: 6vh;
    margin-bottom: 6vh;
    padding: 6vh 3vw;
}
.become-partners-end-div-para{
    font-size: 17.92px;
    line-height: 21.504px;
    font-weight: 600;
}

@media (max-width:600px) {
    .become-partners-list-1-3-div-heading{
        font-size: 19px;
        line-height: 22px;
        font-weight: 800;
        padding: 4vh 4vw;
    }
    /* .become-partners-list-1-3-div-cards-wrapper{
        display: flex;
        gap: 55px;
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 0vh 8vw;
    }
    .become-partners-list-1-3-div-card-1{
        width:  90%;
        height: 100%;
    } */
    .become-partners-list-1-3-div-cards-2-wrapper{
        display: flex;
        gap: 25px;
        width: 100vw;
        flex-direction: column;
        height: auto;
        padding: 0vh 6vw;
        padding-bottom: 4vh;
    }
    .become-partners-list-1-3-div-card-2{
        width: 100%;
        height: auto;
     
    }
    .become-partners-card-2-img-logos{
        width: 40vw;
        height:auto;
        margin-bottom: 6vh;
    }
    .become-partners-card-2-of-div-3-all-special-para{
        font-size: 21.504px;
        line-height: 30.1056px;
        font-weight: 400;
    }
    .become-partners-card-2-of-div-3-all-normal-para{
        font-size: 16.128px;
        line-height: 22.5792px;
        font-weight: 400;
    }
    .large-quotes {
        font-size: 1.5em; 
        vertical-align: top; 
    }
    .large-dash {
        font-size: 1em; 
        margin-right: 5px; 
        display: inline-block; 
        vertical-align: middle; 
    }
    .become-partner-list-1-sec-last-div{
        width: 100vw;
        height: auto;
        background-color: #F1EFED;
        padding: 4vh 2vw;
        margin-left: -6vw;
        position: relative;
    }
    .become-partners-list-1-sec-last-div-heading{
        font-size: 20px;
        line-height: 24px;
        font-weight: 800;
        margin-bottom: 2vh;
    }
    .become-partner-list-1-sec-last-div-logo-wrapper{
        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: column;
        column-gap: 10vw;
        row-gap: 65px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 4vh 2vw;
    }
    .become-partner-list-1-sec-last-div-logo-1{
        width: 50%;
        height: auto;
    }
    .become-partners-end-div{
        width: 100%;
        height: auto;
        border-radius: 8px;
        background-color: #BFD3DC;
        margin-top: 6vh;
        margin-bottom: 6vh;
        padding: 6vh 3vw;
    }
    .become-partners-end-div-para{
        font-size: 17.92px;
        line-height: 21.504px;
        font-weight: 600;
    }
}