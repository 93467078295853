.customer-details-container {
    padding: 20px;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    color: #000;
  }
  
 .customer-details-container h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 32px;
    font-weight: 700;
  }
  
  .user-table table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .user-table th, .user-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #3d3b3b;
  }
  
  .user-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .user-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .user-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .user-table td {
    word-wrap: break-word;
    max-width: 200px; /* Prevents too wide cells */
  }
  
  .user-table th, .user-table td {
    text-align: center; /* Center-align columns for better look */
  }
  
  .user-table th:nth-child(1),
  .user-table td:nth-child(1) {
    width: 50px; /* Set fixed width for SR No. */
  }
  
  .user-table th:nth-child(9),
  .user-table td:nth-child(9) {
    width: 200px; /* Set fixed width for Date & Time */
  }
  