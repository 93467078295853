
body {
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #161513;
    background-color: #fff;
  }


.main-div-heading{
    display: flex;
    align-items: center;
    background-image: url(../image/developer-img/bg-of-developer-rs.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
    background-color: #323939;
    color: #ffffff;
    width: 100vw;
    height: 50vh;
    padding-left: 5vw;

}

.main-heading-of-developer{
    font-size:32.256px;
    line-height:38.7072px;
    font-weight: 400;
}
.side-heading-para{
    font-size:16.128px;
    line-height: 22.5792px;
    font-weight: 400;
}
.nav-bar-of-developer{
    width: 100vw;
    height: 7vh;
    padding-left: 5vw;
}
.nav-bar-of-developer ul{
    display: flex;
    flex-direction: row;
    gap: 20px;
    list-style: none;
    align-items: center;
    background-color:#ffffff;
    color: #000;
    height: 100%;
    width: 90%;
    border-bottom: 2px solid rgb(221, 221, 221);
}
.first-nav-heading{
    font-size: 14.336px;
    line-height: 20.0704px;
    font-weight: 400;
    margin-top: 2.8vh;
}
.nav-bar-of-developer button {
    font-size: 14.336px;
    line-height: 20.0704px;
    font-weight: 700;
}
.dynamic-content-of-developer{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
   
}
.content-of-latest-news{
    width: 100vw;
    height: auto;
  
}
.slider-developer{
       background-color: #F1EFED;
        position: relative;
        overflow: hidden;
        width: 100vw;
        height: 75vh;
        padding-top: 2vh;
       z-index: 1;
}
@media (max-width: 768px) {
    .slider-developer {
        height: auto; 
        width: 100vw;
        position: relative;
      }

 } 
.nav-bar-of-developer li:hover {
    border-bottom: 3px solid #5F7D4F; 
    margin-top: 5px;
}
.active-nav-item {
    border-bottom: 3px solid #5F7D4F; 
    margin-top: 5px;
  }

  
  .btn-of-developer {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 10px;
    text-align: left;
  }
  
  .btn-of-developer:focus {
    outline: none; 
  }
  .content-of-ai{
    width: 100vw;
    height: 75vh;
    background-color: #2A2F2F;
    color: #fff;
    padding-left: 8vw;
   
  }
  .heading-of-ai{
    width: 100vw;
    height: 7vh;
    display: flex;
    flex-direction: row;
    gap: 55vw;
    color: #fff;
    align-items: center;
    margin-top: 6vh;
    margin-bottom: 2vh;
  }
  .ai-heading{
    font-size:21.504px;
    line-height:25.8048px;
    font-weight: 800;
  }
  .links-of-all-ai{
    font-size:16px;
    line-height: 22.4px;
    font-weight: 400;
    color: rgb(240, 204, 113) ;
  }
  .links-of-all-ai a:hover{
    text-decoration: underline solid rgb(240, 204, 113);
  }
  .links-of-all-ai a{
    color: inherit;
    text-decoration: none;
  }
  .card-of-ai{
    display: flex;
    flex-direction: row;
    flex-grow: auto;
    gap: 25px;
  }
  .ai-card-1{
    width: 30%;
    height: auto;

  }
  .ai-card-2{
    width: 30%;
    height: auto;

  }
  .ai-card-3{
    width: 30%;
    height: auto;

  }
  .img-of-ai-card{
    background-size: cover;
    width: 100%;
    height: 33vh;
    margin-bottom: 2vh;
    border-radius: 5px;
  }
  .card-heading{
    font-size:16.128px;
    line-height:19.3536px;
    font-weight:700;
  }
  .img-of-ai-card ul {
    font-size:14.336px;
    line-height:20.0704px;
    font-weight:400;
  }
  .link-of-card{
    font-size:14.336px;
    line-height:20.0704px;
    font-weight:400;
    text-decoration: none;
    color: rgb(240, 204, 113);
  }
  .link-of-card:hover{
    text-decoration:underline solid rgb(240, 204, 113);
  }
  .content-of-languages{
    width: 100vw;
    height: 45vh;
    background-color: #fff;
    padding-left: 8vw;
    padding-right: 8vw;
    padding-top: 3vw;
  }
 .heading-of-lang{
    font-size:21.504px;
    line-height:25.8048px;
    font-weight:800;
 }
 .img-of-lang{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    column-gap: 12vw;
    row-gap: 5vh;
    flex-wrap: wrap;
    padding-left: 10vw;
    padding-top: 4vh;
    padding-right: 4vw;
 }
 .img-of-lang1{
    width: 110px;
    height: 40px;
 }
 .content-of-solutions{
    background-color: #2A2F2F;
    color: #fff;
    padding-left: 8vw;
    padding-right: 8vw;
    padding-top: 6vh;
    width: 100vw;
    height: 90vh;
 }
 .solution-heading{
    font-size:21.504px;
    line-height:25.8048px;
    font-weight:800;
 }
 .solution-card{
    margin-top: 3vh;
    display: flex;
    flex-direction: row;
    column-gap: 25px;
    flex-wrap: wrap; 
    width: 100%;
    height: 80%;
    
 }
 .solution-card-1, .solution-card-2, .solution-card-3, .solution-card-4, .solution-card-5, .solution-card-6 {
    width: 32%;  
    background-image: url(../image/developer-img/bgimg2-of-developer-rs.png);
    background-size: cover;
    height: 30vh; 
    border-radius: 5px;
    padding: 15px;
    box-sizing: border-box;
}
.solution-para{
   
    font-size:16px;
    line-height:22.4px;
    font-weight:400;
}
.solution-btn {
    background-color: transparent;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #fff;
    font-size:12.544px;
    line-height: 15.0528px;
    font-weight: 500;
    transition: all ease 1s;
}

.solution-btn:hover {
    background-color: #ffffff;
    color: #000;
}
.content-of-community{
    width: 100vw;
    height: auto;
    background-color: #FFFFFF;
    padding-left: 8vw;
    padding-left: 8vw;
    padding-top: 4vh;
}
.com-and-event-img-para{
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 20vw;
    background-color: #F5F4F2;
    border-radius: 5px;
    margin-bottom: 7vh;
    margin-top: 2vh;
}
.img-of-com-and-event{
    width: 35vw;
    height: 100%;
    border-bottom-left-radius: 8PX;
    border-top-left-radius: 8px;
}
.para-of-com-and-event{
    padding-left: 4vw;
    padding-top: 8vh;
    padding-right: 4vw;
   
}
.para-of-com{
    font-size:14.336px;
    line-height:20.0704px;
    font-weight:400;
}
.com-and-event-btn{
    background-color: var(--primary-color);
    color: #fff;
    font-size:12.544px;
    line-height:15.0528px;
    font-weight:500;
    padding: 10px 20px;
    border-radius: 4px;
}
.card-of-com-and-event{
    display: flex;
    flex-direction: row;
    gap: 25px;
}
.com-card-1{
    width: 60vh;
    height: 20vh;
}
.com-heading-of-card{
    font-size:14.336px;
    line-height: 17.2032px;
    font-weight:800;
}
.com-para-of-card{
    font-size:14.336px;
    line-height:20.0704px;
    font-weight:400
}
.link-of-com{
    font-size:14.336px;
    line-height:20.0704px;
    font-weight:400;
    text-decoration: none ;
    color: rgb(0, 107, 143);
}
.link-of-com:hover{
    text-decoration: underline rgb(0, 107, 143); ;
}
.content-of-resources{
    width: 100vw;
    height: 80vh;
    background-color:#F1EFED;
    padding-left: 8vw;
    padding-right: 8vw;
    padding-top: 7vh;
}
.content-wrapper-of-resource{
    display: flex;
    flex-direction: row;
    margin-top: 5vh;
}
.content-1-of-resource{
    width: 30vw;
    height: auto;
    margin-top: 3vh;
    margin-left: -3vw;
    z-index: 2;
    background-color: transparent;
}
.btn-resource{
    font-size:  16.128px;
    line-height: 22.5792px;
    font-weight: 600;
    border: none;
    margin-top: 1.5vh;
    padding: 10px 20px;
    border-radius: 4px;
    width: 18vw;
    text-align:left;
    background-color: transparent;
}
.content-1-of-resource li{
    list-style: none;
}
.btn-resource.active {
    background-color: var(--primary-color); /* Active background color */
    color: #fff; /* Text color for active state */
}
.content-2-of-resource{
    background-color: #fff;
    width: 42vw;
    height: 25vw;
    margin-left: -13vw;
    padding-left: 2vw;
    padding-right: 4vw;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

}
.img-of-resource-and-para{
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding-top: 5vh;
    padding-left: 5vw;
}
.img-of-resource{
    width: 10vw;
    height: 25vh;
    background-size: cover;
    border-radius: 5px;
}
.para-of-res-content{
    width: 20vw;
}
.resource-heading-of-card{
    font-size: 17.92px;
    line-height: 21.504px;
    font-weight: 800;
}
.para-of-resource{
    font-size:14.336px;
    line-height: 20.0704px;
    font-weight:400;
}
.content-3-of-resource{
    background-color: #E4E1DD;
    width: 30vw;
    padding-top: 6vh;
    padding-left: 5vw;
    padding-right: 5vw;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.res-3-div-para{
    font-size:14.336px;
    line-height:20.0704px;
    font-weight:400;
    
}
.list-of-3-div{
    list-style:none;
    margin-top: 1vh;
    margin-left: -1.8vw;
}


@media (max-width:786px) {

    
body {
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #161513;
    background-color: #fff;
  }


.main-div-heading{
    background-position-x: right;
    background-position-y: bottom;
    height: 30vh;
    padding-left: 4vw;

}
.nav-bar-of-developer{
    width: 100vw;
    height: auto;
    padding-left: 2vw;
}
.nav-bar-of-developer ul{
    overflow-x: auto;
}
.dynamic-content-of-developer{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
}
.content-of-latest-news{
    width: 100vw;
    height: auto;
}
  .btn-of-developer {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 10px;
    text-align: left;
  }
  .content-of-ai{
    height: auto;
    padding-left: 0vw;
    padding: 4vh 4vw;
  }
  .heading-of-ai{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 20vw;
    color: #fff;
    align-items: center;
    justify-content: space-between;
    margin-top: 0vh;
    margin-bottom: 2vh;
  } 
  .card-of-ai{
    flex-direction: column;
  }
  .ai-card-1{
    width: 100%;
    height: auto;
  }
  .ai-card-2{
    width: 100%;
    height: auto;
  }
  .ai-card-3{
    width: 100%;
    height: auto;
  }
  .content-of-languages{
    width: 100vw;
    height: auto;
    background-color: #fff;
    padding-left: 0vw;
    padding-right: 0vw;
    padding-top: 0vw;
    padding: 4vh 4vw;
  }
 .img-of-lang{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    column-gap: 0vw;
    row-gap: 4vh;
    flex-wrap: wrap;
    padding-left: 0vw;
    padding-top: 4vh;
    padding-right: 0vw;
    justify-content: center;
    align-items: center;
 }
 .content-of-solutions{
    background-color: #2A2F2F;
    color: #fff;
    padding-left: 0vw;
    padding-right: 0vw;
    padding-top: 0vh;
    padding: 4vh 4vw;
    width: 100vw;
    height: auto;
 }
 .solution-card{
    flex-direction: column;
    row-gap: 25px;
 }
 .solution-card-1, .solution-card-2, .solution-card-3, .solution-card-4, .solution-card-5, .solution-card-6 {
    width: 100%;  
}

.content-of-community{
    width: 100vw;
    height: auto;
    background-color: #FFFFFF;
    padding-left: 0vw;
    padding-left: 0vw;
    padding-top: 0vh;
    padding: 4vh 4vw;
}
.com-and-event-img-para{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 5vh;
    margin-top: 2vh;
}
.img-of-com-and-event{
    width: 100%;
    height: 100%;
}
.para-of-com-and-event{
    padding-left: 0vw;
    padding-top: 0vh;
    padding-right: 0vw;
    padding: 4vh 4vw;
}
.card-of-com-and-event{
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.com-card-1{
    width: 100%;
    height: auto;
}

.content-of-resources{
    width: 100vw;
    height: auto;
    background-color:#F1EFED;
    padding-left: 0vw;
    padding-right: 0vw;
    padding-top: 0vh;
    padding: 4vh 4vw;
}
.content-wrapper-of-resource{
    display: flex;
    flex-direction: column;
    margin-top: 0vh;
}
.content-1-of-resource{
    width: 100%;
    height: auto;
    margin-top: 3vh;
    margin-left: 0vw;
}
.btn-resource{
    width: 60vw;
}
.content-2-of-resource{
    background-color: #fff;
    width: 100%;
    height: auto;
    margin-left: 0vw;
    padding-left: 0vw;
    padding-right: 0vw;
}
.img-of-resource-and-para{
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 0vh;
    padding-left: 0vw;
    padding: 4vh 4vw;
}
.img-of-resource{
    width: 100%;
    height:auto;
    background-size: cover;
}
.para-of-res-content{
    width: 100%;
}
.content-3-of-resource{
    background-color: #E4E1DD;
    width: 100%;
    padding-top: 0vh;
    padding-left: 0vw;
    padding-right: 0vw;
    padding: 4vh 4vw;
}


    
}