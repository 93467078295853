body {
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #161513;
    background-color: #FBF9F8;
  }

/* first div  */
.upper-div-become-customer{
 background-color: #3B5C69;
    position: relative;
    z-index: 1;
 width: 100vw;
 height: 50vh;
 padding-left: 5vw;
 padding-right: 8vw;
}
.upper-div-become-customer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    background-image: url(../image/customer/become-customer-background-overlay.png);
    background-size : 98% 96%;
    background-position-y: bottom;
    background-repeat: no-repeat;
    opacity: 0.5; 
    z-index: -1; 
}
.main-heading-of-become-customer{
    font-size:  32.256px;
    line-height: 38.7072px;
    font-weight: 600;
    margin-top: 8vh;
    color: #FFF;
    width: 50vw;
}
.side-heading-become-customer-para{
    margin-top: 2vh;
    font-size:  16.128px;
    line-height:  22.5792px;
    font-weight:  400;
}
.become-customer-btns{
    border: none;
    background-color: #fff;
    border-radius: 5px;
    width: auto;
    height: 5.5vh;
    padding: 4px 4px;
    font-size: 12.544px;
    line-height: 15.0528px;
    font-weight: 600;
    margin-top: 1vh;
    outline: none;
    position: relative; 
}
.become-customer-btns:focus::before {
    content: ""; 
    position: absolute;
    top: 2px; 
    left: 2px;
    right: 2px;
    bottom: 2px;
    border: 2px dotted black; 
    pointer-events: none; 
}
.become-customer-btns:hover{
  background-color: #f9f9f9; 
}

@media (max-width:600px) {
    body {
        font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
        color: #161513;
        background-color: #FBF9F8;
      }
    
    .upper-div-become-customer{
     width: 100vw;
     height: 37vh;
     padding-left: 5vw;
     padding-right: 8vw;
     padding-top: 3vh;
    }
    .upper-div-become-customer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; 
        background-image: url(../image/customer/become-customer-background-overlay.png);
        background-size : 80% 80%;
        background-position-y: center;
        background-position-x: center;
        background-repeat: no-repeat;
        opacity: 0.5; 
        z-index: -1; 
    }
    .main-heading-of-become-customer{
        font-size:  28.8px;
        line-height: 34.56px;
        font-weight: 500;
        margin-top: 0vh;
        color: #FFF;
        width: 100%;
    }
    .side-heading-become-customer-para{
        margin-top: 2vh;
        font-size: 14.4px;
        line-height: 20.16px;
        font-weight: 400;
    }
    .become-customer-btns{
        width: auto;
        height: 4vh;
        padding: 4px 4px;
        font-size:  11.2px;
        line-height:  13.44px; 
        font-weight: 500;
        margin-top: 1vh;
    }
    
}



/* sec-div */

.become-customer-sec-div {
    background-color: #FBF9F8;
    width: 100vw;
    height: 45vh;
    padding: 6vh 5vw;
    position: relative; 
    z-index: 1;
    overflow: hidden;
}

.become-customer-sec-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    background: url("../image/customer/become-customer-pattern1.png");
    background-size: contain;
    background-repeat: repeat;
    opacity: 0.1; 
    z-index: -1; 
}

.become-customer-sec-div-heading-uper{
    font-size: 35.84px;
    line-height: 43.008px;
    font-weight: 600;
}
.become-customer-card-wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    gap: 10px;
}
.become-cutomer-card-1{
    width: 22vw;
    height: 35vh;

}
.become-customer-card-all-heading{
    font-size: 21.504px;
    line-height: 25.8048px;
    font-weight: 800;
}
.become-customer-card-para-all{
    font-size:  14.336px;
    line-height: 20.0704px;
    font-weight: 400;
}

@media (max-width:600px) {
    .become-customer-sec-div{
        background-color: #FBF9F8;
        width: 100vw;
        height: auto;
       padding-right: 8vw;
    }
    .become-customer-sec-div-heading-uper{
        font-size: 32px;
        line-height: 38.4px;
        font-weight: 500;
    }
    .become-customer-card-wrapper{
        flex-direction: column;
    }
    .become-cutomer-card-1{
        width: 87vw;
        height: 15vh;
    }
    .become-customer-card-all-heading{
        font-size: 19.2px;
        line-height: 23.04px;
        font-weight: 800;
    }
    .become-customer-card-para-all{
        font-size:  14.8px;
        line-height: 20.72px;
        font-weight: 400;
    }
    
}

/* 3-div */

.become-customer-sec-last-div{
    width: 100vw;
    height: 40vh;
    display: flex;
    padding: 8vh 5vw;
    gap: 20px;
    background-color: #fff;
}
.become-customer-side-img-of-para{
    width: 100%;
    height: 20%;
}
.become-customer-sec-last-div-child-1{
    width: 10vw;
}
.become-customer-sec-last-div-child-2{
    width: 80vw;
}
.become-customer-special-heading{
    font-size: 21.504px;
    line-height: 30.1056px;
    font-weight: 300;
    color:  rgb(86, 86, 86);

}
.become-customer-small-heading{
    font-size:  14.336px;
    line-height: 20.0704px;
    font-weight: 800;
}
.become-customer-small-para{
    font-size: 10.752px;
    line-height: 15.0528px;
    font-weight: 600;
}

@media (max-width:600px) {
    .become-customer-side-img-of-para{
        width: 20vw;
        height: 5vh;
    }
    .become-customer-sec-last-div{
        height: auto;
        padding: 4vh 5vw;
        flex-direction: column;
    }
    .become-customer-sec-last-div-child-1{
        width: 10vw;
    }
    .become-customer-sec-last-div-child-2{
        width: 90vw;
    }
    .become-customer-special-heading{
        font-size: 20.504px;
    }
    .become-customer-small-heading{
        font-size:  14.336px;
       
    }
    .become-customer-small-para{
        font-size: 9.752px;
    }
    
}

/* div-4 */

.become-customer-sec-last-div-flex-box{
    width: 100vw;
    height: auto;
    padding: 6vh 5vw;
    background-color: #F5F3F2;
    padding-bottom: 10vh;
    position: relative;
    z-index: 1;
}
.become-customer-sec-last-div-flex-box::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    background-image: url(../image/customer/become-customer-pattern2.png);
    background-size: contain;
    background-repeat: repeat;
    opacity: 0.03; 
    z-index: -1; 
}
.become-customer-sec-last-div-heading{
    font-size: 21.504px;
    line-height: 25.8048px;
    font-weight: 800;
    width: 100%;
}
.become-customer-sec-last-div-all-para{
    font-size: 14.336px;
    line-height: 20.0704px;
    font-weight: 400;
    width: 100%;
    margin-top: 2vh;
}
.become-customer-sec-last-div-card-all-heading{
    font-size: 16.128px;
    line-height: 19.3536px;
    font-weight: 800;
}
.become-customer-sec-last-div-crad-wrapper{
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    margin-top: 4vh;
   
}
.become-customer-sec-last-div-card-1{
    width: 42vw;
    height: 8vh;
    margin-top: 3vh;
    border-left: 2px solid #F0CC71;
    padding-left: 2vw;
}

@media (max-width:600px) {
    .become-customer-sec-last-div-flex-box{
        padding-bottom: 5vh;
    }
    .become-customer-sec-last-div-heading{
        font-size: 19.2px;
        line-height: 23.04px;
        font-weight: 800;
    }
    .become-customer-sec-last-div-card-all-heading{
        font-size:  14.4px;
        line-height: 17.28px;
        font-weight: 800;
    }
 
    .become-customer-sec-last-div-card-1{
        width: 100vw;
        margin-top: 3vh;
    }
}

/* 5-div */

.become-customer-last-div-para-and-btn{
    width: 100vw;
    height: 15vh;
    background-color: #3B5C69;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
}
.become-customer-last-div-para-and-btn-heading{
    font-size: 17.92px;
    line-height: 25.088px;
    font-weight: 800;
    color: #FFF;
}
.become-customer-last-div-para-and-btn-button{
    font-size: 12.544px;
    line-height: 15.0528px;
    font-weight: 500;
    border: none;
    background-color: #fff;
    border-radius: 5px;
    width: 11vw;
    height: 5.5vh;
    padding: 4px 5px;
    outline: none;
    position: relative; 
}
.become-customer-last-div-para-and-btn-button:focus::before {
    content: ""; 
    position: absolute;
    top: 2px; 
    left: 2px;
    right: 2px;
    bottom: 2px;
    border: 2px dotted black; 
    pointer-events: none; 
}
.become-customer-last-div-para-and-btn-button:hover{
  background-color: #f9f9f9; 
}

@media (max-width:600px) {
    .become-customer-last-div-para-and-btn{
        flex-direction: column;
        align-items: start;
        justify-content: center;
        gap: 10px;
        padding: 0 5vw;
    }
    .become-customer-last-div-para-and-btn-heading{
        margin-top: 1vh;
    }
    .become-customer-last-div-para-and-btn-button{
        border-radius: 5px;
        width: 45vw;
        height: 4.5vh;
 
    }
}