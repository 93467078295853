  .emp-transperant-navbar{
      width: 100vw;
      height: 7vh;
      background-color:#211e1c96;
      padding-left: 7vw;
      position: fixed;
  }
  
  .emp-Link-of-empoly{
      position: relative;
      text-decoration: none;
      color: #ffffff;
      font-size: 16px;
      top: 13px;
    }
  
    .emp-Link-of-empoly:hover{
      text-decoration:
      underline solid rgb(255, 255, 255);
    }
  
    .emp-Link-of-empoly .emp-arrow-emp svg {
      position: absolute;
      top: -3px ;
      margin-left: -6px;  
      padding-right: 10px;
    }
  
  .emp-bg-img-1{
     background-color: #375965;
     background-image: url(../image/customer/bgimg01-bgse-leadership.jpg);
     background-size: contain;
     height: 65vh;
     width: 100vw;
  }
  .emp-bg-img-2{
     width: 100vw;
     height: 30vh;
     background-color:#FBF9F8;  
  }
  .emp-overlay-div{
      position: absolute;
      top: 20vh;
      left: 2vw;
      width: 95vw;
      height: 66vh;
      color: #000;
      background-color: white;
      padding-left: 2vw;
      padding-right: 2vw;
  }
  .img-and-info-wrapper{
    background-color: #F5F4F2;
    width: 90vw;
    margin-top: 7vh;
    height: 33vh;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
  .emp-img{
    background-size: cover;
    width: 28vw;
    height: 100%;
    margin-left: 2vw;
  }
  .emp-info{
    margin-left: 3vw;
    margin-top: 18vh;
    width: 50%;
    height: 50%;
  }
  .emp-name{
    font-size:21.504px;
    line-height:25.8048px;
    font-weight:400;
    text-decoration:none solid rgb(22, 21, 19);
    text-align:start;
  }
.info-of-employ{
    font-size:14.336px;
    line-height: 20.0704px;
    font-weight:700;
}
.text1{
    font-size:17.92px;
    line-height:25.088px;
    font-weight:400;
    margin-top: 6vh;
}
.text2{
    font-size:14.336px;
    line-height:20.0704px;
    font-weight:400;
 
}


  @media screen and (max-width: 768px) {
      
  .emp-transperant-navbar{
   display: none;
  }
  
  .emp-bg-img-1{
      height: 10vh;
  }
  .emp-bg-img-2{
    display: none;
    height: 0vh;
  }
  .emp-overlay-div{
    position: relative;
    width: 100vw;
    height:auto;
    color: #000;
    background-color: #ffffff;
    top: 0;
    left: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 4vh;
}
  .img-and-info-wrapper {
    background-color: #F5F4F2; 
    width: 90vw;
    height: auto;
    margin-left: 5vw;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
    padding-left: 0vw;
    padding-right: 0vw;
    margin-top: 5vh;
}
.emp-img {
  background-size: contain;
  width: 100%;
  height: 40%;
  margin-left: 0vw;
}
.emp-info {
  margin-left: 8vw;
  margin-top: 0vh;
  width: 100%;
  height: 50%;
  padding: 2vh 0;
}
.emp-name {
  font-size: 18px;
}
.info-of-employ {
  font-size: 12px;
  margin-top: -1vh;
  padding-right: 20vw;
}

.text1{
  font-size:17.92px;
  margin-top: 5vh;
  padding-left: 5vw;
  padding-right: 4vw;
}
.text2{
  font-size:14.336px;
  padding-left: 5vw;
  padding-right: 4vw;
  margin-top: 0vh;
}
  
    }