body {
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #161513;
    background-color: #FBF9F8;
  }

/* first div  */
.product-tools-tempalte-human-resource-first-div{
    width: 100vw;
    height: 50vh;
    display: flex;
    gap: 10vw;
    background-color: #ffffff;
    color: #FFF;
    padding-top: 16vh;
    padding-left: 6vw;
    padding-right: 5vw;
    align-items: center;
    justify-content: space-between;
    background-image: url(../image/tools/bgimg04-bgn-02.avif);
    background-size : 98% 96%;
    background-position-y: bottom;
    margin-top: -15px;
    
}

.product-tools-tempalte-non-fungible-administrator-first-div{
  width: 100vw;
    height: 50vh;
    display: flex;
    gap: 10vw;
    background-color: #ffffff;
    color: #FFF;
    padding-top: 16vh;
    padding-left: 6vw;
    padding-right: 5vw;
    align-items: center;
    justify-content: space-between;
    background-image: url(../image/tools/bgimg04-bgn-02.avif);
    background-size : 98% 96%;
    background-position-y: bottom;
    margin-top: -15px;
}
.product-tools-tempalte-human-resource-first-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    background-image: url(../image/tools/bgimg04-bgn-02.avif);
    background-size : 100%;
    background-position-y: bottom;
    background-repeat: no-repeat;
    opacity: 0.5; 
    z-index: -1; 
}

.product-tools-tempalte-non-fungible-administrator-first-div::before{
  content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    background-image: url(../image/tools/bgimg04-bgn-02.avif);
    background-size : 100%;
    background-position-y: bottom;
    background-repeat: no-repeat;
    opacity: 0.5; 
    z-index: -1; 
}

.product-tools-tempalte-human-resource-first-div-heading{
    font-size:  34.256px;
    line-height: 38.7072px;
    font-weight: 600;
    margin-top: -6rem;
    color: #0c0c0c;
    width: 50vw;
}

.product-tools-tempalte-human-resource-first-div-paragraph{
    margin-top: 2vh;
    font-size:  22.128px;
    font-weight:  400;
    line-height: 28px;
}

/* .product-tools-tempalte-human-resource-first-div-child-2{
    margin-right: 4rem;
} */
.product-tools-tempalte-human-resource-first-div-child-2-img{
    background-color: #fff;
    width: 28vw;
    height: 35vh;
    margin-bottom: 6rem;
    
}

.product-tools-tempalte-human-resource-first-div-child-2-img:hover{
    transition: transform 0.3s ease;
    transform: scale(1.05); 
}

@media (max-width:600px) {

    body {
        font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
        color: #161513;
        background-color: #FBF9F8;
      }

    .product-tools-tempalte-human-resource-first-div{
        /* width: 100vw;
        height: 50vh;
        display: flex;
        gap: 10vw;
        background-color: #ffffff;
        color: #FFF;
        padding-top: 16vh;
        padding-left: 6vw;
        padding-right: 5vw;
        align-items: center;
        justify-content: space-between;
        background-image: url(D:\lattice\latticelabs\src\image\tools\bgimg04-bgn-02.avif);
        background-size : 98% 96%;
        background-position-y: bottom;
        margin-top: -15px; */

        height: 80vh;
    flex-direction: column;
    gap: 10vw;
    padding-top: 0vh;
    padding-left: 4vw;
    padding-right: 4vw;
    margin-top: -2rem;
        
    }

    .product-tools-tempalte-non-fungible-administrator-first-div{
      height: 80vh;
    flex-direction: column;
    gap: 10vw;
    padding-top: 0vh;
    padding-left: 4vw;
    padding-right: 4vw;
    margin-top: -2rem;
    margin-bottom: -20rem;
    }
    /* .product-tools-tempalte-human-resource-first-div::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; 
        background-image: url(D:\lattice\latticelabs\src\image\tools\bgimg04-bgn-02.avif);
        background-size : 100%;
        background-position-y: bottom;
        background-repeat: no-repeat;
        opacity: 0.5; 
        z-index: -1; 
    } */
    
    .product-tools-tempalte-human-resource-first-div-heading{
        font-size:  30px;
    line-height: 36px;
    font-weight: 400;
    margin-top: 3rem;
        width: 24rem;
    }
    
    .product-tools-tempalte-human-resource-first-div-paragraph{
        /* margin-top: 2vh; */
        font-size:  20.128px;
        font-weight:  400;
        line-height: 28px;
    }
    
    /* .product-tools-tempalte-human-resource-first-div-child-2{
        margin-right: 4rem;
    } */
    .product-tools-tempalte-human-resource-first-div-child-2-img{
        width: 90vw;
    height: 25vh;
        
    }
}

.product-tools-tempalte-human-resource-second-div-para-btn{
    background-color: #D6E5E5;
    width: 100vw;
    height: 13vh;
    position: relative;
    left: 0vw;
    display: flex;
    gap: 25px;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
}

.product-tools-tempalte-non-fungible-administrator-second-div-para-btn{
  background-color: #D6E5E5;
    width: 100vw;
    height: 13vh;
    position: relative;
    left: 0vw;
    display: flex;
    gap: 25px;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
}

.product-tools-tempalte-human-resource-second-div-heading{
    font-size:17.92px;
    line-height: 25.088px;
    font-weight: 800;
}

.product-tools-tempalte-human-resource-second-div-btn{
    border: none;
    background-color: var(--primary-color);
    width: auto;
    height: 5.5vh;
    font-size: 13px;
    line-height: 15.0528px;
    font-weight: 600;
    color: #FFF;
    border-radius: 4px;
    outline: none;
    position: relative; 
}

.product-tools-tempalte-human-resource-second-div-btn:hover{
    background-color:#161513;
}

.product-tools-tempalte-human-resource-second-div-btn:focus::before{
    content: ""; 
    position: absolute;
    top: 2px; 
    left: 2px;
    right: 2px;
    bottom: 2px;
    border: 1.5px dotted rgb(255, 255, 255); 
    pointer-events: none;
}

@media (max-width:600px) {

    .product-tools-tempalte-human-resource-second-div-para-btn{
        padding: 2vh 4vw;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: start;
    align-items: start;
    height: auto;
    margin-top: -1rem;
    }

    .product-tools-tempalte-non-fungible-administrator-second-div-para-btn{
      padding: 2vh 4vw;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: start;
    align-items: start;
    height: auto;
    margin-top: 2rem;
    }

    .product-tools-tempalte-human-resource-second-div-heading{
        font-size:16px;
    line-height: 20px;
    font-weight: 800;
    }

    .product-tools-tempalte-human-resource-second-div-btn{
        border: none;
        background-color: var(--primary-color);
        width: 20vw;
        height: 4vh;
        font-size: 12px;
        line-height: 15.0528px;
        font-weight: 600;
        color: #FFF;
        border-radius: 4px;
        outline: none;
        position: relative; 
    }
}


.product-tools-tempalte-human-resource-third-div{
    display: flex;
    margin-bottom: 3rem;
    margin-top: 1rem;
}
.product-tools-tempalte-human-resource-third-div-child-1{
    margin-left: 5.7rem;
    margin-top: 2rem;

}

.product-tools-tempalte-human-resource-third-div-child-1-heading{
    font-size: 1rem;
    font-weight: 700;
}
.product-tools-tempalte-human-resource-third-div-child-1-paragraph{
    width: 38rem;
    font-size: 14px;
}

.product-tools-tempalte-human-resource-third-div-child-1-btn{
    border: none;
    background-color: var(--primary-color);
    width: auto;
    height: 5.5vh;
    font-size: 13px;
    line-height: 15.0528px;
    font-weight: 600;
    color: #FFF;
    border-radius: 4px;
    outline: none;
    position: relative; 
}

.vertical-line {
    width: 1.5px;
    background-color: #0000001a;
    height: 8rem;
    margin-top: 2rem; /* Full height of the container */
}

.product-tools-tempalte-human-resource-third-div-child-2{
    margin-left: 3rem;
    margin-top: 2rem;

}

.product-tools-tempalte-human-resource-third-div-child-2-heading{
    font-size: 1rem;
    font-weight: 700;
}
.product-tools-tempalte-human-resource-third-div-child-2-paragraph{
    width: 43rem;
    font-size: 14px;
}

.product-tools-tempalte-human-resource-third-div-child-2-btn{
    border: none;
    background-color: var(--primary-color);
    width: 20vw;
    height: 5.5vh;
    font-size: 13px;
    line-height: 15.0528px;
    font-weight: 600;
    color: #FFF;
    border-radius: 4px;
    outline: none;
    position: relative;
}

@media (max-width: 600px) {
    .product-tools-tempalte-human-resource-third-div {
        display: block; /* Stack elements vertically for small screens */
        margin-bottom: 1.5rem;
        margin-top: 0.5rem;
    }

    .product-tools-tempalte-human-resource-third-div-child-1 {
        margin-left: 1rem; /* Reduce margin for smaller screens */
        margin-top: 4rem;
        margin-bottom: -7rem;
    }

    .product-tools-tempalte-human-resource-third-div-child-1-heading {
        font-size: 0.9rem; /* Slightly smaller font size */
        font-weight: 700;
    }

    .product-tools-tempalte-human-resource-third-div-child-1-paragraph {
        width: 100%; /* Adjust width to fit smaller screens */
        font-size: 12px;
    }

    .product-tools-tempalte-human-resource-third-div-child-1-btn {
        width: 34%; /* Adjust button width to fit the screen */
        height: 4vh;
        font-size: 12px;
        line-height: 14px;
    }

    .product-tools-tempalte-human-resource-third-div-child-2 {
        margin-left: 1rem; /* Reduce margin for smaller screens */
        margin-top: 1rem;
    }

    .product-tools-tempalte-human-resource-third-div-child-2-heading {
        font-size: 0.9rem; /* Slightly smaller font size */
        font-weight: 700;
    }

    .product-tools-tempalte-human-resource-third-div-child-2-paragraph {
        width: 100%; /* Adjust width to fit smaller screens */
        font-size: 12px;
    }

    .product-tools-tempalte-human-resource-third-div-child-2-btn {
        width: 63%; /* Adjust button width to fit the screen */
        height: 4vh;
        font-size: 12px;
        line-height: 14px;
    }
}

.product-tools-tempalte-human-resource-fourth-div {
    position: relative;
    width: 100vw;
    height: 80vh;
    display: flex;
    gap: 10vw;
    color: #FFF;
    padding-top: 16vh;
    padding-left: 6vw;
    padding-right: 5vw;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    overflow: hidden; /* Prevent overflow issues with pseudo-element */
}

.product-tools-tempalte-human-resource-fourth-div::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90%;
    background-image: url(../image/tools/rc04-booking-dot-com.jpg);
    background-size: 100%;
    background-position-y: bottom;
    filter: brightness(40%); /* Reduce brightness */
    z-index: 1; /* Ensure the background is behind content */
}

.product-tools-tempalte-human-resource-fourth-div > * {
    position: relative;
    z-index: 2; /* Ensure content stays above the background */
}

.product-tools-tempalte-human-resource-fourth-div-heading{
    font-size: 36px;
    line-height: 38.7072px;
    /* font-weight: 600; */
    margin-top: -18rem;
    color: #fbf9f8;
    width: 43vw;
}

.product-tools-tempalte-human-resource-fourth-div-paragraph{
    margin-top: 2vh;
    font-size:  16.128px;
    line-height:  22.5792px;
    font-weight:  400;
}

.product-tools-tempalte-human-resource-fourth-div-btn{
    border: none;
    background-color: #fff;
    border-radius: 5px;
    width: 11.3vw;
    height: 5.5vh;
    padding: 4px 4px;
    font-size: 12.544px;
    line-height: 15.0528px;
    font-weight: 600;
    margin-top: 5vh;
    outline: none;
    position: relative; 
}

.product-tools-tempalte-human-resource-fourth-div-btn:focus::before{
    content: ""; 
    position: absolute;
    top: 2px; 
    left: 2px;
    right: 2px;
    bottom: 2px;
    border: 2px dotted black; 
    pointer-events: none; 
}

.product-tools-tempalte-human-resource-fourth-div-btn:hover{
    background-color: #f9f9f9;
}

@media (max-width: 600px) {
    .product-tools-tempalte-human-resource-fourth-div {
        flex-direction: column; /* Stack elements vertically */
        height: auto; /* Adjust height for smaller screens */
        padding-top: 8vh;
        padding-left: 2vw;
        padding-right: 2vw;
        gap: 2rem; /* Reduce gap between elements */
        align-items: flex-start; /* Align items to the start */
        justify-content: flex-start; /* Adjust content alignment */
        margin-bottom: 4rem;
    }

    .product-tools-tempalte-human-resource-fourth-div::before {
        background-size: cover; /* Adjust background to cover the area */
        height: 100%; /* Extend pseudo-element to full height */
        filter: brightness(50%); /* Slightly dim the background for readability */
    }

    .product-tools-tempalte-human-resource-fourth-div-heading {
        font-size: 24px; /* Reduce font size */
        line-height: 1.4;
        margin-top: 0; /* Remove excessive top margin */
        width: 100%; /* Adjust width for responsiveness */
        /* text-align: center;  */
       margin-left: 5px;
    }

    .product-tools-tempalte-human-resource-fourth-div-paragraph {
        font-size: 14px; /* Slightly smaller font size */
        line-height: 1.5;
        margin-top: 1rem;
        width: 100%; /* Adjust paragraph width to fit */
        /* text-align: center;  */
        margin-bottom: 0rem;
        
    }

    .product-tools-tempalte-human-resource-fourth-div-btn {
        width: 45%; /* Make button span most of the width */
        height: 4vh; /* Adjust height */
        font-size: 14px; /* Increase font size slightly for readability */
        margin-top: 2rem; /* Adjust margin for better spacing */
        /* align-self: center;  */
        margin-bottom: 2rem;
    }

    .product-tools-tempalte-human-resource-fourth-div-btn:focus::before {
        top: 1px; /* Adjust focus outline dimensions */
        left: 1px;
        right: 1px;
        bottom: 1px;
    }
}

.product-human-resource-div-four-content-of-resources{
  margin-left: -0.3rem;
  width: 100vw;
      height: auto;
      background-color:#F3F2F0;
      padding: 6vh 6vw;
  }
  
  .product-human-resource-div-four-solution-heading{
    font-size: 32.256px;
    line-height: 28.7072px;
    font-weight: 500;
  }

.product-human-resource-div-four-solution-heading{
    font-size: 32.256px;
    line-height: 28.7072px;
    font-weight: 500;
}

/* .product-human-resource-div-four-content-of-resources {
    width: 100vw;
    height: auto;
    background-color:#F3F2F0;
    padding: 6vh 6vw;
    margin-left: -0.3rem;
  } */

.product-human-resource-div-four-content-wrapper-of-resource {
    display: flex;
    flex-direction: row;
    position: relative; 
  }
  .product-human-resource-div-four-content-1-of-resource {
    width: 30vw;
    height: 55vh;
    margin-top: 3vh;
    margin-left: 3rem;
    z-index: 2;
    background-color: transparent;
    position: relative;
  }
  .product-human-resource-div-four-content-1-of-resource ul {
    list-style: none;
  }
  .product-human-resource-div-four-btn-resource {
    font-size: 16.128px;
    line-height: 22.5792px;
    font-weight: 600;
    border: none;
    margin-top: 1.5vh;
    padding: 10px 20px;
    border-radius: 4px;
    width: 18vw;
    text-align: left;
    background-color: transparent;
  }
  
  .product-human-resource-div-four-btn-resource.active {
    background-color:#1E3224;
    color: #fff;
  }
  
  .product-human-resource-div-four-combined-content {
    background-color: #fff;
    width: 100vw;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 2vw 6vw ;
    z-index: 1;
    margin-left: -4vw;
  }
  
  .product-human-resource-div-four-top-content {
    text-align: left;
    margin-bottom: 2vh;
  }
  
  .product-human-resource-div-four-resource-heading {
    font-size: 18px;
    line-height: 22px;
    font-weight: 800;
    margin-bottom: 1vh;
  }
  
  .product-human-resource-div-four-resource-description {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    /* margin-bottom: 2vh; */
  }
  
  .product-human-resource-div-four-resource-btn {
    font-size: 14px;
    font-weight: 600;
    color: white;
    background-color: var(--primary-color);
    border: none;
    padding: 8px 18px;
    border-radius: 4px;
  }
  .product-human-resource-div-four-resource-btn:hover{
    background-color: #161513;
  }
  
  .product-human-resource-div-four-grid-content {
    display: flex;
    flex-wrap: wrap;
    gap: 2vw;
    justify-content: space-between;
    margin-left: -1.8rem;
    margin-top: -2rem;
  }
  
  .product-human-resource-div-four-grid-item {
    width: calc(48% - 2vw);
    border-radius: 5px;
    padding: 2vw;
  }
  
  .product-human-resource-div-four-grid-heading {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 1vh;
    width: 30rem;
  }
  
  .product-human-resource-div-four-grid-para {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: -10vh;
    width: 28rem;
  }
  
  .product-human-resource-div-four-grid-link {
    font-size: 14px;
    font-weight: 400;
    color: #006b8f;
    text-decoration: none;
  }

  @media (max-width: 600px) {
    .product-human-resource-div-four-content-of-resources {
      margin-left: 0;
      width: 100%;
      height: auto;
      background-color: #F3F2F0;
      padding: 4vh 4vw; 
    }
  
    .product-human-resource-div-four-solution-heading {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
    }
  
    .product-human-resource-div-four-content-wrapper-of-resource {
      display: flex;
      flex-direction: column; 
      position: relative;
    }
  
    .product-human-resource-div-four-content-1-of-resource {
      width: 55%; 
      height: auto; 
      margin-top: 2vh;
      margin-left: 15px;
      z-index: 2;
      background-color: transparent;
      position: relative;
    }
  
    .product-human-resource-div-four-content-1-of-resource ul {
      list-style: none;
      padding: 0; 
    }
  
    .product-human-resource-div-four-btn-resource {
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      border: none;
      margin-top: 1.5vh;
      padding: 8px 12px; 
      border-radius: 4px;
      width: 100%; 
      background-color: transparent;
    }
  
    .product-human-resource-div-four-btn-resource.active {
      background-color: #1E3224;
      color: #fff;
    }
  
    .product-human-resource-div-four-combined-content {
      background-color: #fff;
      width: 100%;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      padding: 4vw;
      z-index: 1;
      margin-left: 0;
    }
  
    .product-human-resource-div-four-top-content {
      text-align: center; 
      margin-bottom: 2vh;
    }
  
    .product-human-resource-div-four-resource-heading {
      font-size: 16px; 
      line-height: 20px;
      font-weight: 800;
      margin-bottom: 1vh;
      margin-left: -10.7rem;
    }
  
    .product-human-resource-div-four-resource-description {
      font-size: 13px; 
      line-height: 18px;
      font-weight: 400;
      text-align: center; 
    }
  
    .product-human-resource-div-four-resource-btn {
      font-size: 13px;
      font-weight: 600;
      color: white;
      background-color: var(--primary-color);
      border: none;
      padding: 6px 14px; 
      border-radius: 4px;
    }
  
    .product-human-resource-div-four-resource-btn:hover {
      background-color: #161513;
    }
  
    .product-human-resource-div-four-grid-content {
      display: flex;
      flex-direction: column; 
      gap: 2vw; 
      margin-left: 0;
      margin-top: 0;
    }
  
    .product-human-resource-div-four-grid-item {
      width: 100%; 
      border-radius: 5px;
      padding: 2vw;
    }
  
    .product-human-resource-div-four-grid-heading {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 1vh;
      width: 100%;
    }
  
    .product-human-resource-div-four-grid-para {
      font-size: 13px; 
      font-weight: 400;
      margin-bottom: 1rem;
      width: 100%; 
    }
  
    .product-human-resource-div-four-grid-link {
      font-size: 13px; 
      font-weight: 400;
      color: #006b8f;
      text-decoration: underline;
    }
  }

  .product-tools-tempalte-human-resource-sixth-div{
    margin-left: 5.7rem;
    margin-top: 2rem;
  }
  .product-tools-tempalte-human-resource-sixth-div-heading{
    font-size: 18px;
    line-height: 22px;
    font-weight: 800;
    margin-bottom: 3vh;
  }
  .product-tools-tempalte-human-resource-sixth-div-sub-heading{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 1vh;
  }
  .product-tools-tempalte-human-resource-sixth-div-para{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 1vh;
    width: 50rem;
  }

  @media (max-width: 600px){
    .product-tools-tempalte-human-resource-sixth-div{
        margin-left: 1.5rem;
        margin-top: 2rem;
      }
      .product-tools-tempalte-human-resource-sixth-div-heading{
        font-size: 18px;
        line-height: 22px;
        font-weight: 800;
        margin-bottom: 3vh;
      }
      .product-tools-tempalte-human-resource-sixth-div-sub-heading{
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 1vh;
      }
      .product-tools-tempalte-human-resource-sixth-div-para{
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 4vh;
        width: 22rem;
      }
  }

  

  .product-tools-tempalte-human-resource-seventh-div{
    display: flex;
    justify-content: center;
    align-items: center; 
    margin-top: 6rem; 
    text-align: center;
  }
  .product-tools-tempalte-human-resource-seventh-div-heading{
    font-size: 30px;
  }

  @media (max-width: 600px){
    .product-tools-tempalte-human-resource-seventh-div{
        display: flex;
        justify-content: center;
        align-items: center; 
        margin-top: 6rem; 
        text-align: center;
      }
      .product-tools-tempalte-human-resource-seventh-div-heading{
        font-size: 30px;
        width: 16rem;
      }
  }

  .product-tools-tempalte-human-resource-eight-div{
    display: flex;
    margin-bottom: 3rem;
    margin-top: 1rem;
  }

  .product-tools-tempalte-human-resource-eight-div-child-1{
    margin-left: 7.7rem;
    margin-top: 2rem;
    margin-right: 7rem;

  }
  .product-tools-tempalte-human-resource-third-div-child-1-heading{
    font-size: 1rem;
    font-weight: 700;
  }
  .product-tools-tempalte-human-resource-eight-div-child-1-paragraph{
    width: 43rem;
    font-size: 14px;
    text-decoration: none;
  }

  .product-tools-tempalte-human-resource-eight-div-child-1-paragraph:hover{
    text-decoration-line: underline;
  }

  

.product-tools-tempalte-human-resource-eight-div-child-2{
    margin-left: 1rem;
    margin-top: 2rem;
    margin-right: 5rem;

  }
  .product-tools-tempalte-human-resource-eight-div-child-2-heading{
    font-size: 1rem;
    font-weight: 700;
  }
  .product-tools-tempalte-human-resource-eight-div-child-2-paragraph{
    width: 43rem;
    font-size: 14px;
    text-decoration: none;
  }

  .product-tools-tempalte-human-resource-eight-div-child-2-paragraph:hover{
    text-decoration-line: underline;
  }

  .product-tools-tempalte-human-resource-eight-div-child-3{
    margin-left: 1rem;
    margin-top: 2rem;

  }
  .product-tools-tempalte-human-resource-eight-div-child-3-heading{
    font-size: 1rem;
    font-weight: 700;
  }
  .product-tools-tempalte-human-resource-eight-div-child-3-paragraph{
    width: 43rem;
    font-size: 14px;
    text-decoration: none;
  }

  .product-tools-tempalte-human-resource-eight-div-child-3-paragraph:hover{
    text-decoration-line: underline;
  }

  .product-tools-tempalte-human-resource-eight-div-child-4{
    margin-left: 3rem;
    margin-top: 2rem;

  }
  .product-tools-tempalte-human-resource-eight-div-child-4-heading{
    font-size: 1rem;
    font-weight: 700;
  }
  .product-tools-tempalte-human-resource-eight-div-child-4-paragraph{
    width: 43rem;
    font-size: 14px;
    text-decoration: none;
  }

  .product-tools-tempalte-human-resource-eight-div-child-4-paragraph:hover{
    text-decoration-line: underline;
  }

  .eight-div-child-1-vertical-line {
    width: 1.5px;
    background-color: #0000001a;
    height: 14rem;
    margin-top: 2rem;
   /* Full height of the container */
}

.eight-div-child-3-vertical-line {
  width: 1.5px;
  background-color: #0000001a;
  height: 14rem;
  margin-top: 2rem;
  margin-left: 5rem;
  margin-right: -1.5rem;
 /* Full height of the container */
}

@media (max-width: 600px) {
    .product-tools-tempalte-human-resource-eight-div {
      flex-direction: column; /* Stack children vertically */
      align-items: flex-start; /* Align children to the start */
      margin-bottom: 2rem;
      margin-top: 1rem;
    }
  
    .product-tools-tempalte-human-resource-eight-div-child-1,
    .product-tools-tempalte-human-resource-eight-div-child-2,
    .product-tools-tempalte-human-resource-eight-div-child-3,
    .product-tools-tempalte-human-resource-eight-div-child-4 {
      margin: 1rem 1rem 2rem 1rem; /* Equalized margins for all children */
    }
  
    .product-tools-tempalte-human-resource-third-div-child-1-heading,
    .product-tools-tempalte-human-resource-eight-div-child-2-heading,
    .product-tools-tempalte-human-resource-eight-div-child-3-heading,
    .product-tools-tempalte-human-resource-eight-div-child-4-heading {
      font-size: 0.9rem; /* Slightly smaller font size for headings */
    }
  
    .product-tools-tempalte-human-resource-eight-div-child-1-paragraph,
    .product-tools-tempalte-human-resource-eight-div-child-2-paragraph,
    .product-tools-tempalte-human-resource-eight-div-child-3-paragraph,
    .product-tools-tempalte-human-resource-eight-div-child-4-paragraph {
      width: 100%; /* Full width for paragraphs */
      font-size: 15px; /* Reduced font size for smaller screens */
      text-align: justify; /* Ensure readability */
    }
  
    .product-tools-tempalte-human-resource-eight-div-child-1-paragraph:hover,
    .product-tools-tempalte-human-resource-eight-div-child-2-paragraph:hover,
    .product-tools-tempalte-human-resource-eight-div-child-3-paragraph:hover,
    .product-tools-tempalte-human-resource-eight-div-child-4-paragraph:hover {
      text-decoration-line: underline;
    }
  
    .eight-div-child-1-vertical-line {
      height: 10rem; /* Adjust height for smaller screens */
      margin: -5rem auto; /* Center vertically and adjust spacing */
    }

    .eight-div-child-3-vertical-line {
      height: 10rem; /* Adjust height for smaller screens */
      margin: -5rem auto; /* Center vertically and adjust spacing */
    }
  }

.mysql-section {
    padding: 2rem;
    /* text-align: center; */
    background-color: #f9f9f9;
    margin-left: 3.5rem;
  }
  
  .mysql-heading {
    font-size: 22px;
    font-weight: 800;
    margin-bottom: 1rem;
  }
  
  .mysql-cards {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
  
  .mysql-card {
    width: 30%;
    background: linear-gradient(to bottom, #33553c 50%, #ffffff 50%);
    border-radius: 8px;
    text-align: left;
    overflow: hidden;
    /* border: 1px solid #1e5128; */
  }
  
  .card-top {
    padding: 1.5rem;
    color: white;
  }
  
  .card-top i {
    font-size: 2rem;
    display: block;
    margin-bottom: 1rem;
  }
  
  .card-top h3 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 63px;
  }
  
  .card-bottom {
    padding: 1.5rem;
    color: #000000;
  }
  
  .card-bottom p {
    margin-bottom: 1rem;
    margin-top: 3rem;
    font-size: 14px;
    width: 20rem;
    padding-left: 15px;
    padding-top: 20px;
  }
  
  .card-bottom button {
    background-color: white;
    border: 1px solid #33553c;
    color: #000000;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    /* font-weight: bold; */
    margin-left: 16px;
    margin-top: 5px;
    font-size: 14px;
  }
  
  .card-bottom button:hover {
    background-color: #0a0a0a;
    color: white;
  }

  @media (max-width: 600px) {
    .mysql-section {
      padding: 1rem; /* Reduced padding for smaller screens */
      margin-left: 0; /* Remove extra margin */
      background-color: #f9f9f9;
    }
  
    .mysql-heading {
      font-size: 18px; /* Reduced font size for the heading */
      font-weight: 800;
      margin-bottom: 1rem;
      text-align: center; /* Center-align heading on smaller screens */
    }
  
    .mysql-cards {
      flex-direction: column; /* Stack cards vertically */
      gap: 1.5rem; /* Add spacing between cards */
    }
  
    .mysql-card {
      width: 100%; /* Full width for each card */
      border-radius: 8px;
      overflow: hidden;
      text-align: left;
    }
  
    .card-top {
      padding: 1rem; /* Reduce padding for compact layout */
    }
  
    .card-top i {
      font-size: 1.5rem; /* Reduce icon size */
      margin-bottom: 0.5rem; /* Adjust margin for spacing */
    }
  
    .card-top h3 {
      font-size: 16px; /* Smaller font size for headings */
      margin-top: 1rem; /* Adjust spacing */
    }
  
    .card-bottom {
      padding: 1rem; /* Reduce padding for the card bottom */
    }
  
    .card-bottom p {
      margin-bottom: 1rem;
      margin-top: 3rem; /* Reduced spacing */
      font-size: 13px; /* Slightly smaller text size */
      width: auto; /* Allow text to flow without a fixed width */
      padding-left: 10px;
      padding-top: 10px;
    }
  
    .card-bottom button {
        font-size: 12px;
        padding: 0.5rem -0.2rem;
        margin-left: 10px;
        margin-top: 1rem;
        width: 37%; /* Full width for better usability on mobile */
    }
  
    .card-bottom button:hover {
      background-color: #0a0a0a;
      color: white;
    }
  }