/* General Styling */
.privacy-page {
    width: 100vw;
    height: 100vh;
    position: relative;
  }

/* Image div */
.privacy-img1 {
    background-image: url(./image/blockchain-PrivacyPage-img.jpg); 
    background-size: 100% 100%;
    /* background-position-x: -10%;
    background-position-y: 10%; */
    opacity: 0.7; 
    background-color: #fff; 
    mix-blend-mode: screen; 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100vw; 
    height: 40vh; 
    background-repeat: no-repeat;
    z-index: 0; 
}

/* Header div */
.header-div {
    left: 0;
    top: 0;
    background-color: transparent;
    position: absolute;
    z-index: 1; 
    padding-left: 5vw;
    width: 100vw;
    height: 40vh;
    color: #000; 
}

/* Optional: Add a semi-transparent white overlay to improve readability */
.header-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.849); 
    z-index: -1; 
}
  .header-div{
    padding-left: 5vw;
    width: 100vw;
    height: 40vh;
    z-index: 1;
    position: relative;
  }
  .Link-of-privacy{
    position: relative;
    text-decoration: none;
    color: #000;
    font-size: 16px;
    top: 6px;
  }

  .Link-of-privacy:hover{
    text-decoration:
    underline solid rgb(22, 21, 19);
  }

  .Link-of-privacy .arrow-privacypage svg {
    position: absolute;
    top: -2px ;
    margin-left: -5px;  
  
  }
  
  .header-text {
    top: 40%;
    color: #000;
    position: absolute;
    font-size:32px;
    line-height:38.7072px;
    vertical-align:baseline;
    letter-spacing:normal;
    word-spacing:0px;
    font-weight:400;
    font-style:normal;
    font-variant:normal;
    text-transform:none;
    text-decoration:none solid rgb(22, 21, 19);
    text-align:start;
    z-index: 2;
  }
  .long-short-bg{
    background-image: url(./image/long-short.webp);
    height: 2vh;
    width: 100vw;
  }
  
  /* Navigation Div */
  .dynamic-div {
    display: flex;
    padding-left: 5vw;
    height: 9.5vh;
    background-color: #211E1C;
    padding-top: 4vh;
    
  }
  
  .privacy-list {
    margin: 0 15px;
    position: relative;
    cursor: pointer;
    color:#fff;
    transition: all 0.3s ease;
    font-size:14.336px;
    line-height:20.0704px;
    vertical-align:middle;
    letter-spacing:normal;
    word-spacing:0px;
    font-weight: 400;
    font-style:normal;
    font-variant:normal;
    text-transform:none;
    text-align: left;
    text-indent: 0px;
  }
  
  .privacy-list:hover {
    text-decoration:underline solid rgb(255, 255, 255);
  }
  
  .privacy-list.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color:#F7E0A1;
    transition: width 0.3s ease;
  }
  
  /* Description Div */
  .dynamic-description-div {
    padding-top: 8vh;
    padding-left: 5vw;
    padding-right: 8vw;
    width: 100vw;
    height: 70vh;
    color: #000;;
    background-color: #fff;
    overflow-y: auto;
    font-size:14.336px;
    line-height:20.0704px;
    font-weight:400;

  }
  /* Active state for list items */
.privacy-list.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #f7e0a1;
    transition: width 0.3s ease;
  }
  
  .privacy-list.more {
    cursor: pointer;
    font-size: 16px;
    padding: 5px;
  }

.heading-of-dyanamic-contet{
    font-size: 17.92px;
    line-height: 21.504px;
    vertical-align: baseline;
    letter-spacing: normal;
    word-spacing: 0px;
    font-weight: 800;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    text-decoration: none solid rgb(22, 21, 19);
    text-align: start;
    text-indent: 0px;
}



  @media (max-width: 768px) {

    .privacy-img1 {
      background-image: url(http://localhost:3000/static/media/blockchain-PrivacyPage-img.b9b6b1b….jpg);
      background-size: 100% 100%;
      width: 100vw;
      height: 20vh;
      background-repeat: no-repeat;
      z-index: 0;
  }
  .header-div::before {
        height: 100%;
    }
    .header-div {
      height: 20vh;
  }
  .Link-of-privacy {
    display: none;
  }
  .header-text {
    top: 40%;
    font-size: 24px;
    line-height: 28.2px;

  
}
    .dynamic-div {
        display: flex;
        flex-wrap: wrap; 
        width: 100vw;
        padding-bottom: 4vh;
        padding-left: 0;
        height: 11vh;
    }
    .privacy-list{
        margin-right: 2px;
        font-size: 12px;
        margin-bottom: 6px;
    }

}