
body ,html {
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #161513;
    background-color: #fff;
  }

.customer-img1-div{
    width: 100vw;
    height: 88vh;
    background-image: url(../image/customer/customer-pages-bg-overlay.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #FBF9F8;
}
.customer-img1-div-of-dai-page{
    width: 100vw;
    height: 80vh;
    background-image: url(../image/customer/customer-pages-bg-overlay.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #FBF9F8;
}

.img-and-content-div{
    position: relative;
    width: 100vw;
    height: 55vh;
    display: flex;
    flex-direction: column;
    padding-top: 2vh;
    padding-left: 6vw;
    padding-right: 2vw;
}
.top-image-of-dai-page{
    position: absolute;
    width: 15vw;
    height: 10vh;
    margin-left: 70vw;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 5;
    margin-top: 15vh;
}
.top-image{
    position: absolute;
    width: 68vw;
    height: 45vh;
    margin-left: 22vw;
    background-repeat: no-repeat;
    background-size: cover;
}
.flotting-content-div{
    position: absolute;
    width: 45vw;
    height: 45vh;
    border-radius: 5px;
    background-color: rgb(253, 253, 253);
    z-index: 2;
    margin-top: 5vh;
    padding-top: 4vh;
    padding-left: 4vw;
    padding-right: 5vw;
}
.flotting-content-div-of-dai-page{
    position: absolute;
    width: 90vw;
    height: auto;
    border-radius: 5px;
    background-color: rgb(253, 253, 253);
    z-index: 2;
    padding-top: 4vh;
    padding-left: 4vw;
    padding-right: 30vw;
}

.Link-of-customer{
    position: relative;
    text-decoration: none;
    color: #000000;
    font-size: 16px;
    top: 13px;
  }

  .Link-of-customer:hover{
    text-decoration:
    underline solid rgb(0, 0, 0);
  }

  .Link-of-customer .arrow-customerPage svg {
    position: absolute;
    top: -2px ;
    margin-left: -6px;  
  }

.Customerheader-text {
    margin-top: 2vh;
    font-size:36px;
    line-height:43.2px;
    font-weight:400;
    text-decoration:none solid rgb(22, 21, 19);
}

.customer-normal-text{
    font-size:16px;
    line-height:22.4px;
    vertical-align:baseline;
    letter-spacing:normal;
    word-spacing:0px;
    font-weight:400;
    text-decoration:none solid rgb(22, 21, 19);

  }

.customer-para-special{
    width: 86vw;
    height: 30vh;
    background-color:#FBF9F8;
    position: absolute;
    margin-top: 4vh;
    padding-right: 21vw;
}
.customer-para-special-of-dai-page_{
    width: 86vw;
    height: 50vh;
    background-color:#FBF9F8;
    position: absolute;
    margin-top: 4vh;
    padding-right: 21vw;
}
.share{
    font-size:20px;
    line-height:28px;
    font-weight: 700;
    text-decoration:none solid rgb(22, 21, 19);
}
  .customer-icon-div {
    display: flex;
    flex-direction: row; 
    align-items: center;
    gap: 20px; 
    position: absolute;
    left: 5vw;
    top: 0;
  }
  .customer-icon-div-of-dai-page{
    display: flex;
    flex-direction: row; 
    align-items: center;
    gap: 20px; 
    position: absolute;
    margin-top: -6.5vh;
    margin-left: 12vh;
  }
  .share-bottom-para{
    font-size:21.504px;
    line-height:30.464px;

  }
  .share-bottom-para-of-dai-page{
    font-size:21.504px;
    line-height:30.464px;
    margin-top: 8vh;
  }

.text-in-line{
    font-size:14.336px;
    line-height:19.712px;
    font-weight:600;
}
.flooting-div-2{
    position: absolute;
    border-radius: 5px;
    height: 15vh;
    width: 20vw;
    padding: 5vh;
    background-color: #fff;
    margin-left: 65vw;
    margin-top: 10vh;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .18);
}
.flooting-div-2-of-dai-page{
    display: none;
    border-radius: 5px;
    height: 15vh;
    width: 20vw;
    padding: 5vh;
    background-color: #fff;
    margin-left: 65vw;
    margin-top: -22vh;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .18);
}
.flooting-div-2-para{
    font-size:17.92px;
    line-height:21.504px;
    font-weight:800;
}
.below-info-div{
    width: 100vw;
    height: auto;
    background-color: #FFFFFF;
    padding-left: 25vw;
    padding-right: 22vw;
    padding-top: 10vh;
}
.below-info-div-of-fourseason-page{
    width: 100vw;
    height: auto;
    background-color: #FFFFFF;
    padding-left: 25vw;
    padding-right: 22vw;
    padding-top: 10vh;
}
.below-info-div-of-german-federal-ministry{
    width: 100vw;
    height: auto;
    background-color: #FFFFFF;
    padding-left: 25vw;
    padding-right: 22vw;
    padding-top: 10vh;
}
.below-info-div-of-d-ai-page{
    width: 100vw;
    height: auto;
    background-color: #FFFFFF;
    padding-left: 25vw;
    padding-right: 22vw;
    padding-top: 10vh;
}
.normal-para{
    font-size:14.336px;
    line-height:20.0704px;
    font-weight: 400;

}
.side-by-side-div-info{
    width: 100vw;
    height: auto;
    background-color: #FFFFFF;
    display: flex;
    gap: 25px;
    margin-top: 8vh;
    margin-bottom: 10vh;
}
.sbs{
    width: 30%;
    height: auto;
}
.heading-of-sbs{
    font-size:21.504px;
    line-height:25.8048px;
    font-weight:800;
}
.tag-of-customer{
    text-decoration: none;
    color: #006b8f;
    font-size:14.336px;
    line-height:16.128px;
    font-weight:400;
}
.tag-of-customer:hover{
    text-decoration:underline solid rgb(0, 107, 143)
}



@media screen and (max-width: 768px) {

    
body ,html {
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #161513;
    background-color: #fff;
  }

  .customer-img1-div {
    width: 100vw;
    height: auto;
    background-color: #FBF9F8;
}
  .img-and-content-div {
   
    display: flex;
    flex-direction: row;
    padding-top: 0vh;
    padding-left: 0vw;
    padding-right: 0vw;
}
.top-image {
    width: 100vw;
    margin-left: 0vw;
}
.top-image-of-dai-page{
    width: 35vw;
    height: 5vh;
    margin-left: -5vw;
    margin-top: 0vh;
}
.flotting-content-div {
   
    width: 100vw;
    height: auto;
    border-radius: 0;
    margin-top: 30vh;
    padding-top: 2vh;
    padding-left: 4vw;
    padding-right: 5vw;
}
.flotting-content-div-of-dai-page{
    position: relative;
    width: 100vw;
    height: auto;
    border-radius: 0;
    margin-top: 4vh;
    padding-top: 2vh;
    padding-left: 4vw;
    padding-right: 5vw;
}
.customer-icon-div-of-dai-page {
    margin-left: 0;
}
.Customerheader-text {
    font-size: 28px;
    line-height: 34px;
    font-weight: 400;

}
.customer-normal-text {
    font-size:16px;
    line-height:22.4px;
    font-weight: 400;

}
.customer-para-special {
    width: 100%;
    height: auto;
    background-color: #FBF9F8;
    margin-top: 4vh;
    padding-right: 0vw;
}
.share-bottom-para {
    font-size:20px;
    line-height:26px;
    font-weight:300;
}
.share-bottom-para-of-dai-page {
    font-size:20px;
    line-height:26px;
    font-weight:300;
   
}
.flooting-div-2-of-dai-page {
    height: auto;
    width: 92vw;
    padding: 5vh;
    margin-left: 0vw;
    margin-top: 0vh;
}
.text-in-line{
    font-size:16px;
    line-height:22px;
    font-weight:600;
}
.customer-icon-div {
    left: 0vw;
    top: 5vh;
}
.share {
    margin-bottom: 8vh;
}
.flooting-div-2 {
    height: 12vh;
    width: 92vw;
    padding: 5vh;
    margin-left: 0vw;
    margin-top: 0vh;
    display: none;
}

.below-info-div {
    width: 100vw;
    height: auto;
    background-color: #FFFFFF;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 15px;
    margin-top: 55vh;
}
.below-info-div-of-fourseason-page{
    width: 100vw;
    height: auto;
    background-color: #FFFFFF;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 15px;
    margin-top: 77vh;
}
.below-info-div-of-german-federal-ministry{
    width: 100vw;
    height: auto;
    background-color: #FFFFFF;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 15px;
    margin-top: 77vh;
}
.below-info-div-of-d-ai-page{
    width: 100vw;
    height: auto;
    background-color: #FFFFFF;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 15px;
}
.side-by-side-div-info {
    flex-direction: column;
}
.sbs{
    width: 95%;
    height: auto;
}

}