
html,
:root {
  --primary-color: #161F2D; 
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color:#FFFFFF; 
  color: #161513;
}

body {
  background-color:#FFFFFF; 
  font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
  color: #161513;
  overflow-x: hidden;
}

.all {
  min-height: 100vh;
  background-color:#FFFFFF; 
  font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
  color: #161513;
}


.chat-widget {
  position: fixed;
  bottom: 3vh;
  right: 3vh;
  z-index: 20;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.chat-widget.expanded {
  width: 16vw;
  height: 27vh;
}

.chat-widget.collapsed {
  width: 10vw;
  height: 10vh;
}

.chat-widget-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-widget-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.chat-widget-header-left {
  display: flex;
  align-items: center;
}

.chat-icon {
  margin-right: 8px;
  margin-left: 3vw;
}

.chat-title {
  margin-top: 7px;
  font-size: 16px;
  font-weight: 400;
}

.close-btn {
  background: none;
  border: none;
  font-size: 18px;
  margin-bottom: 1vh;
  cursor: pointer;
  font-weight: 600;
}

.chat-heading{
  margin-top: 2vh;
  margin-left: 5vw;
}

.contact-number {
  margin-left: 3vw;
  font-size: 22px;
  font-weight: 600;
}

.chat-widget-collapsed {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  padding: 10px;
  cursor: pointer;
}

.call-icon-container {
  display: flex;
  align-items: center;
}

.separator {
  height: 50%;
  width: 1px;
  background-color: #ccc;
}

.chat-icon-container {
  display: flex;
  align-items: center;
}



.navbar-logo {
  width: 150px;
  height: 40px;
  object-fit: cover;
  left: 0;
  margin-left: 4rem;
  position: relative;
}


.navbar-items {
  color: #ffffff;
  font-size:16px;
  line-height:20.8px;
  vertical-align:baseline;
  letter-spacing:normal;
  word-spacing:0px;
  font-weight:400;
  font-style:normal;
}

/* Navbar links and hover effects */

.custom-navbar {
  background-color:var(--primary-color);
  position: sticky !important;
  top: 0;
  z-index: 50;
  width: 100%;
}
/* Custom styles for navbar-toggler-icon */
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='white' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* Navbar items container */
.navbar-items {
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 0 4.5rem;
  position: relative;
}

/* Navbar links */
.navbar-nav .nav-item .nav-link {
  color: #ffffff;
  font-size: 16px;
  text-align: center;
}

.dropdown-content {
  border-top: 1px solid black;
  position: absolute;
  top: 99.5%;
  left: 0;
  width: 100%;
  height: 85vh;
  background-color:var(--primary-color);
  color: #ffffff;
  overflow: hidden;
  max-height: 0;
  z-index: 100;
  transition: max-height 0.5s ease-in-out;
}

/* When the dropdown is visible */
.dropdown-content.visible {
  max-height: 90vh;
  overflow-y: auto;
}

/* Dropdown info */
.dropdown-info {
  margin-top: 2vh;
  text-align: left;
}

.dropdown-info h2 {
  font-size: 24px;
  color: #ffffff;

}

.dropdown-info p {
  font-size: 16px;
  color: #cccccc;
  font-size:14px;
  line-height:19.6px;
  vertical-align:baseline;
  letter-spacing:normal;
  word-spacing:0px;
  font-weight:600;
  font-style:normal;
  font-variant:normal;
  text-transform:none;
  text-align:left;
  text-indent:0px;
}

/* Button customization */
.search-icon-button{
  position: relative;
  left: 55.4vw;
  fill: rgba(0, 0, 0, 0);
  stroke-width: 2;
  stroke: #fbf9f8;
  z-index: 20;
}

.search-bar-container {
 position: absolute;
 right: 27vw;
 width: 56vw;
 height: 7.5vh;
 transition: all 0.3s ease-in-out;
 border-top-left-radius:4px ;
 border-bottom-left-radius: 4px;
 padding: 5px;
}
.search-close-button {
  display: flex;
  cursor: pointer;
  position: absolute;
  top: 1.7vh;
  left: 0;
  z-index: 20;
}
.search-bar::placeholder {
 position: absolute;
 top: 1.6vh;
 left: 2.5vw;
 font-size: 18px;
 font-weight: 500;
  text-decoration:none solid rgb(0, 0, 0);
  text-align:start;
  z-index: 20;
  color: #000000;
}
.flag-logo{
  position: absolute;
  right: 25.5vw;
  z-index: 20;
  cursor: pointer;
  width: 25px;
  height: 20px;
}


.contact-btn {
  position: absolute !important;
  right: 2.5vw !important;
  top: 1vh !important;
  margin-top: 2px;
  color: #ffffff !important;
  border: 1.3px solid #ffffff !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  word-spacing: 0px !important;
  letter-spacing: normal !important;
  width: 11vw;
  height: 6vh;
  padding: 0.4rem 0.5rem;
  border-radius: 5.3px !important;
  display: inline-flex;  /* Make the button a flex container */
  align-items: center;   /* Vertically align the icon and text */
  justify-content: center; /* Horizontally center the contents */
  z-index: 20;
}

.contact-icon {
  margin-right: 0.5rem; /* Space between the icon and text */
  width: 20px;  /* Icon width */
  height: 20px; /* Icon height */
}

.account-btn {
  position: absolute;
  color: #ffffff;
  top: 1vh;
  margin-top: 2px;
  background-color: #161513;
  width: 10vw;
  height: 6vh;
  right: 14.2vw;
  z-index: 20;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  line-height: normal;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  font-weight: 600;
  font-style: normal;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center; 
  justify-content: center; 
  padding-left: 10px; 
}

/* Style for the icon inside the button */
.account-btn svg {
  width: 18px;
  height: 18px;
  margin-right: 8px; 
  stroke-width: 2;
  color: white; 
}
.acc-btn a {
  color: white;
  text-decoration: none; 
}

.account-btn.active {
  background-color: var(--primary-color);
}

.account-dropdown {
  position: absolute;
  top: 8vh;
  right: 15.5vw;
  width: 25vw;
  height: 55vh;
  background-color:#FBF9F8;
  border-radius: 5px;
  box-shadow: -3px 5px 20px rgba(0, 0, 0, 0.326);
  padding: 10px;
  z-index: 1000;
}

.section {
 
 padding-left: 3vh;
 padding-right: 3vh;
 padding-top: 3vh;
  margin-bottom: 5px;
}

.section h3 {
  margin: 0 0 5px;
  font-size: 14px;
  color: #333;
  padding-bottom: 2vh;
}

.acc-btn {
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  border-radius: 4px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.acc-btn-1:hover {
  background-color:#161513;
}
.acc-btn-3:hover {
  background-color:#161513;
}

hr {
  border-top: 2px solid #a2a2a2 !important;
}
.acc-btn-4 , .acc-btn-2{
  background-color: #ffffff;
  color: #000000;
  border: 1px solid black;
  margin-top: 5px;
}

.accback-btn {
  display: none; /* Hidden by default */
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}


.dpage {
  width: 100vw;
  height:auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
 
}

.A {
  display: flex;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  height: 20%;
  min-height: 5rem;
  font-size: 21px;
  font-weight: 800;
  line-height: 21.504px;
  overflow: hidden;
  padding-top: 4.5rem;
  margin-left: 2px;
  padding-top: 1rem;
  margin-top: 20vh;
  padding-left: 3vw;
  padding-right: 3vw;
}

.list-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-weight: 700;
  margin-top: 1rem;
  font-size: 14.336px;
  line-height: 20.0704px;
  letter-spacing: normal;
  text-align: left;
  word-spacing: 0px;
  position: relative;
  border-bottom: 3px solid #E9E7E4;
  
}

.list {
  display: flex;
  list-style-type: none;
  padding-left: 3vw;
  padding-right: 3vw;
  width: 100%;
  flex-wrap: wrap;
}

.list-item {
  margin-right: 20px;
  cursor: pointer;
  padding: 5px;
  position: relative;

}
.list-item img {
  width: 130px; 
  height: 50px; 
  object-fit: contain;
 
}
.img-1:hover {
  filter: hue-rotate(220deg) saturate(5); /* Vibrant opposite color tint */
}

.img-2:hover {
  filter: hue-rotate(90deg) saturate(5); /* Greenish tint */
}

.img-4:hover {
  filter: sepia(5) saturate(300%) brightness(1.5); /* Sepia with brighter tones */
}

.img-5:hover {
  filter: sepia(3) saturate(800%) hue-rotate(200deg); /* Sepia with red tint */
}

.img-6:hover {
  filter: hue-rotate(270deg) saturate(5); /* Purple tint */
}


.list-item::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2.8vh;
  width: 100%;
  height: 3px;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.list-item:hover::after {
  background-color: #4C825C;
}

/* Underline effect for the selected item */
.list-item.active {
  text-decoration: underline;
  
}

/* Flex container for the first and second divs */
.flex-container {
  padding-left: 5vw;
  display: flex;
  justify-content: space-between;
  color: #000000;
  width: 100vw;
  height: 83vh;
  position: relative;
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
}

/* First div (for content) */
.first-div {
  position: absolute;
  top: 20vh;
  width: 40vw;
  height: auto;
  background-color: #ffffff;
  text-align: start;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 5vh;
  padding-bottom: 2vh;
}
.fist_div-button{
  color: #fff !important;
  background: var(--primary-color);
  font-size: 1em;
  font-weight: 500;
  line-height: 1.2;
  padding: 0.7rem 1rem 0.7rem;
  border-radius: 4px;
  cursor: pointer;
  border: 0;
  text-align: center;
  margin-top: 2vh;
  transition: all 1s ease;
}
.fist_div-button:hover{
  background-color:#161513;
}
.first-div h2 {
  font-size:22px;
  line-height:33.6px;
  vertical-align:baseline;
  letter-spacing:normal;
  word-spacing:0px;
  font-weight:700;
  font-style:normal;
  font-variant:normal;
  text-transform:none;
  text-decoration: none solid rgb(22, 21, 19);
  text-align:start;
  text-indent:0px;
}
.content-div {
  display: none;
  transition: opacity 5s ease-in-out;
}

.content-div.active {
  display: block; 
  opacity: 1; 
}

.footer {
  width: 100vw;
  height: 40vh;
  background-color: transparent;
}
.footer-bottom{
  background-color:var(--primary-color);
  color: #ddd;
  padding: 0.3rem;
  position: absolute;
  width: 100vw;
  height: 10vh;
  padding-top: 2vh;
  padding-left: 10vw;
  font-size:12px;
  line-height:24px;
  vertical-align:baseline;
  letter-spacing:normal;
  word-spacing:0px;
  font-weight:400;
  font-style:normal;
  font-variant:normal;
  text-transform:none;
  text-align:left;
  text-indent:0px;
}
.footer-separator {
  margin: 0 10px; /* Adjust the value for the desired gap */
}
.footer-separator:hover{
  text-decoration:underline solid rgb(251, 249, 248);
}


.f_list {
  background-color:var(--primary-color);
  color: #ddd;
  height: 50vh;
  padding-left: 9vw;
  padding-right: 10vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
}

.list-section {
  flex: 1;
  padding: 3rem 2rem;

}

.list-section h3 {
  font-size: 21px;
  font-weight: bold;
  color: #ABA7A6; 
}

.list-section ul {
  padding-left: 0;
  list-style-type: none;
  padding-top: 1rem;

}

.list-section li {
  font-size: 14px;
  padding: 0.3rem;
  font-weight: 600;
}

.list-section a {
  text-decoration: none; 
  color: inherit;
}

.list-section a:hover {
  text-decoration: underline;
  color: #ffffff;
}

.nav-link {
  position: relative;
  text-decoration: none;
  color: inherit;
}

.nav-link::after {
  content: '';
  position: absolute;
  left:0;
  bottom: 7px;
  width: 0;
  height: 1px;
  background-color: #ffffff;
  transition: width 0.3s ease;
}
.nav-item.active .nav-link::after {
  content: none
}
.nav-link:hover::after {
  width: 86%;
  margin-left: 0.3vw;
}
.nav-item.active .nav-link {
  border-bottom: 1px solid #ffffff;
  padding-bottom: 0; 
  margin-bottom: 0; 
  width: 100%; 
  padding-left: -4vw;
  padding-right: -6vw;

}

/* images */

/* Container for the whole scroller */


.slider {
  background-image: url(./image/slider-bg-img.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 75vh;
  padding-top: 2vh;
 z-index: 1;
}

.slides {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  left: 15vw;
  position: relative;
  height: 80%;
  color: #fff;

}
.slide {
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 1s ease, transform 1s ease;
  transform: translateX(50%);

}

.slide.active {
  opacity: 1;
  transform: translateX(0);
  z-index: 1;
  
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;  
}

.column img {
  width: 60%;
  height: 55vh;
  object-fit: cover;
  
}

.column.content {
  padding: 50px;
  width: 40%;
  height: 59vh;
  left: 45%;
  top: 8%;
  position: absolute;
  color: #000000;
  background-color: #fff;
  opacity: 1; /* Initially hidden */
  z-index: 2;
}

@keyframes fadeIn {
  0% {
    opacity: 0; /* Fully transparent at the start */
  }
  10%{
    opacity: 0.2;
  }
  50%{
    opacity: 0.5;
  }
  90%{
    opacity: 0.8;
  }
  100% {
    opacity: 1; /* Fully visible at the end */
  }
}

.column.content.fade-in {
  animation: fadeIn 1s ease-in forwards; /* 1-second fade-in animation */
}

.content h1 {
  font-size:28px;
  line-height:36.4px;
  vertical-align:baseline;
  letter-spacing:normal;
  word-spacing:0px;
  font-weight:400;
  font-style:normal;
  font-variant:normal;
  text-transform: none;
  text-decoration:none solid rgb(22, 21, 19);
  text-align:start;
  text-indent:0px;
  margin-bottom: 2vh;
  
}

.content p {
  font-size:16px;
  line-height: 22.4px;
  vertical-align: baseline;
  letter-spacing:normal;
  word-spacing: 0px;
  font-weight: 400;
  font-style: normal;
  font-variant:normal;
  text-transform:none;
  text-decoration:none solid rgb(22, 21, 19);
  text-align: start;
  text-indent:0px;
  margin-bottom: 4vh;
}

.btns {
  height: 6.3vh;
  width: 30%;
  padding-top: 2.5%;
  display: inline-block;
  border-radius: 2vw;
  background-color:var(--primary-color)  ; 
  color: #ffffff;
  border-radius: 5px;
  font-size:12.544px;
  line-height:15.0528px;
  vertical-align:baseline;
  letter-spacing:normal;
  word-spacing:0px;
  font-weight:500;
  text-decoration: none;
  font-style: normal;
  text-align: center;
}

.btns:hover{
  background-color:#161513 ;
}

.navigation {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 2; 
 
}

.navigation button {
  background: transparent;
  color: rgb(0, 0, 0);
  border: none;
  font-size: 2.5rem;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.navigation .prev {
  left: 6vw; 
}

.navigation .next {
  right:  7vw; 
}

.bullets {
  display: flex;
  justify-content: center;
  left: 50%;
  position: absolute;
  bottom: 1%;
}

.bullet {
  width: 15px;
  height: 15px;
  margin: 5px;
  background: #ddd;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s;
 
}

.bullet.active {
  background: #000000;
}


  
.hpt {
  font-size: 32.256px;
  line-height: 38.7072px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none solid rgb(22, 21, 19);
  text-align: start;
  text-indent: 0px;
}
.dropdown-info a {
  color: inherit;
  font-size: 14px;
  line-height: 15px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  font-weight: 600;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none solid rgb(204, 204, 204);
  text-align: start;
  text-indent: 0px;
}

.dropdown-info a:hover {
  text-decoration: underline solid rgb(240, 204, 113);
  color: #ffffff;
}

/* Container with background image */
.img-middle {
  position: relative; 
  width: 100%;
  height: 90vh; 
  z-index: 0; 
}

/* Pseudo-element for background image */
.img-middle::before {
  content: "";
  position: absolute; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./image/middle-bg-img.jpg'); 
  background-color:#e4e0dd;
  mix-blend-mode: initial;
  background-size: 300px 300px ; 
  filter: opacity(0.7); 
  z-index: -1;
}

/* Content area */
.middle {
 
  height: 80vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 6rem;
  padding-right: 6rem;
  gap: 1rem;
  position: relative; 
  z-index: 1; 
 
}

/* Individual content boxes */

.m1 {
  margin-top: 7vh;
  background-color: #FFFFFF !important;
  width: 70%;
  height: 85%;
  border-bottom: 5px solid #4C825C;
  position: relative; 
  z-index: 2; 
}

.mimg {
  width: 80%;
  height: 25%;
  margin-left: 2vw;
  bottom: 5rem;
  position: absolute;
  background-size: contain;
  background-position: center;
}


.mh1 {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  font-size: 22px;
  line-height: 32px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  font-weight: 800;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none solid rgb(22, 21, 19);
  text-align: start;
  text-indent: 0px;
}

.mp1 {
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 14px;
  line-height: 25px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none solid rgb(22, 21, 19);
  text-align: start;
  text-indent: 0px;
}


.ma {
  color: #006DA1;
  position: absolute;
  bottom: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-align: start;
  text-indent: 0px;

}

.banner {
 
  width: 100vw;
  height: 40vh;
  display: flex;
  overflow: hidden;
  position: relative;
  color: #ffffff;
}

.b1,
.b2 {
  width: 100%;
  height: 100%;
}

.b1img,
.b2img {
  width: 100%;
background-size: contain;
 background-position-x: 70%;
 position: absolute;
  
}


.bh1 {
  position: absolute;
  padding-top: 3rem;
  padding-left: 29.5vw;
  text-align: end;
  font-size: 21.504px;
  line-height: 28.672px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-transform: capitalize;
  text-decoration: none solid rgb(255, 255, 255);
  text-indent: 0px;
}
.bh1a {
  font-size: 28.672px;
  line-height: 35.84px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  font-weight: 800;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none solid rgb(251, 249, 248);
  text-align: end;
  text-indent: 0px;
}

.bp {
  position: absolute;
  padding-top: 10vw;
  padding-left: 18vw;
  text-align: end;
  font-size: 14.336px;
}

.bh12 {
  position: absolute;
  padding-top: 3rem;
  text-align: start;
  margin-left: 6vw;
  font-size: 21.504px;
  line-height: 28.672px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-transform: capitalize;
  text-decoration: none solid rgb(255, 255, 255);
  text-indent: 0px;
}
.bh1a2 {
  font-size: 28.672px;
  line-height: 35.84px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  font-weight: 800;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none solid rgb(251, 249, 248);
  text-align: end;
  text-indent: 0px;
}

.bp2 {
  margin-left: 6vw;
  position: absolute;
  padding-top: 10vw;
  text-align: start;
  font-size: 14.336px;
}

.button1 {
  width: 5.5vw;
  margin-top: 2vh;
  height: 6vh;
  position: absolute;
  bottom: 2vh;
  background-color: #fff;
  border-radius: 5px;
  margin-left: 36.8vw;
  margin-bottom: 3vh;
  font-size: 12px;
  font-weight: 500;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  line-height: 35.84px;
  text-align: center;
  border: none;
}

.button2 {
  width: 5.5vw;
  border: none;
  margin-top: 2vh;
  height: 6vh;
  position: absolute;
  bottom: 2vh;
  background-color: #fff;
  border-radius: 5px;
  margin-left: 6vw;
  margin-bottom: 3vh;
  font-size: 12px;
  font-weight: 500;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  line-height: 35.84px;
  text-align: center;
}

/* Container for the "Who We Are" flex layout */


.grid-links ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Two columns */
  gap: 3px 30px; 
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.grid-links ul ul {
  grid-column: span 3; 
  padding-left: 20px;
  list-style-type: disc; 
}

.lattice-list strong {
  display: block;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.lattice-list li {
  font-size: 14px;
  padding: 5px 0;

}

.dropdownborder {
  margin: 10px 0;
  border: 1px solid rgb(240, 204, 113, 0.8);
}
.dropdown-info{
  padding-left: 5.5%;
}
.customer-story{
 
  position: absolute;
  right: 2vw;
  font-size:16px;
  line-height:22.4px;
  vertical-align:baseline;
  letter-spacing:normal;
  word-spacing:0px;
  font-weight:400;
  text-decoration: none !important;
}
.customer-story-a{
  color: #006B8F;
  text-decoration: none;
}






/* responsive  */



/* Media Query for Small Screens (up to 768px) */
@media (max-width: 768px) {

  html,
  body {
    margin: 0;
    width: 100vw;
    padding: 0;
    background-color:#ffffff;
  }

  .navbar-logo {
    left: 0 !important;
    width: 150px;
    margin-left: 0vw;
    margin-top: 2vh;
    object-fit: cover;
    height: 40px;
  }

  /* 1. Adjust contact button text overflow */
  .contact-btn {
    position: relative;
    margin-top: 0.2vh;
    margin-right: 21vw;
    width: 40px;  
    padding: 0.5rem;
    border: none !important;
  }

  .contact-btn span {
    display: none;  /* Hide the text on mobile */
  }
  .acc-btn a {
    color: white;
    text-decoration: none; /* Remove the default underline */
  }

  .contact-icon {
    margin-right: 0; /* Remove the space between icon and text */
    width: 25px;  /* Adjust icon size for mobile */
    height: 25px; /* Adjust icon size for mobile */
    position: absolute;
    top: 2.3vh;
    right: -10vw;
  }
 

  /* 2. Navbar Toggler Button */
  .navbar-toggler {
    z-index: 20;
    position: absolute;
    right: 0;
    top: 2.55vh;
  }
  /* 3. Navbar should cover full screen width */
  .custom-navbar {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
  }
 .navbar-items {
  border-top: 1px solid black;
 }
  
.grid-links ul {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Two columns */
  gap: 3px 30px; 
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.grid-links ul ul {
  grid-column: span 1; 
  padding-left: 20px;
  list-style-type: disc; 
}

.lattice-list strong {
  display: block;
  margin-bottom: 10px;
  font-size: 1.2rem;
}
.lattice-list li {
  font-size: 14px;
  padding: 5px 0;
}

.dropdownborder {
  margin: 10px 0;
  border: 1px solid rgb(240, 204, 113, 0.8);
}
.dropdown-info{
  padding-left: 5.5%;
}
  /* Adjust dropdown info styling */
  .dropdown-info {
   
    font-size: 1.2rem;
    z-index: 100;
  }

  .dropdown-content {
    overflow-y: auto;
  }

  /* When the dropdown is visible */
  .dropdown-content.visible {
    max-height: 100vh;
  }

  .account-btn {
    width: 40px; 
    height: 40px; 
    padding: 0; 
    position: relative;
    margin-top: -3.3vh;
    margin-right: 3.8vw;
    justify-content: center; 
    background-color: transparent;
  }

  /* Hide the text inside the button */
  .account-btn span {
    display: none;
    opacity: 0;
  }

  /* Adjust the icon to be bigger on mobile */
  .account-btn svg {
    margin-right: 0; 
    width: 24px;  
    height: 22.5px;
    background-color: transparent;
    z-index: 20;
  }

.account-btn.active {
  background-color: var(--primary-color);
}
.search-icon-button{
  top: 1.5vh;
  left: -31vw;
 
}
.flag-logo{
 margin-top: 2.8vh;
 margin-right: 7vw;
 z-index: 1000;
}
.search-bar-container{
  margin-top: 3vh;
  margin-right: 10vw;
  width: 60vw;
  height: 5vh;
  z-index: 20;
}
.search-close-button {
  margin-top: -0.8vh;
}
.search-bar::placeholder {
  margin-left: 4vw;
  margin-top: -6.3px;
  font-size: 1rem;
}
.navbar-items {
  padding: 0;
  padding-top: 2vh;
  padding-left: 5vw;
}
.navbar-nav .nav-item .nav-link {
  text-align: left;
}
.account-dropdown {
  position: absolute;
  top: 8vh;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color:#FBF9F8;
  border-radius: 5px;
  box-shadow: -3px 5px 20px rgba(0, 0, 0, 0.326);
  padding: 10px;
  z-index: 20;
}

.section {
 
 padding-left: 3vh;
 padding-right: 3vh;
 padding-top: 3vh;
  margin-bottom: 5px;
}

.section h3 {
  margin: 0 0 5px;
  font-size: 14px;
  color: #333;
  padding-bottom: 2vh;
}

.acc-btn {
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  border-radius: 4px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.acc-btn:hover {
  background-color:#161513;
}

hr {
  border-top: 2px solid #a2a2a2 !important;
}
.acc-btn-4 , .acc-btn-2{
  background-color: #ffffff;
  color: #000000;
  border: 1px solid black;
  margin-top: 5px;
}
.accback-btn {
  display: block; /* Show on mobile */
 
}
.dropdown-content {
  border-top: 1px solid black;
}


.scroller-img-bg {
  height: 80vh; 
  background-size: cover; 
  position: relative;
}

/* Slider Styles */
.slider {
  height: auto; 
  width: 100vw;
  position: relative;
}

/* Slide Styles */
.slides {
  width: 100vw; 
  left: 0;
  height: 84vh;
  position: relative;
}

.slide {
  transform: translateX(0); 
}
.row {
  flex-direction: column; 

}

/* Image Styles */
.column img {
  position: absolute;
  top: -47vh;
  width: 100vw; 
  height: 32vh; 
  background-size: contain;
 
}

/* Content Styles */
.column.content {
  padding: 20px;
  width: 100vw; /* Make content section full width with padding */
  height: 50vh; /* Let the content adjust its height based on content */
  left: 0; /* Center content on mobile */
  top: 36vh;
  position: absolute;
  background-color: #ffffff;
  opacity: 1;
}

/* Heading and Paragraph in Content */
.content h1 {
  font-size: 24px; /* Reduce font size for mobile */
  line-height: 30px;
  margin-bottom: 1.5vh; /* Add some spacing below the heading */
}

.content p {
  font-size: 14px; /* Reduce font size for mobile */
  line-height: 20px;
  margin-bottom: 3vh; /* Add spacing below the paragraph */
}

/* Button Styling */
.btns {
  width: 40%; 
  font-size: 14px; 
  padding-top: 2vh; 
  height: 6vh;
  border-radius: 1vw; 
}

.btns:hover {
  background-color: #161513;
}

.navigation button {
  display: none;
  font-size: 2rem; /* Smaller navigation buttons */
  padding: 8px;
}

/* Bullet Navigation Styles */
.bullets {
  display: flex;
  justify-content: center;
  left: 47%;
  position: absolute;
  bottom: 62%;
  transform: translateX(-50%); 
  z-index: 5;
}

.bullet {
  width: 40px; 
  height: 10px;
  border-radius: 8px;
  margin: 5px;
}

.bullet.active {
  background: #000000;
}

/* Animation for fading content */
.column.content.fade-in {
  animation: fadeIn 1s ease-in forwards;
}

/* Keyframes for fade-in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}


.middle {
  flex-direction: column;  
  padding-left: 0;     
  padding-right: 0;
  gap: 15px;       
  height: auto;    
  margin-top: 12vh;       
  width: 100vw;     
 
}
.img-middle {
  height: auto;
}
/* Adjust individual content boxes */
.m1 {
  width: 80vw;            
  height: 45vh ;               
  position: relative;      
  border-bottom: 5px solid  #4C825C;
  background-color:  #ffffff !important;    
  margin-bottom: 1vh; 
  margin-top: 3vh;
}

/* Adjust image size for mobile */
.mimg {
  width: 70%;             
  height: 20%;            
  left: 10%;    
  bottom: 15%;     
     
}

/* Adjust headings and text for smaller screens */
.mh1 {
  font-size: 18px;         
  line-height: 26px;       
  padding-left: 1rem;      
  padding-right: 1rem;
}

.mp1, .mp2, .mp3, .mp4 {
  font-size: 12px;        
  line-height: 18px;      
  padding-left: 1rem;    
  padding-right: 1rem;
}

.ma {
  font-size: 14px;         
  bottom: 0.5rem;          
  text-decoration: underline; 
}


/* 7. Make sure list sections are responsive */
.footer {
  width: 100vw;
  height: 40vh;
}

.f_list {
  background-color: var(--primary-color);
  color: #ddd;
  padding-left: 3vw;
  padding-right: 4vw;
  padding-top: 6vh;
  height: 40vh;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}

.list-section {
  width: 100vw;
  padding: 0.2rem 5rem;
  padding-left: 2rem;
  padding-right: 5rem;

}
.list-section h3 {
  width: 78vw;
  font-size: 21px;
  font-weight: bold;
  color: #ABA7A6;
}

/* Heading styles for mobile */
.footer-heading {
  font-size: 14px;
  cursor: pointer;
  position: relative;
  
}

/* Arrow icon for dropdown */
.footer-heading .arrow {
  position: absolute;
  right: 0;
  font-size: 18px;
  transition: transform 0.3s ease;
  transform: none;
}

/* Rotate the arrow when dropdown is open */
.footer-heading .arrow.rotate {
  transform: rotate(180deg);
}

/* The resource list is hidden by default */
.resource-list {
  display: none;
  padding-left: 0;
  list-style-type: none;
  margin-top: 10px;
  overflow-y: auto;
}

/* Show the resource list when it is open */
.resource-list.show {
  display: block;
}

.resource-list ul {
  padding-left: 20px;
}

.resource-list ul li {
  margin-bottom: 8px;
}

.footer-bottom {
  background-color: var(--primary-color);
  color: #ddd;
  font-size: 10px;
  padding: 0.3rem;
  font-weight: 600;
  position: absolute;
  width: 100vw;
  height: 10vh;
}
.footer-separator {
  margin: 0 40px; /* Adjust the value for the desired gap */
}

  .dpage {
    width: 100vw;
    height: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .A {
    display: flex;
    align-items: center;
    text-align: left;
    width: 100vw;
    height: auto;
    font-size: 21px;
    font-weight: 800;
    line-height: 21.5px;
    overflow: hidden;
    margin: -5vh 0;
    z-index: 2;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 4vh;
    margin-bottom: 1vh;
  }

  .list-container {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    position: relative;
    border-bottom: none;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    list-style-type: none;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0;
    margin-top: 3vh;
  }

  .list-item {
    margin: 0;
    cursor: pointer;
    font-size: 16px;
    background-color: #fff;
    transition: background-color 0.3s ease;

  }

  .list-item:last-child {
    margin-bottom: 0;
  }
  .list-item.active {
   border-bottom: 2px solid #000000;
  }

  .list-item::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 3px;
    background-color: transparent;
    transition: background-color 0.3s ease;
  }

  * {
    box-sizing: border-box;
  }

  .list-container,
  .list-item {
    max-width: 100%;
    overflow: hidden;
  }
  .customer-story {
    position: absolute;
    right: 65.3vw;
    margin-top: 13vh;
    font-size: 12px;
  }
  .hpt {
    font-size: 22.256px;
    margin-bottom: 5vh;
    margin-top: 2vh;
  }

  .flex-container {
    display: flex;
    width: 100%;
    height: 50vh;
    background-size: cover;
  }

  .first-div {
    width: 82vw;
    height: 35vh;
    padding: 1rem;
    background-color: transparent;
    color: #000000;
    background-color: #fff;
    top: 8vh;
  }

  .content-div {
    display: none;
  }

  .content-div.active {
    display: block;
  }

  
.banner {
  width: 100vw;
  flex-direction: column; 
  height: 60vh; 
  margin-top: 5vh;
  overflow-y: hidden;
  margin-bottom: 5vh;
 
}

.b1, .b2 {
  flex: 1;
  height: 50vh; 
  width: 100vw; 
  position: relative; 
}
.b1img,
.b2img {
  width: 100%;
  height: 100%;
  background-size: cover;
  position: absolute;
}


.bh12{
  text-align: start; 
  top: -4vh;
  left: 0;
  font-size: 16px;
  margin-bottom: 1vh;

}
.bh1 {
  text-align: start; 
  top: -4.5vh;
  left: -90px;
  font-size: 14px;
  margin-bottom: 1vh;
}
.bh1a2{
  text-align: start; 
  top: 8vh;
  margin-top: 3vh;
  position: absolute;
  left: 0;
  font-size: 18px;
  
}
.bh1a{
  text-align: start; 
  top: 8vh;
  margin-top: 3vh;
  position: absolute;
  font-size: 18px;
}

.bp2 {
  text-align: start; 
  padding: 0.1rem 0.2rem; 
  bottom: 6vh;
  left: 0;
  font-size: 14px;
}
.bp{

  text-align: start; 
  padding: 0.1rem 0.2rem; 
  bottom: 8vh;
  left: 3vh;
  font-size: 14px;

}

.button2 {
  position:absolute; 
  display: block;
  width: 25vw;
  height: 5vh;
  bottom: -2vh;
  left: 0;
  
}
.button1{
  position:absolute; 
  display: block;
  width: 25vw;
  height: 5vh;
  bottom: -2vh;
  left: -30vw;
}
.img-6:hover {
  filter: none
}

.img-2:hover {
  filter:none
}
.list-item img {
  width: 100px;
  height: 50px;
}

.chat-widget.expanded {
  width: 50vw;
  height: 20vh;
}

.chat-widget.collapsed {
  width: 4rem; /* Small circular size for collapsed view */
  height: 4rem;
  border-radius: 4px; /* Circular shape for icon-only view */
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-icon {
  width: 30px; 
  height: 30px;
  margin-right: 2vw;
}

.chat-widget-header-left {
  display: flex;
  align-items: center;
  margin-left: 2vw;
}

.chat-title {
  font-size: 14px;
}

.contact-number {
  font-size: 16px;  
  margin-left: 10vw;
}
.chat-heading {
  margin-left: 13vw;
}
.call-icon-container{
  display: none;
}
.separator {
  display: none;
}


}


@media (min-width: 769px) {
  .resource-list {
    display: block;
  }

  .footer-heading .arrow {
    display: none;
  }

  .brand-name {
    font-size: 30px;
  }

  /* Show the arrow for each brand to toggle dropdown */
  .brand-header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px;
  }

  /* Display the dropdown list when it's toggled */
  .dropdown-list {
    display: none;
    /* Hidden by default */
  }

  .dropdown-list.show {
    display: block;
    /* Show when toggled */
  }

  /* Rotate the arrow when dropdown is open */
  .arrow.rotate {
    transform: rotate(180deg);
  }

  /* Make sure the content of selected brand appears below the list */
  .first-div {
    margin-top: 20px;
  }

  .content-div {
    display: none;
  }

  .content-div.active {
    display: block;
  }

}


.country-selector-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.country-selector-modal {
  background: white;
  border-radius: 8px;
  max-width: 1100px;
  max-height: 550px; 
  padding: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.8);
  position: relative;
  overflow-y: auto; 
}
.country-selector-modal h5 {
  font-size: 20px;
  font-weight: 600;
}

.country-btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 2rem; /* Larger size for "X" */
  color: #fff;
  cursor: pointer;
}
.country-btn-close:hover {
  color: #fff;
  cursor: pointer;
}

.country-selector-list {
  margin-top: 15px;
  column-count: 6;
  column-gap: 2rem;
  row-gap: 1rem;
}


.country-selector-list p {
  font-size: 14px;
  font-weight: 600;
  width: 150px;
  margin-bottom: 0;
  margin-top: 5px;
}

.country-selector-list a {
  display: block;
  margin-top: 5px;
  color: #006b8f;
  text-decoration: none;
  width: 150px;
}

.country-selector-list a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1400px) {
  .navbar .navbar-collapse {
    position: absolute;
    top: 99.5%;
    left: 0;
    width: 100%;
    padding-left: 4.5rem;
    background: var(--primary-color);
  }

  .navbar .container-fluid {
    position: relative;
  }

}

.navbar > .container-fluids{
  display: flex !important;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1180px) {
  .country-selector-modal {
    max-width: none;
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }

  .country-selector-list {
    column-count: 4;
  }
}
@media (max-width: 768px) {
  .container-fluids{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
  }

  .country-selector-list {
    column-count: 2;
  }

  .country-selector-modal h5 {
    font-size: 16px;
  }
}
@media (max-width: 550px) {

  .country-selector-list {
    column-count: 1;
  }
}

.contact-btn, .account-btn{
  height: 2.5rem;
  white-space: nowrap;
}
.account-btn:hover{
 background-color: #000000;
 color: #fff;
}



.cookie-modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start; 
  z-index: 1000;
  transition: top 0.3s ease, bottom 0.3s ease;
  padding-top: 20px; 
}

.cookie-modal {
  background: white;
  padding: 20px;
  width: 60%;
  text-align: center;
  border: 10px solid rgba(0, 0, 0, 0.4); 
  background-clip: padding-box; 
}

.cookie-modal-overlay.modal-open {
  top: 0px;
  bottom: auto;
}

.cookie-modal-overlay.modal-closed {
  bottom: 20px;
  top: auto;
  opacity: 0; 
  pointer-events: none; 
}

.loading-spinner {
  font-size: 18px;
  color: #555;
  padding: 20px 0;
}

.heading-of-cookies-with-logo{
  display: flex;
  width: 100%;
  height: 5vh;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dadadd;
}
.heading-of-cookies{
  font-size: 21px;
  font-weight: 600;
  line-height: 20.0704px;
}
.img-logo-of-cookies-header{
  filter: invert(1);
  width: 200px;
  height: 100px;
}
.div-2-heading-below-text-and-list{
  width: 100%;
  height: auto;
  padding: 4vh 0;
  text-align: start;
}
.div-2-para-below-heading-ofcookies{
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  color: #8e8e8e;
}
.div-2-cookies-list-text{
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  color: #8e8e8e;
}
.bold-cookies-peferance{
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
  color: #4c4c4c;
}

.button-group {
  display: flex;
  gap: 10px;
}

.accept-btn {
  background-color: #729a74;
  color: white;
  padding: 8px 15px;
  font-size: 12px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.deny-btn {
  background-color:#729a74;
  color: white;
  padding: 8px 15px;
  font-size: 12px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.accept-btn:hover {
  background-color: #75a277;
  color: white;
}
.deny-btn:hover {
  background-color:#75a277;
  color: white;
}
.cookie-content{
  position: relative;
}
.close-btn-of-cookies{
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  color: black;
  border: none;
  border-radius: 50%; 
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
 z-index: 2000;
}
@media (max-width:786px) {

  .cookie-modal-overlay {
    padding-top: 80px; 
  }
  .cookie-modal {
    width: 90%;
  }
.heading-of-cookies-with-logo{
  text-align: start;
}
.img-logo-of-cookies-header{
 display: none;
}
  
}