body {
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #161513;
    background-color: #fff;
  }


/* 1-div */

.product-infra-compute-first-div{
    width: 100vw;
    height: auto;
    background-color: #F0EFED;
    display: flex;
    padding: 6vh 6vw;
    gap: 5vw;
}
.product-infra-compute-first-div-heading{
    font-size: 50.176px;
    line-height: 60.2112px;
    font-weight: 400;
}
.product-infra-compute-first-div-paragraph{
    font-size: 21.504px;
    line-height: 30.1056px;
    font-weight: 400;
    width: 50vw;
   
}
.product-infra-compute-first-div-img-and-para{
    width: 27vw;
    height: auto;
    background-color: #fff;
    overflow: hidden; 
    position: relative;
}
.product-infra-compute-first-div-btn-1{
    font-size: 12.544px;
    line-height: 15.0528px;
    font-weight: 500;
    color: #FFF;
    background-color: var(--primary-color);
    transition: all ease 0.3s;
    border-radius: 5px;
    margin-right: 2vw;
    padding: 1.5vh 1vw;
}
.product-infra-compute-first-div-btn-1:hover{
    background-color: #161513;
}
.product-infra-compute-first-div-btn-2{
    font-size: 12.544px;
    line-height: 15.0528px;
    font-weight: 500;
    color: #000000;
    transition: all ease 0.3s;
    border: 1px solid #000000;
    border-radius: 5px;
  padding: 1.5vh 1vw;
}
.product-infra-compute-first-div-btn-2:hover{
    background-color: #161513;
    color: #FFFFFF;
}
.product-infra-compute-img-of-first-div{
    width: 100%;
    height: 85%;
    transition: transform 0.3s ease; 
}
.product-infra-compute-title-of-img{
    font-size: 12.544px;
    line-height: 17.5616px;
    font-weight: 700;
    padding: 1.5vh 1.2vw;
    width: 100%;
}
.product-infra-compute-img-of-first-div:hover{
    transform: scale(1.05); 
}

@media (max-width:600px) {

    .product-infra-compute-first-div{
        width: 100vw;
        height: auto;
        background-color: #F0EFED;
        display: flex;
        flex-direction: column;
        padding: 6vh 4vw;
        gap: 5vw;
    }
    .product-infra-compute-first-div-heading{
        font-size: 46px;
        line-height: 58.2112px;
        font-weight: 400;
    }
    .product-infra-compute-first-div-paragraph{
        font-size: 20.504px;
        line-height: 28px;
        font-weight: 400;
        width: 90vw;
    }
    .product-infra-compute-first-div-img-and-para{
        width: 90vw;
        height: auto;
        background-color: #fff;
        overflow: hidden; 
        position: relative;
    }
    .product-infra-compute-first-div-btn-1{
        margin-right: 2vw;
    }
    .product-infra-compute-img-of-first-div{
        width: 100%;
        height: 85%;
    }
    .product-infra-compute-title-of-img{
        padding: 0vh 0vw;
        width: 100%;
    }
    
}

/* 2-div */

.product-infra-compute-sec-div{
    width: 100vw;
    height:auto;
    background-color: #04232D;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    gap: 30px;
    padding: 4vh 2vw;
}
.product-infra-compute-sec-div-heading{
    font-size: 17.92px;
    line-height: 21.504px;
    font-weight: 800;
    width: 50%;
}
.product-human-identity-sec-div-heading{
  font-size: 17.92px;
    line-height: 21.504px;
    font-weight: 800;
    width: 50%;
}
.product-infra-compute-sec-div-para{
    font-size: 14.336px;
    line-height: 20.0704px;
    font-weight: 400;
    padding-top: 6px;
}
.product-infra-compute-sec-div-btn{
    font-size:  12.544px;
    line-height: 15.0528px;
    font-weight: 500;
    background-color: #fff;
    color: #000000;
    width: max-content;
    border: none;
    border-radius: 5px;
    padding: 12px 15px;
    }

    .product-networking-compute-sec-div{
      width: 100vw;
    height: auto;
    background-color: #33553C;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    gap: 30px;
   padding-top: 4vh;
   margin-top: 2rem;
    }
    .product-networking-compute-sec-div-heading{
    font-size: 17.92px;
    line-height: 21.504px;
    font-weight: 800;
    /* width: 50vw;
    margin-bottom: 4vh; */
    }

    .product-networking-compute-sec-div-btn{
    font-size:  12.544px;
    line-height: 15.0528px;
    font-weight: 500;
    background-color: #fff;
    color: #000000;
    width: 10vw;
    height: 5.5vh;
    border: none;
    border-radius: 5px;
    margin-bottom: 4vh;
    }

    .product-tools-tempalte-human-identity-second-div-para-btn{
      background-color: #D6E5E5;
      width: 100vw;
      height: 13vh;
      position: relative;
      left: 0vw;
      display: flex;
      gap: 25px;
      justify-content: center;
      align-items: center;
      margin-top: 4rem;
      margin-left: -7rem;
    }

@media (max-width:600px) {
    .product-infra-compute-sec-div{
        flex-direction: column;
        align-items: start;
        justify-content: start;
        gap: 5px;
        padding: 2vh 4vw;
    }
    .product-infra-compute-sec-div-heading{
        width: 90vw;
    }
    .product-infra-compute-sec-div-para{
        padding-top: 10px;
    }
    .product-infra-compute-sec-div-btn{
        width: auto;
        height: auto;
        padding: 1vh 1vh;
        }

        .product-networking-compute-sec-div{
        width: 100vw;
        height: 14vh;
        background-color: #33553C;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        gap: 30px;
       padding-top: 4vh;
       margin-top: 2rem;
        }
        .product-networking-compute-sec-div-heading{
          font-size: 17.92px;
        line-height: 22.504px;
        font-weight: 800;
        width: 69vw;
        margin-bottom: 4vh;
        margin-left: 20px;
        }
    
        .product-networking-compute-sec-div-btn{
          font-size:  12.544px;
        line-height: 15.0528px;
        font-weight: 500;
        background-color: #fff;
        color: #000000;
        width: 28vw;
        height: 5.5vh;
        border: none;
        border-radius: 5px;
        margin-bottom: 4vh;
        margin-right: 20px;
        }

        .product-tools-tempalte-human-identity-second-div-para-btn{
        padding: 2vh 4vw;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: start;
        align-items: start;
        height: auto;
        margin-top: 3rem;
        margin-left: -1rem;
        }
    
}

/* 3-div */

.product-infra-compute-third-div{
    width: 100vw;
    height: 45vh;
    background-color: #FDFDFD;
    padding: 6vh 6vw;
}
.product-infra-compute-third-div-heading{
    font-size: 32.256px;
    line-height: 38.7072px;
    font-weight: 400;
}
.product-infra-compute-third-div-cards-wrapper{
    display: flex;
    gap: 25px;
    width: 100%;
    padding-right: 6vw;
}
.product-infra-compute-third-div-card-1{
    display: flex;
    gap: 15px;
    width: 35%;
    height: 100% ;
}
.product-infra-compute-third-div-card-1-content{
    width: 25vw;
}
.product-infra-compute-third-div-card-1-logo-all{
    width: 4vw;
    height: 4vh;
  
}
.product-infra-compute-third-div-card-1-all-heading{
    font-size: 25.088px;
    line-height: 30.1056px;
    font-weight: 800;
}
.product-infra-compute-third-div-card-1-all-para{
    font-size: 14.336px;
    line-height: 20.0704px;
    font-weight: 400;
}

@media (max-width:600px) {

    
.product-infra-compute-third-div{
    height: auto;
    padding: 4vh 4vw;
}
.product-infra-compute-third-div-heading{
    font-size: 28px;
    line-height: 32.7072px;
}
.product-infra-compute-third-div-cards-wrapper{
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding-right: 4vw;
}
.product-infra-compute-third-div-card-1{
    display: flex;
    gap: 10px;
    width: 100%;
    height: 100% ;
}
.product-infra-compute-third-div-card-1-content{
    width: 80vw;
}
.product-infra-compute-third-div-card-1-logo-all{
    width: 10vw;
    height: 4vh;
   
}
.product-infra-compute-third-div-card-1-all-heading{
    font-size: 21.088px;
    line-height: 28.1056px;
    font-weight: 800;
}
.product-infra-compute-third-div-card-1-all-para{
    font-size: 13.336px;
    line-height: 20.0704px;
    font-weight: 400;
}
    
}

/* div-4 */

.product-infrs-div-four-solution-heading{
    font-size: 32.256px;
    line-height: 28.7072px;
    font-weight: 500;
}

.product-infrs-div-four-content-of-resources {
    width: 100vw;
    height: auto;
    background-color:#F3F2F0;
    padding: 6vh 6vw;
  }
  
  .product-infrs-div-four-content-wrapper-of-resource {
    display: flex;
    flex-direction: row;
    position: relative; 
  }
  .product-infrs-div-four-content-1-of-resource {
    width: 30vw;
    height: auto;
    margin-top: 3vh;
    margin-left: -3vw;
    z-index: 2;
    background-color: transparent;
    position: relative;
  }
  .product-infrs-div-four-content-1-of-resource ul {
    list-style: none;
  }
  .product-infrs-div-four-btn-resource {
    font-size: 16.128px;
    line-height: 22.5792px;
    font-weight: 600;
    border: none;
    margin-top: 1.5vh;
    padding: 10px 20px;
    border-radius: 4px;
    width: 18vw;
    text-align: left;
    background-color: transparent;
  }
  
  .product-infrs-div-four-btn-resource.active {
    background-color:#1E3224;
    color: #fff;
  }
  
  .product-infrs-div-four-combined-content {
    background-color: #fff;
    width: 100vw;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 2vw 6vw ;
    z-index: 1;
    margin-left: -4vw;
  }
  
  .product-infrs-div-four-top-content {
    text-align: left;
    margin-bottom: 2vh;
  }
  
  .product-infrs-div-four-resource-heading {
    font-size: 18px;
    line-height: 22px;
    font-weight: 800;
    margin-bottom: 1vh;
  }
  
  .product-infrs-div-four-resource-description {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 2vh;
  }
  
  .product-infrs-div-four-resource-btn {
    font-size: 14px;
    font-weight: 600;
    color: white;
    background-color: var(--primary-color);
    border: none;
    padding: 8px 18px;
    border-radius: 4px;
  }
  .product-infrs-div-four-resource-btn:hover{
    background-color: #161513;
  }
  
  .product-infrs-div-four-grid-content {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2vw;
    height: 100%;
  }
  
  .product-infrs-div-four-grid-item {
    width: calc(48% - 4vw);
    border-radius: 5px;
    padding: 1vw;
  }
  
  .product-infrs-div-four-grid-heading {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 1vh;
  }
  
  .product-infrs-div-four-grid-para {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 1vh;
  }
  
  .product-infrs-div-four-grid-link {
    font-size: 14px;
    font-weight: 400;
    color: #006b8f;
    text-decoration: none;
  }
  

@media (max-width:600px) {

    
.product-infrs-div-four-solution-heading{
    font-size: 28.256px;
    line-height: 28.7072px;
    font-weight: 600;
}

.product-infrs-div-four-content-of-resources {
    width: 100vw;
    height: auto;
    background-color:#F3F2F0;
    padding: 6vh 6vw;
  }
  
  .product-infrs-div-four-content-wrapper-of-resource {
    display: flex;
    flex-direction: column;
    position: relative; 
  }
  .product-infrs-div-four-content-1-of-resource {
    width: 30vw;
    height: auto;
    margin-top: 0vh;
    margin-left: -5vw;
    z-index: 2;
    background-color: transparent;
    position: relative;
  }

  .product-infrs-div-four-btn-resource {
    font-size: 16.128px;
    line-height: 22.5792px;
    font-weight: 600;
    border: none;
    margin-top: 1.5vh;
    padding: 10px 10px;
    border-radius: 4px;
    width: 60vw;
    text-align: left;
    background-color: transparent;
  }
  
  .product-infrs-div-four-combined-content {
    background-color: #fff;
    width: 100vw;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 4vh 6vw ;
    z-index: 1;
    margin-left: -6vw;
  }
  .product-infrs-div-four-grid-content {
    flex-direction: column;
    row-gap: 1vh;
  }
  
  .product-infrs-div-four-grid-item {
    width: 100%;
  }
  
    
}

/* div-5 */

.product-infra-div-five{
  width: 100vw;
  height: auto;
  background-color: #fff;
  padding: 6vh 6vw;
}
.product-infra-div-five-small-para{
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.product-infra-div-five-cards-wrapper{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
}
.product-infra-div-five-card-1{
  width: 32%;
  height: auto;
  background-color: #FBF9F8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .1); 
  position: relative;
  border-radius: 5px;
  padding: 4vh 2vw;
  margin-top: 4vh;
  opacity: 1;
  z-index: 2;
}
.product-infra-div-five-card-1::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../image/cloud/texture-of-product-compute-five-div.png);
  background-size: contain;
  background-position: center;
  opacity: 0.04;
  z-index: 1;
}
.product-infra-div-five-all-card-heading{
  font-size: 40.32px;
  line-height: 56.448px;
  font-weight: 800;
}
.product-infra-div-five-all-card-heading-of-scm{
  font-size: 21.32px;
  line-height: 28.448px;
  font-weight: 800;
}
.product-infra-div-five-all-card-para{
  font-size:  16.128px;
  line-height: 22.5792px;
  font-weight: 800;
}
.product-infra-div-five-all-card-para-of-scm{
  font-size:  12.128px;
  line-height: 17.5792px;
  font-weight: 800;
}
.product-infra-div-five-btns-wrapper{
  width: 100vw;
  height: auto;
  padding: 6vh 0;
  display: flex;
  flex-direction: row;
  gap: 25px;
}
.product-infra-div-five-btn-1{
  width: 15%;
  padding: 11px 4px;
  font-size: 12.544px;
  line-height: 15.0528px;
  font-weight: 500;
  border: none;
  background-color: var(--primary-color);
  color: #FFFFFF;
  border-radius: 5px;
}
.product-infra-div-five-btn-2{
  width: 11%;
  padding: 11px 4px;
  font-size: 12.544px;
  line-height: 15.0528px;
  font-weight: 500;
  background-color: #fff;
  border: 1px solid #000000;
  border-radius: 5px;
}
.product-infra-div-five-btn-1:hover{
  background-color: #161513;
}
.product-infra-div-five-btn-2:hover{
  background-color: #161513;
  color: #FFFFFF;
}
.product-infra-div-five-very-small-para{
  font-size: 12px;
  line-height: 15.0528px;
  font-weight: 400;
}

.product-infra-div-five-small-para{
  font-size: 12px;
  line-height: 15.0528px;
  font-weight: 400;
  margin-top: 2rem;
}

@media (max-width:600px) {
.product-infra-div-five-card-1{
  width: 100%;
}

.product-infra-div-five-btns-wrapper{
  flex-direction: column;
}
.product-infra-div-five-btn-1{
  width: 55%;
  padding: 15px 4px;
}
.product-infra-div-five-btn-2{
  width: 40%;
  padding: 10px 4px;
}  
}

/* div-6 */

.product-infra-div-six{
  width: 100vw;
  height: auto;
  padding: 6vh 6vw;
  background-color: #F5F4F2;
}
.product-infra-div-six-text-wrapper{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  justify-content: space-between;
}
.product-infra-div-six-heading-oftxt-wrapper{
  font-size: 21.504px;
  line-height: 25.8048px;
  font-weight: 800;
}
.product-infra-div-six-link-of-txt-wrapper{
  font-size: 16px;
  color: #006b8f;
  line-height: 22.4px;
  font-weight: 400;
  text-decoration: none;
}
.product-infra-div-six-link-of-txt-wrapper:hover{
  text-decoration: 1px underline  #006b8f;
}
.product-infra-div-six-cards-wrapper{
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  width: 100%;
  height: auto;
  margin-top: 4vh;
}
.product-infra-div-six-card-1{
  width: 23.5%;
  height: auto;
  padding: 4vh 1vw;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 16px 0 rgba(0, 0, 0, .18);
  transition: all ease 0.2s;
}
.product-infra-div-six-card-1:hover{
  transform: scale(1.05);
  cursor: pointer;
}
.product-infra-div-six-card-1:hover .product-infra-div-six-card-1-all-links {
  text-decoration: underline;
}
.product-infra-div-six-card-1-para-1{
  font-size: 10.752px;
  line-height:15.0528px;
  font-weight:400;
  color: #161513;
}
.product-infra-div-six-card-1-all-links{
  font-size: 17.92px;
  line-height: 21.504px;
  font-weight: 800;
  color: #000000;
  text-decoration: none;
}
.product-infra-div-six-card-1-all-links:hover{
  text-decoration: 2px underline #000000;
}
.product-infra-div-six-card-1-all-para{
  padding-top: 2vh;
  padding-bottom: 10vh;
  font-size: 14.336px;
  line-height: 20.0704px;
  font-weight: 400;
  color:rgba(22, 21, 19, 0.65) ;
}

@media (max-width:600px) {
.product-infra-div-six{
  width: 100vw;
  height: auto;
  padding: 4vh 4vw;
  background-color: #F5F4F2;
}
.product-infra-div-six-cards-wrapper{
  flex-direction: column;
}
.product-infra-div-six-card-1{
  width: 100%;
  padding: 4vh 4vw;
}
.product-infra-div-six-card-1-all-para{
  padding-top: 2vh;
  padding-bottom: 6vh;
}
  
}


/* div-7 */

.product-infra-div-seven{
  width: 100vw;
  height: auto;
  padding: 6vh 6vw;
  background-color: #FBF9F8;
}
.product-infra-div-seven-img-wrapper{
  display: flex;
  flex-wrap: wrap;
  column-gap: 10vw;
  row-gap: 50px;
  width: 100%;
  height: auto;
  padding: 6vh 6vw;
}
.product-infra-div-seven-img-1{
  width: 15%;
  height: 10vh;
 
}

@media (max-width:600px) {

  .product-infra-div-seven-img-wrapper{
    flex-direction: column;
    padding: 4vh 4vw;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
  .product-infra-div-seven-img-1{
    width: 80%;
    height: 5vh;
  }
  
}

/* div-8 */

.product-infra-div-eight{
  width: 100%;
  height: auto;
  padding: 8vh 6vw;
  background-color: #fff;
}
.product-infra-div-eight-heading{
  font-size: 21.504px;
  line-height: 25.8048px;
  font-weight: 800;
}
.product-infra-div-eight-cards-wrapper{
  display: flex;
  gap: 4vw;
  width: 100%;
  height: auto;
  padding: 1vh 2vw;
}
.product-infra-div-eight-card-1{
  width: 30%;
  height: auto;
  background-color: var(--primary-color);
  border-radius: 8px;
  box-shadow: 0px 5px 12px 0px rgba(22, 21, 19, .2);
  cursor: pointer;
}
.product-infra-div-eight-card-1-content-div{
  width: 100%;
  height: 45vh;
  padding: 4vh 2vw;
}
.product-infra-div-eight-card-1-img-div{
  width: 100%;
  height: 25vh;
  background-color: rgb(118, 162, 210);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.product-infra-div-eight-card-1-content-div-all-links{
  font-size: 14.336px;
  line-height: 20.0704px;
  font-weight: 400;
  color: #f0cc71;
  text-decoration: none;

}
.product-infra-div-eight-card-1-content-div-all-heading{
  font-size: 21.504px;
  line-height: 26.88px;
  font-weight: 800;
  color: #FFFFFF;
  padding: 4vh 0;
}
.carousel-controls{
  display: none;
}

@media (max-width:600px) {
    .product-infra-div-eight-cards-wrapper {
      display: flex;
      overflow: hidden;
      scroll-snap-type: x mandatory;
    }
    .product-infra-div-eight-card-1 {
      min-width: 100%;
    }
    .product-infra-div-eight-card-1-content-div{
      width: 100%;
      height: 35vh;
      padding: 4vh 8vw;
    }
    .product-infra-div-eight-card-1-img-div{
      height: 20vh;
    }
    .carousel-controls {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      margin-top: 2vh;
    }
    .carousel-controls button {
      border: none;
      font-size: 1rem;
      cursor: pointer;
      padding: 0.5rem 1rem;
      border-radius: 4px;
    }
    .carousel-controls span {
      font-size: 1.2rem;
      font-weight: bold;
      color: var(--primary-color);
    }
    .carousel-controls button:disabled {
      cursor: not-allowed;
    }

}


/* div-9 */

.product-infra-last-div{
  width: 100vw;
  height: auto;
  background-color: #E4E1DD;
  padding: 8vh 6vw;
}
.product-infra-last-div-wrapper{
  width: 100%;
  height: auto;
  background-color: #fff;
  padding: 10vh 6vw;
}
.product-infra-last-div-heading{
  font-size: 56px;
  line-height: 67.2px;
  font-weight: 500;
}
.product-infra-last-div-1-list-card-wrapper{
  display: flex;
  gap: 25px;
  width: 100%;
  height: auto;
}
.product-infra-last-div-1-list-card-1{
  width: 45%;
  height: auto;
}
.product-infra-last-div-1-list-card-2{
  width: 28%;
  height: auto;
  text-align: start;
}
.product-infra-last-div-1-list-card-1-all-main-heading{
  font-size: 24px;
  line-height: 32px;
  padding-bottom: 5px;
  font-weight: 800;
}
.product-infra-last-div-1-list-card-1-all-main-para{
  font-size: 18px;
  line-height: 25.2px;
  font-weight: 400;
  padding-bottom: 5px;
}
.product-infra-last-div-1-list-card-1-all-main-btn{
  font-size: 12.544px;
  line-height: 15.0528px;
  font-weight: 500;
  border: none;
  background-color: var(--primary-color);
  color: #FFFFFF;
  padding: 12px 12px;
  border-radius: 4px;
}
.product-infra-last-div-1-list-card-1-all-main-btn:hover{
  background-color: #161513;
}
.product-infra-last-div-1-list-card-1-all-sub-heading{
  font-size: 17.92px;
  line-height: 24.192px;
  font-weight: 700;
}
.product-infra-last-div-list-1-card-1-list-all{
  font-size: 13.75px;
  line-height: 16.5px;
  font-weight: 400;
  text-align: start;
  padding-top: 1vh;
}
.product-infra-last-div-2-list-card-1{
  width: 55%;
  height: auto;
}
.product-infra-last-div-2-list-card-2{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  height: auto;
}
.product-infra-last-div-2-list-card-2-child-1{
  width: 45%;
  height: auto;
}
.product-infra-last-div-1-list-card-1-all-sub-para{
  font-size: 13.75px;
  line-height: 16.5px;
  font-weight: 400;
}
.product-infra-last-div-1-list-card-1-all-sub-link{
  font-size: 13.75px;
  line-height: 16.5px;
  font-weight: 400;
  color: #006b8f;
  text-decoration: none;
}

@media (max-width:600px) {

  
.product-infra-last-div{
  width: 100vw;
  height: auto;
  background-color: #E4E1DD;
  padding: 2vh 6vw;
}
.product-infra-last-div-wrapper{
  width: 100%;
  height: auto;
  background-color: #fff;
  padding: 10vh 6vw;
}
.product-infra-last-div-heading{
  font-size: 32px;
  line-height: 38.2px;
  font-weight: 500;
}
.product-infra-last-div-1-list-card-wrapper{
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  height: auto;
}
.product-infra-last-div-1-list-card-1{
  width: 100%;
  height: auto;
}
.product-infra-last-div-1-list-card-2{
  width: 100%;
  height: auto;
  text-align: start;
}
.product-infra-last-div-1-list-card-1-all-main-heading{
  font-size: 21px;
  line-height: 28px;
  padding-bottom: 5px;
  font-weight: 800;
}
.product-infra-last-div-1-list-card-1-all-main-para{
  font-size: 18px;
  line-height: 25.2px;
  font-weight: 400;
  padding-bottom: 5px;
}
.product-infra-last-div-2-list-card-1{
  width: 100%;
  height: auto;
}
.product-infra-last-div-2-list-card-2{
  flex-direction: column;
}
.product-infra-last-div-2-list-card-2-child-1{
  width: 100%;
  height: auto;
}

}


.nav-bar-of-lci-overview-wrapper{
  width: 100vw;
  height: auto;
  padding: 5px 3vw;
  background-color: #211E1C;
  position: sticky;
  top: 7.7vh;
  z-index: 30;
  display: flex;
  align-items: center;
  overflow-x: auto;
}

@media (max-width:786px) {

  .nav-bar-of-lci-overview-wrapper{
    padding: 5px 0px;
    align-items: start;
  }

  .nav-bar-lci-over-view-items ul{
    align-items: start;
    width: max-content;
  }
  
}

.nav-bar-lci-over-view-items ul{
  display: flex;
  gap: 15px;
  align-items: center;
  background-color: #161f2d;
}
.item-1-of-lci-over-view-nav{
  border: none;
  background-color: #161F2D;
  font-size: 16px;
  line-height: 20.8px;
  font-weight: 400;
  color: #fff;
  position: relative;
  transition: all ease 0.3s;
}
.item-1-of-lci-over-view-nav::after {
  content: '';
  position: absolute;
  left:0;
  right: 0;
  bottom: 0px;
  width: 0;
  height: 1px;
  background-color: #ffffff;
  transition: width 0.3s ease;
}
.item-1-of-lci-over-view-nav:hover::after {
  width: 90%;
  margin-left: 0.2vw;
}