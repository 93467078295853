/* General Page Styling */

.sign-in-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-image: url(../src/image/bg-of-sign-in.jpg);
}

.sing-in-heading {
    color: black;
    position: absolute;
    top: 24vh;
    font-weight: bold;
}

.sign-in-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 30vw;
    height: 80vh;
    padding: 80px;
    background-color: white;
    border-radius: 5px;
}

.sign-in-form {
    margin-top: 7vh;
    display: flex;
    flex-direction: column;
    width: 25vw;
}

.email-input {
    padding: 12px;
    border: none;
    border-bottom: 2px solid #ccc;
    font-size: 16px;
    margin-bottom: 2vh;
    background: transparent;
}

.email-input:focus {
    outline: none;
    border-bottom: 2px solid #0066cc;
}

.next-btn {
    padding: 10px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%;
}

.next-btn:hover {
    background-color: #504C4A;
}

.forgot-username {
    text-align: center;
}

.help-link {
    color: #0066cc;
    text-decoration: none;
}

.help-link:hover {
    text-decoration: underline;
}

.create-account {
    margin-top: 40px; 
    display: flex;
    flex-direction: column;
    width: 25vw;
}

.account-text {
    margin-top: 10vh;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    text-align: center;
}

.account-textp {
    text-align: center;
    color: black;
    margin-top: 2vh;
}

.create-account-btn {
    padding: 10px;
    background-color: #ffffff; /* White background */
    border: 2px solid var(--primary-color); /* Add a visible border */
    color: var(--primary-color); /* Add visible text color */
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.create-account-btn:hover {
    background-color: #f5f5f5; 
    border-color: #504C4A; 
    color: #504C4A;
}

.transparent-gap {
    margin-top: 32vh;
    height: 4vh; 
    width: 30vw;
    background-color: #FBF3DC; 
    position: absolute;
}

/* Responsive Styling for Smaller Screens */
@media (max-width: 768px) {
    .sign-in-wrapper, .create-account {
        width: 80vw; 
        padding: 40px; 
    }

    .sign-in-form, .create-account {
        width: 70vw; 
    }
    .sing-in-heading {
        color: black;
        position: absolute;
        top: 20vh;
        font-weight: bold;
    }

    .email-input {
        font-size: 14px; 
    }

    .next-btn{
        width: 30vw;
        position: absolute;
        font-size: 14px;
        left: 35%;
        top: 46vh;
        padding: 8px; 
        
    }
    .account-text {
       
        margin-bottom: 19vh;
        font-size: 18px;
        font-weight: bold;
        color: #333;
        text-align: center;
    }
     .create-account-btn {
        width: 30vw;
        position: absolute;
        font-size: 14px;
        left: 35%;
        top: 75vh;
        padding: 8px; 
    }

 

    .transparent-gap {
        height: 2vh; 
        width: 80vw; 
    }

    .account-text {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .sign-in-wrapper, .create-account {
        width: 90vw; 
        padding: 30px;
    }

    .sign-in-form, .create-account {
        width: 85vw; 
    }

    .next-btn{
        width: 30vw;
        position: absolute;
        font-size: 14px;
        left: 35%;
        top: 40vh;
        padding: 8px; 
        
    }
    .account-text {
       
        margin-bottom: 19vh;
        font-size: 18px;
        font-weight: bold;
        color: #333;
        text-align: center;
    }
     .create-account-btn {
        width: 30vw;
        position: absolute;
        font-size: 14px;
        left: 35%;
        top: 70vh;
        padding: 8px; 
    }


    .sing-in-heading {
        font-size: 16px;
        top: 20vh; 
    }

    .transparent-gap {
        height: 1.5vh;
        width: 90vw;
    }
}
