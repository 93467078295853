body {
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #161513;
    background-color: #fff;
}

.Lattice-news-div-heading-h1 {
    font-size: 32.256px;
    line-height: 38.7072px;
    font-weight: 700;
}

.news-page-news-container {
    width: 100vw;
    height: auto;
    background-color: #FFF;
    padding: 4vh 6vw;
    margin-bottom: 25px;
}

.news-page-upper-area-img {
    width: 90vw;
    height: auto;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    gap: 25px;
    padding: 8vh 4vw;
}

.news-page-img-of-one-div-of-uai {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 5vh;
}

.news-page-upper-area-img-one {
    position: relative;
    overflow: hidden;
}

.news-page-img-of-one-div-of-uai {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 5vh;
    transition: transform 0.8s ease;
    cursor: pointer;
}

.news-page-img-of-one-div-of-uai:hover {
    transform: scale(1.1);
}

.news-page-heading-of-img {
    font-size: 28.672px;
    line-height: 34.4064px;
    font-weight: 700;
    color: rgb(0, 0, 0);
    text-decoration: none;
}

.news-page-heading-of-img:hover {
    text-decoration: underline 2px solid rgb(0, 0, 0);
}

.news-page-upper-area-img-sec {
    display: flex;
    width: 60vw;
    height: 70vh;
    flex-direction: column;
    gap: 25px;
}

.news-page-upper-area-img-sec-one-img {
    display: flex;
    gap: 15px;
}

.news-page-img-one-uaisoi {
    width: 28vw;
    height: 25vh;
    border-radius: 8px;
    background-size: cover;
    overflow: hidden;
    position: relative;
    z-index: 22;
}

.news-page-img-one-uaisoi img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    transition: transform 0.5s ease;
    cursor: pointer;
}

.news-page-img-one-uaisoi img:hover {
    transform: scale(1.1);
    /* Zoom the image */
}

.news-page-one-link-heading {
    font-size: 14.336px;
    line-height: 17.2032px;
    font-weight: 700;
    color: #000;
    text-decoration: none;
}

.news-page-one-link-heading:hover {
    text-decoration: underline 1px solid rgb(0, 0, 0);
}

.news-page-img-content {
    padding-top: 7.5vw;
    position: relative;
}

.news-page-search-wrapper {
    width: 100vw;
    height: 28vh;
    background-color: #FFF;
    padding-left: 5vw;
    padding-right: 5vw;
}

.news-page-search-area {
    width: 100%;
    height: 20vh;
    background-color: #D9E5EA;
    display: flex;
    border-radius: 5px;
    gap: 20px;
    padding: 4vh 3vw;
}

.news-page-search-box-heading {
    font-size: 17.92px;
    line-height: 21.504px;
    font-weight: 800;
    margin-bottom: 2vh;
}

.news-page-border-between {
    width: 0.16vw;
    height: 13vh;
    background-color: #C5D0D4;
}

.news-page-sign-up-div {
    width: 50%;
    height: 100%;
    position: relative;
}

.news-page-heading-and-side-btn {
    width: 50%;
    height: 100%;
    padding-left: 3vw;
}

.news-page-search-input {
    outline: none;
    border: 1px solid #000;
    width: 95%;
    height: 50%;
    padding-left: 3vw;
    border-radius: 5px;
}

.news-page-search-icon {
    position: absolute;
    left: 10px;
    margin-top: 1.3vh;
    width: 25px;
    height: 25px;
    filter: brightness(0);
}

.news-page-search-input::placeholder {
    color: #aaa;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.news-page-search-input:focus::placeholder {
    opacity: 1;
}

.news-page-search-btn {
    font-size: 12.544px;
    line-height: 15.0528px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    background-color: var(--primary-color);
    color: #FFF;
    padding: 1.5vh 1vw;
}

.news-page-search-btn:hover {
    background-color: #161513;
}

.news-section-of-news-page {
    width: 100vw;
    height: auto;
    background-color: #fff;
    padding-left: 5vw;
    padding-right: 8vw;
    display: flex;
    justify-content: space-between;
    color: #000;
    overflow-x: auto;
    margin-bottom: 10vh;
}

.news-floating-div {
    width: 20vw;
    height: 40vh;
    background-color: #7A736E;
    color: #FFF;
    border-radius: 10px;
    padding: 6vh 1vw;
}

.content-under-heading-of-news-page {
    display: flex;
    width: 60vw;
    height: auto;
    padding-top: 6vh;
}

.news-heading-of-all-section {
    font-size: 21.504px;
    line-height: 25.8048px;
    font-weight: 800;
}

.news-page-small-div {
    font-size: 14.336px;
    line-height: 20.0704px;
    font-weight: 400;
    width: 11vw;
}

.news-page-heading-and-para {
    width: 80vw;
}

.main-heading-of-news {
    font-size: 17.92px;
    line-height: 21.504px;
    font-weight: 800;
    text-decoration: none;
    color: #000000;
}

.main-heading-of-news:hover {
    text-decoration: underline 2px solid #000000;
    cursor: pointer;
}

.description-of-heading-of-news {
    margin-top: 1.5vh;
    font-size: 14.336px;
    line-height: 20.0704px;
    font-weight: 400;
    color: rgb(122, 115, 110);
}

.news-floating-div-heading {
    font-size: 17.92px;
    line-height: 21.504px;
    font-weight: 800;
    color: #FFF;

}

.news-floating-div-para {
    font-size: 14.336px;
    line-height: 20.0704px;
    font-weight: 400;
    color: #FFF;
}

.news-floating-div-btn {
    font-size: 12.544px;
    line-height: 15.0528px;
    font-weight: 500;
    border: none;
    width: 6vw;
    height: 5.8vh;
    margin-top: 1vh;
    border-radius: 5px;
    background-color: #fff;
}

.news-floating-div-btn:hover {
    background-color: #F1EFED;
}

.news-floating-div-btn:focus {
    border: none;
    outline: none;
    position: relative;
}

.news-floating-div-btn:focus::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    border: 2px dotted black;
    pointer-events: none;
}

.see-more-btn-news-page {
    border: 1px solid #000000;
    font-size: 12.544px;
    line-height: 15.0528px;
    font-weight: 500;
    width: 7vw;
    height: 5.5vh;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 3vh;
    margin-bottom: 8vh;
    transition: all ease 0.3s;
}

.see-more-btn-news-page:hover {
    background-color: #161513;
    color: #FFF;
}

.news-page-last-card-container {
    width: 100vw;
    height: 65vh;
    background-color: #F1EFED;
    padding: 6vh 5vw;
}

.news-page-last-card-heading {
    font-size: 21.504px;
    line-height: 25.8048px;
    font-weight: 800;
}

.news-page-card-wrapper {
    display: flex;
    gap: 25px;

}

.news-page-card-1 {
    width: 33vw;
    height: 46vh;
    background-color: #fff;
    margin-top: 2vh;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.img-news-page-card {
    background-color: #325C72;
    position: relative;
    width: 100%;
    height: 50%;
    padding: 6vh 2vw;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    z-index: 0;
    color: #fff;
}

.text-span-of-news-page-last-div {
    margin-top: 9vh;
    font-size: 21.504px;
    line-height: 25.8048px;
    font-weight: 800;
}

.img-news-page-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../image/customer/new-last-div-overlay-img.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    opacity: 0.3;
    mix-blend-mode: overlay;
    z-index: 1;
}

.news-page-content-of-card {
    width: 100%;
    padding: 6vh 2.5vw;
    height: 50%;
}

.news-page-card-links {
    font-size: 17.92px;
    line-height: 25.088px;
    font-weight: 400;
    color: rgb(0, 107, 143);
    text-decoration: none;

}

.news-page-card-links:hover {
    text-decoration: underline 1.5px solid rgb(0, 107, 143);
}

@media (max-width:600px) {
    body {
        font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
        color: #161513;
        background-color: #fff;
    }

    .Lattice-news-div-heading-h1 {
        font-size: 32.256px;
        line-height: 38.7072px;
        font-weight: 700;
        margin-top: 8vh;
    }

    .news-page-news-container {
        width: 100vw;
        height: 163vh;
        background-color: #FFF;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .news-page-upper-area-img {
        width: 90vw;
        height: auto;
        border-radius: 10px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 4vh 4vw;
    }

    .news-page-img-of-one-div-of-uai {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
        border-radius: 8px;
        margin-bottom: 5vh;
    }

    .news-page-img-of-one-div-of-uai {
        border-radius: 8px;
        margin-bottom: 5vh;
        transition: transform 0.8s ease;
        cursor: pointer;
    }

    .news-page-heading-of-img {
        font-size: 24.672px;
        line-height: 34.4064px;
        font-weight: 700;
        color: rgb(0, 0, 0);
        text-decoration: none;
        z-index: 22;
    }

    .news-page-upper-area-img-sec {
        display: flex;
        width: 100%;
        height: 70vh;
        flex-direction: column;
        gap: 20px;
    }

    .news-page-upper-area-img-sec-one-img {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .news-page-img-one-uaisoi {
        width: 100%;
        height: 25vh;
        border-radius: 8px;
        background-size: cover;
        overflow: hidden;
        position: relative;
        z-index: 22;
    }

    .news-page-img-one-uaisoi img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        transition: transform 0.5s ease;
        cursor: pointer;
    }

    .news-page-one-link-heading {
        font-size: 14.336px;
        line-height: 17.2032px;
        font-weight: 700;
        color: #000;
        text-decoration: none;
    }

    .news-page-img-content {
        padding-top: 7.5vw;
        position: relative;
    }

    .news-page-search-wrapper {
        width: 100vw;
        height: 35vh;
        background-color: #FFF;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .news-page-search-area {
        width: 100%;
        height: 30vh;
        background-color: #D9E5EA;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        gap: 20px;
        padding: 4vh 3vw;
    }

    .news-page-search-box-heading {
        font-size: 16.92px;
        line-height: 21.504px;
        font-weight: 800;
        margin-bottom: 2vh;
    }

    .news-page-border-between {
        display: none;
    }

    .news-page-sign-up-div {
        width: 80%;
        height: 20vh;
        position: relative;
    }

    .news-page-heading-and-side-btn {
        width: 100%;
        height: 100%;
        padding-top: 2vh;
        padding-left: 0vw;
    }

    .news-page-search-input {
        outline: none;
        border: 1px solid #000;
        width: 95%;
        height: 70%;
        padding-left: 10vw;
        border-radius: 5px;
    }

    .news-page-search-icon {
        position: absolute;
        left: 10px;
        margin-top: 1.3vh;
        width: 25px;
        height: 25px;
        filter: brightness(0);
    }

    .news-page-search-btn {
        width: 50vw;
        height: 5vh;
        font-size: 12.544px;
        line-height: 15.0528px;
        font-weight: 600;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: #FFF;
    }

    .news-section-of-news-page {
        width: 100vw;
        height: auto;
        background-color: #fff;
        padding-left: 5vw;
        padding-right: 8vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #000;
        overflow-x: auto;
        margin-bottom: 10vh;
    }

    .news-floating-div {
        width: 100%;
        height: 25vh;
        background-color: #7A736E;
        color: #FFF;
        border-radius: 0px;
        padding: 4vh 4vw;
        border-radius: 5px;
    }

    .content-under-heading-of-news-page {
        display: flex;
        width: 60vw;
        flex-direction: column;
        height: auto;
        padding-top: 6vh;
    }

    .news-heading-of-all-section {
        font-size: 20.504px;
        line-height: 25.8048px;
        font-weight: 800;
    }

    .news-page-small-div {
        font-size: 14.336px;
        line-height: 20.0704px;
        font-weight: 400;
        width: 30vw;
        padding-bottom: 1vh;
    }

    .news-page-heading-and-para {
        width: 90vw;
    }

    .main-heading-of-news {
        font-size: 16.92px;
        line-height: 19.504px;
        font-weight: 800;
        text-decoration: none;
        color: #000000;
    }

    .news-floating-div-heading {
        font-size: 17.92px;
        line-height: 21.504px;
        font-weight: 800;
        color: #FFF;

    }

    .news-floating-div-para {
        font-size: 14.336px;
        line-height: 20.0704px;
        font-weight: 400;
        color: #FFF;
    }

    .news-floating-div-btn {
        font-size: 12.544px;
        line-height: 15.0528px;
        font-weight: 500;
        border: none;
        width: 25vw;
        height: 5vh;
        margin-top: 1vh;
        border-radius: 5px;
        background-color: #fff;
    }

    .see-more-btn-news-page {
        border: 1px solid #000000;
        font-size: 12.544px;
        line-height: 15.0528px;
        font-weight: 500;
        width: 20vw;
        height: 5.5vh;
        background-color: #fff;
        border-radius: 4px;
        margin-top: 3vh;
        margin-bottom: 8vh;
        transition: all ease 0.3s;
    }

    .news-page-last-card-container {
        width: 100vw;
        height: auto;
        background-color: #F1EFED;
        padding: 6vh 5vw;
    }

    .news-page-last-card-heading {
        font-size: 21.504px;
        line-height: 25.8048px;
        font-weight: 800;
    }

    .news-page-card-wrapper {
        display: flex;
        gap: 25px;
        flex-direction: column;
    }

    .news-page-card-1 {
        width: 100%;
        height: 35vh;
        background-color: #fff;
        margin-top: 2vh;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
    }

    .text-span-of-news-page-last-div {
        margin-top: 5vh;
    }
    

}