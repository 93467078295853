.nav-career-banner {
  background: url(../image/career-bg.avif);
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nav-career-banner-wrapper {
  position: relative;
  background-color: #fff;
}

.nav-career-content {
  color: #161513;
  max-width: 50%;
  position: absolute;
  top: 3rem;
  left: 6rem;
  background-color: #fff;
  border-radius: 10px;
  padding: 56px;
  max-height: 448px;
}

.nav-career-heading {
  font-size: 3.5rem;
  color: rgb(22, 21, 19);
  margin-bottom: 0.5rem;
}

[class*="nav-career-heading"]:after {
  content: "";
  display: block;
  width: 40px;
  height: 4px;
  margin: 0.39285em 0 0.423em;
  background: #ca4d3c;
}

.nav-career-para {
  font-size: 1.11rem !important;
  margin-bottom: 1rem;
}

.nav-career-btn {
  background-color: #161513;
  color: #fff;
  border-radius: 5px;
  padding: 0.5rem;
  outline: none;
  border: none;
}

@media (max-width: 850px) {
  .nav-career-banner {
    width: 88%;
    margin-left: 6%;
    min-height: 200px;
  }
  .nav-career-content {
    max-width: 100%;
    position: relative;
    top: 0rem;
    left: 0rem;
    padding: 2rem;
    border-radius: 0px;
    max-height: 100%;
  }
}

@media only screen and (max-width: 770px) {
  .nav-career-banner {
    height: 33vw;
  }
}

.nav-career-info {
  padding: 50px;
}

.nav-career-info {
  position: relative;
  z-index: 1; /* Content layer */
}

.nav-career-info::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../image/rw-pattern16.avif);
  background-size: fill;
  background-position: center;
  opacity: 0.05;
  z-index: -1;
  pointer-events: none;
  mix-blend-mode: initial;
}


.nav-career-learn {
  background-color: #587b86;
  color: #fff;
  padding: 1.5rem 1rem;
}

.nav-career-learn p {
  font-weight: 800;
  margin: 0;
}
.nav-career-learn button {
  font-size: 0.85rem;
  padding: 0.5rem 1rem;
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: transparent;
  color: #fff;
}

@media only screen and (max-width: 720px) {
  .customer-flex {
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .nav-career-learn p {
    font-size: 0.9rem;
  }
}

.nav-lattice-career {
  color: rgb(22, 21, 19);
  font-size: 2rem;
}

.nav-career-video-container {
  position: relative;
  width: 720px;
  height: 420px;
  overflow: hidden;
}
.nav-career-video-container iframe {
  width: 100%;
  height: 100%;
}
.nav-career-video-container img {
  height: 100%;
  width: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.nav-career-video-container:hover img,
.nav-career-video-container .nav-career-play-button:hover ~ img {
  transform: scale(1.1);
}
.nav-career-play-button {
  bottom: 5%;
  right: 5%;
  padding: 25px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
}

.nav-career-play-button img {
  height: 30px;
  width: 30px;
}

.nav-career-video-wrapper {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.nav-career-content-right {
  padding: 78px;
}
.nav-career-content-right h2 {
  font-size: 3rem;
}

.nav-career-job-search {
  text-decoration: none;
  color: rgb(0, 107, 143) !important;
  padding: 0rem;
}

@media only screen and (max-width: 1300px) {
  .nav-career-content-right {
    padding: 50px;
  }

  .nav-career-video-container {
    width: 650px;
  }
}

@media only screen and (max-width: 1150px) {
  .nav-career-content-right {
    padding: 30px;
  }

  .nav-career-video-container {
    width: 575px;
  }
}

@media only screen and (max-width: 950px) {
  .nav-career-content-right h2 {
    font-size: 2rem;
  }

  .nav-career-video-container {
    width: 475px;
  }
}
@media only screen and (max-width: 850px) {
  .nav-career-heading {
    font-size: 3rem;
  }

  .nav-career-video-wrapper {
    flex-direction: column;
  }

  .nav-career-content-right h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .nav-career-content-right {
    padding: 1.5rem;
  }

  .nav-career-video-container {
    width: 100vw;
    height: 60vh;
    margin: 0;
    padding: 0;
  }
}
@media only screen and (max-width: 650px) {
  .nav-career-video-container {
    width: 100vw;
    height: 30vh;
    margin: 0;
    padding: 0;
  }

  .nav-career-play-button {
    padding: 15px;
  }

  .nav-career-play-button img {
    height: 20px;
    width: 20px;
  }
}

.nav-career-section {
  background-color: #2f3337;
  padding: 40px 0px;
}

.nav-career-cards {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 2rem;
}

.nav-career-title {
  color: white;
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-weight: 300;
  padding-bottom: 10px;
  width: fit-content;
}

[class*="nav-career-title"]:after {
  content: "";
  display: block;
  width: 40px;
  height: 4px;
  margin: 0.39285em 0 0.423em;
  background: #f0cc71;
}

.nav-career-slate {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.nav-card-title {
  color: white;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.nav-card-description {
  color: #d1d5db;
  font-size: 0.95rem;
  line-height: 1.5;
  margin-bottom: 15px;
}

.learn-more {
  color: #f0cc71;
  text-decoration: none;
  font-size: 0.95rem;
  margin-top: auto;
}

.learn-more:hover {
  color: #d4b172;
}

.nav-career-grow {
  margin-left: 10rem;
  margin-right: 10rem;
}

@media only screen and (max-width: 1350px) {
  .nav-career-cards {
    grid-template-columns: auto auto;
  }

  .nav-career-grow {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
@media only screen and (max-width: 650px) {
  .nav-career-cards {
    grid-template-columns: auto;
  }

  .nav-career-title {
    color: white;
    font-size: 2rem;
  }

  .nav-career-grow {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

.card-style {
  border-radius: 8px;
  background: #fff;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  height: 180px;
}

.opportunity-image {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  object-fit: cover;
}

.nav-career-opportunity-title {
  font-weight: 700;
}

.nav-career-opportunity-learn {
  text-decoration: none;
  color: #006b8f !important;
  padding: 0;
}

.nav-career-featured-opportunity, .nav-career-place {
  font-weight: 900;
  font-size: 1.5rem;
}

.nav-career-opportunity-box {
  display: grid;
  grid-template-columns: auto auto;
  gap: 2rem;
}
.nav-career-opportunity-wrapper {
  padding: 2rem 10rem;
  background-color: #FBF9F8;
}

@media only screen and (max-width: 1350px) {
  .nav-career-opportunity-wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (max-width: 1200px) {
  .nav-career-opportunity-box {
    grid-template-columns: auto;
    gap: 0rem;
  }
}
@media only screen and (max-width: 850px) {
  .card-style img {
    display: none;
  }

  .nav-career-opportunity-gap {
    margin-left: 0rem !important;
  }

  .card-style {
    height: auto;
  }
}
@media only screen and (max-width: 650px) {
  .nav-career-opportunity-wrapper {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}


.nav-career-social-links {
  position: relative;
  z-index: 1;
  background-color: #36383e;
  padding: 50px;
}

.nav-career-social-links::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../image/career-social-link-bg.png);
  background-size: fill;
  background-position: center;
  opacity: 0.2;
  z-index: -1;
  pointer-events: none;
  mix-blend-mode: overlay;
}

.nav-career-social-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
  width: 100%;
  color: #fff;
}

.nav-career-social-flex span {
  font-size: 1rem;
}

.career-connect p {
  font-size: 2rem;
  margin-bottom: 0rem;
}

.nav-career-social-flex img {
  height: 25px;
  width: 25px;
}
.nav-career-social-flex a {
  text-decoration: none;
}

.career-solcial-flex {
  display: flex;
  justify-content: start;
  align-items: end;
  gap: 0.5rem;
}
.career-solcial-flex span {
  margin: 0;
  padding: 0;
  color: #fff;
}

@media only screen and (max-width: 950px) {
  .nav-career-social-flex {
    flex-wrap: wrap;
  }
  .career-connect {
    width: 100%;
    text-align: center;
  }
}

.nav-career-award-wrapper {
margin: 2rem 10rem;
}

.text-one-rem{
  font-size: 1rem;
}

.career-award-title{
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0rem;
}

.career-awards-box {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 2rem;
}

@media only screen and (max-width: 1350px) {
  .nav-career-award-wrapper {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
@media only screen and (max-width: 1200px) {
  .career-awards-box {
    grid-template-columns: auto auto;
  }
}

@media only screen and (max-width: 850px) {
  .career-awards-box {
    grid-template-columns: auto;
  }
}
@media only screen and (max-width: 650px) {
  .nav-career-award-wrapper {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

.career-awards-box div:nth-child(2), .career-awards-box div:nth-child(5){
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  padding-left: 2rem;
}


.career-awards-box div:only-child{
  height: 130px;
}



@media only screen and (max-width: 1350px) {
  .nav-career-award-wrapper {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
@media only screen and (max-width: 1200px) {
  .career-awards-box div:nth-child(2), .career-awards-box div:nth-child(5){
    border-left:none;
    border-right:none;
    padding-left: 0rem;
  }


  .career-awards-box {
    grid-template-columns: auto auto;
  }

  .career-awards-box > .career-award {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 1rem;
  }

  .career-awards-box > .career-award:nth-child(5),
  .career-awards-box > .career-award:nth-child(6) {
    border-bottom: none;
  }
}

@media only screen and (max-width: 850px) {
  .career-awards-box {
    grid-template-columns: auto;
  }

  .career-awards-box > .career-award:nth-child(5) {
    border-bottom: 1px solid #dcdcdc;
  }

  .career-awards-box div:only-child{
    height: auto;
  }
}
@media only screen and (max-width: 650px) {
  .nav-career-award-wrapper {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

.nav-career-apply{
  padding: 50px;
}

.nav-career-apply-wrapper {
  position: relative;
  z-index: 1;
  padding: 2rem 10rem;
}

.nav-career-apply-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../image/rw-pattern16.avif);
  background-size: fill;
  background-position: center;
  opacity: 0.09;
  z-index: -1;
  pointer-events: none;
  mix-blend-mode: overlay;
}

.apply-box-container {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  height: 200px;
}


.golden-side {
  background-color: rgb(247, 224, 161);
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 250px;
}

.golden-side img {
  width: 40px;
  height: 40px;
}

.apply-today {
  color: #000;
  padding-top: 1rem;
  font-weight: 700;
}

.white-side {
  background-color: #fff;
  flex-grow: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 450px;
}

.text-line {
  font-size: 0.875rem;
  margin-bottom: 20px;
}

.search-job-btn {
  background-color: #fff;
  color: #000;
  padding: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.875rem;
  width: 130px;
}

@media only screen and (max-width: 1150px) {
  .nav-career-apply-main{
   flex-direction: column;
  }

  .apply-box-container {
    width: 100%;
  }
}
@media only screen and (max-width: 850px) {
  .apply-box-container {
    flex-direction: column;
    height: auto;
  }

  .golden-side {
    width: 100%;
    padding: 20px;
  }
  
  .white-side {
    width:100%;
    padding: 20px;
  }
}
@media only screen and (max-width: 1350px) {
  .nav-career-apply-wrapper {
    padding: 2rem;
  }
}
@media only screen and (max-width: 650px) {
  .nav-career-apply-wrapper {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.story-section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 20px 0;
}

.story-card {
  border-radius: 10px;
  width:350px;
}

.story-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  background: url(../image/blog-bg.png);
  height:200px;
  padding: 10px;
  border-radius: 10px;
}

/* Left-aligned text */
.story-text {
  flex: 1;
  margin-right: 10px;
}

.story-quote {
  font-size: 1rem;
  font-style: italic;
  color: #333;
  line-height: 1.4;
}

.story-image img {
  height: 150px;
  width: 150px;
}

.story-details {
  margin-top: 10px;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;
}

.story-heading {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.story-description {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 10px;
}

.read-story-link {
  font-size: 0.875rem;
  color: rgb(0, 107, 143);
  text-decoration: none;
}

.read-story-link:hover {
  text-decoration: underline;
}

.story-blogs{
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap:1rem;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1100px) {
  .nav-career-apply-wrapper {
    padding: 2rem;
  }

  .story-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
  }

  .story-content {
    height:auto;
}

.story-details {
  height: auto;
}
}


