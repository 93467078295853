.create-acc-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: url(../src/image/bg-createaccount.jpg) no-repeat fixed;
    background-size: cover; 
}

.create-acc-container {
    width: 65vw;
    background:#FCFBFA;
    padding: 30px;
    border-radius: 8px;
    
}

.create-acc-heading {
    text-align: center;
    margin-bottom: 40px;
    color: #333333;
    font-size:35px;
    line-height:45.5px;
    vertical-align:baseline;
    letter-spacing:normal;
    word-spacing:0px;
    font-weight: 500;
    font-style:normal;
    font-variant:normal;
    text-transform:none;
    text-decoration:none solid rgb(22, 21, 19);
}

.create-acc-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 40vw;
   padding-left: 8vw;
   
}

.form-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-group label {
    font-size: 14px;
    color: #555555;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group select {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    outline: none;
}

.form-helper {
    font-size: 12px;
    color: #777;
    margin-top: 5px;
}

.create-acc-paragraph {
    font-size: 12px;
    color: #555555;
    line-height: 1.5;
    margin-top: 20px;
}

.create-acc-btn {
    width: 12vw;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background-color:var(--primary-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.create-acc-btn:hover {
    background-color:#161513;
}


/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  
    .create-acc-wrapper {
        padding: 10px;
        background-position: center center; 
    }
 .create-acc-container {
        width: 80vw;
        padding: 20px;
    }

    /* Adjust heading font size and margin */
    .create-acc-heading {
        font-size: 28px; /* Smaller font size on mobile */
        margin-bottom: 20px; /* Less space below heading */
    }

    /* Form should occupy the full width of the screen */
    .create-acc-form {
        width: 70vw;  
        padding-left: 0;  
    }

    /* Stack the form fields vertically with a little spacing */
    .form-row {
        flex-direction: column;
        gap: 15px;
    }

    /* Full width for form groups */
    .form-group {
        width: 80%;
        padding-left: 5vw;
    }

    /* Adjust input and select fields to be more mobile-friendly */
    .form-group input,
    .form-group select {
        font-size: 16px; /* Larger font size for easier typing */
        padding: 12px; /* Adjust padding for mobile touch */
    }

    .create-acc-btn {
        width: 50%; 
        height: 6vh;
        font-size: 18px; 
        padding: 12px 20px; 
    }

    /* Adjust text paragraph size */
    .create-acc-paragraph {
        font-size: 14px;  
    }
}

/* For very small screens (portrait mobile view) */
@media screen and (max-width: 480px) {
    
    .create-acc-wrapper {
        padding: 5px;
    }

    .create-acc-container {
        width: 100%;  
        padding: 15px;  
    }

    .create-acc-heading {
        font-size: 24px; 
        margin-bottom: 15px; 
    }

    /* Adjust the form layout */
    .create-acc-form {
        width: 100%; 
    }

    .form-row {
        flex-direction: column; 
        gap: 10px;
    }

    .form-group input,
    .form-group select {
        font-size: 16px;
        padding: 10px;
    }

    /* Adjust button styling */
    .create-acc-btn {
        width: 50%;  
        font-size: 18px;
    }

    .create-acc-paragraph {
        font-size: 13px;
    }
}