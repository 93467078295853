
body {
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #161513;
    background-color: #FBF9F8;
  }


.Lattice-blog-div{
 width: 100vw;
 height: 35vh;
 background-color:#264759;
 background: url(../image/developer-img/pattern-of-blog-company.png) contain 0.1;
 display: flex;
 justify-content: space-between;
 padding-left: 5vw;
 padding-right: 8vw;
 align-items: center;
 color: #FFF;
 z-index: 1;
}
.Lattice-blog-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background: url("../image/developer-img/pattern-of-blog-company.png") center;
    background-size: contain;
    opacity: 0.03; /* Adjust this value for the desired transparency */
    z-index: 1;
  }
  
.Lattice-blog-div-heading-h1{
    font-size: 32.256px;
    line-height: 38.7072px;
    font-weight: 700;
}
.heading-below-para-of-blog{
    font-size: 16.128px;
    line-height: 19.3536px;
    font-weight: 500;
    margin-top: 2vh;
}
.Lattice-blog-div-icon{
    font-size: 20px;
    line-height:  28px;
    font-weight: 700;
}
.img-wifi{
    width: 30px;
    height: 30px;
    margin-top: -1vh;
    margin-left: 1vw;
}
.blogs-container{
    width: 100vw;
    height: 90vh;
    background-color: #FBF9F8;
    padding-left: 5vw;
    position: relative;
    padding-right: 5vw;
}
.upper-area-img{
    width: 90vw;
    height: auto;
    border-radius: 10px;
    background-color: #fff;
    z-index: 20;
    top: -5vh;
    position: absolute;
    display: flex;
    gap: 25px;
    padding: 4vh 4vw;
}
.img-of-one-div-of-uai{
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 5vh;
}
.heading-of-img{
    font-size: 28.672px;
    line-height: 34.4064px;
    font-weight: 700;
    color:rgb(0, 0, 0);
    text-decoration: none;
     
}
.heading-of-img:hover{
    text-decoration: underline 2px solid rgb(0, 0, 0); 
}
.para-of-img1{

    font-size: 14.336px;
    line-height: 20.0704px;
    font-weight: 400;
    color:rgb(0, 0, 0);
    text-decoration: none;
}
.para-of-img1:hover{
    text-decoration: underline 1px solid rgb(0, 0, 0); 
}
.para-of-img-time{
    font-size: 14.336px;
    line-height:  20.0704px;
    font-weight: 400;
}
.upper-area-img-sec{
    display: flex;
    width: 60vw;
    height: auto;
    flex-direction: column;
    gap: 25px;

}
.upper-area-img-sec-one-img{
    display: flex;
    gap: 15px;
}
.img-one-uaisoi{
    width: 17vw;
    height: auto;
    border-radius: 8px;
    background-size: cover;
}
.heading-of-img{
    font-size: 28.672px;
    line-height: 34.4064px;
    font-weight: 700;
    color: #000;
    text-decoration: none;
}
.heading-of-img:hover{
    text-decoration: underline 2px solid rgb(0, 0, 0); 
}
.one-link-heading{
    font-size:  14.336px;
    line-height: 17.2032px;
    font-weight: 700;
    color: #000;
    text-decoration: none;
}
.one-link-heading:hover{
    text-decoration: underline 1px solid rgb(0, 0, 0); 
}
.img-content{
    padding-top: 5vw;
    position: relative;
}
.search-wrapper-blog{
    width: 100vw;
    height: auto;
    background-color: #FBF9F8;
    padding-left: 5vw;
    padding-right: 5vw;
    margin-bottom: 20px;
}
.search-area-of-blog{
    width: 100%;
    height: 20vh;
    background-color: #EDF6F9;
    display: flex;
    border-radius: 5px;
    gap: 20px;
    padding: 4vh 3vw;
   
}
.search-box-heading{
    font-size: 17.92px;
    line-height: 21.504px;
    font-weight: 800;
    margin-bottom: 2vh;
}
.border-between-blog{
    width: 0.16vw;
    height: 11vh;
    background-color: #D7DFE2;
    
}
.sign-up-blog-1-div{
    width: 50%;
    height: 100%;
    position: relative;
}
.heading-and-side-btn-of-search{
    width: 50%;
    height: 100%;
    padding-left: 3vw;
}
.search-input-of-blog{
    outline: none;
    border: 1px solid #000;
    width: 95%;
    height: 50%;
    padding-left: 3vw;
    border-radius: 5px;
}
.search-icon-blog {
    position: absolute;
    left: 10px;
    margin-top: 1.3vh;
    width: 25px;
    height: 25px;
    filter: brightness(0);
    
  }
  .search-input-of-blog::placeholder {
    color: #aaa;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .search-input-of-blog:focus::placeholder {
    opacity: 1; 
  }
  .search-btn-of-blog{
    width: 19vw;
    height: 5vh;
    font-size: 12.544px;
    line-height: 15.0528px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    background-color: var(--primary-color);
    color: #FFF;
  }
  .search-btn-of-blog:hover{
    background-color: #161513;
  }
  .blog-container-all{
    padding-left: 5vw;
    padding-right: 5vw;
    width: 100vw;
    height: auto;
    background-color: #FBF9F8;
    margin-bottom: 25px;
  }
  .horizontal-line-of-blog{
    width: 100%;
    height: 0.2vh;
    background-color:  #D7DFE2;
  }
  .fist-blog-of-container {
    width: 100vw;
    height: auto;
    padding: 6vh 0;
  }
  
  .blog-card-all-heading {
    font-size: 21.504px;
    line-height: 25.8048px;
    font-weight: 800;
  }
  
  .blog-card-all-container {
    width: 90%;
    overflow: hidden;
    position: relative;
  }
  
  .blog-card-all-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .blog-card-all-controls {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .blog-card-all-see-all {
    font-size: 16px;
    color: #0073e6;
    text-decoration: none;
    margin-right: 15px;
  }
  
  .blog-card-all-arrow {
    width: 40px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .blog-card-all-arrow svg {
    width: 18px;
    height: 18px;
  }
  
  .blog-card-all-arrow.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .blog-card-all-arrow:hover:not(.disabled) {
    border-color: #000000;
  }
  
  .blog-card-all-slider {
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
  }
  
  .blog-card-all-track {
    display: flex;
    width: calc(100% * 2);
    transition: transform 0.5s ease;
    will-change: transform;
  }
  
  .blog-card-all-item {
    flex: 0 0 24%;
    margin-right: 15px; 
    background-color: #f9f9f9;
  }
  
  .blog-card-all-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .blog-card-all-item h3 {
    font-size: 17.92px;
    line-height: 21.504px;
    font-weight: 700;
    color: #000;
  }
  
  .blog-card-all-item .blog-card-all-link {
    font-size: 12px;
    color: #28AEE1;
    text-decoration: none;
  }
  .blog-card-all-link:hover{
   text-decoration: underline 1px solid #28AEE1;
  }
  .blog-card-all-item h3:hover{
    text-decoration: underline 2px solid #000000;
  }
  .blog-card-all-footer {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    color: #555;
    margin-top: 10px;
  }
  
  .blog-card-all-divider {
    color: #ccc;
    font-weight: bold;
  }
  
  .blog-card-all-duration {
    font-size: 14px;
    color: #555;
  }
  .horizontal-line-of-blog2{
    width: 100%;
    height: 0.2vh;
    background-color:  #D7DFE2;
  }
  .last-div-of-blog{
    width: 100vw;
    height: auto;
    background-color: #264759;
    display: flex;
    justify-content: space-between;
    padding: 4vh 5vw;
    
  }
  .last-heading-of-blog{
    font-size: 21px;
    line-height: 25.8048px;
    font-weight: 800;
    color: #FFF;
  }
  .last-blog-btns{
    font-size:12.544px;
    line-height: 15.0528px;
    font-weight:600;
    width: 20vw;
    height: 5vh;
    padding: 4px 6px;
    border: none;
    background-color: #FFF;
    margin-right: 4vw;
    margin-top: 1vh;
    border-radius: 4px;
  }
  .last-img-of-blog{
    filter: brightness(0);
    width: 15px;
    height: 15px;
   margin-left: 3px;
  }
  .last-blog-btns:focus{
    border: none;
    outline: none;
    position: relative; 
  }
  .last-blog-btns:focus::before {
    content: ""; 
    position: absolute;
    top: 2px; 
    left: 2px;
    right: 2px;
    bottom: 2px;
    border: 2px dotted black; 
    pointer-events: none; 
}
.last-blog-btns:hover{
  background-color: #f9f9f9; 
}

@media (max-width:600px) {



  body {
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #161513;
    background-color: #FBF9F8;
  }


.Lattice-blog-div{
  width: 100vw;
  height: auto;
  flex-direction: column;
  display: flex;
  justify-content: start;
  padding-left: 0vw;
  padding-right: 0vw;
  align-items: start;
  padding: 4vh 4vw;

}
.Lattice-blog-div-heading-h1{
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
}
.Lattice-blog-div-icon{
    font-size: 19px;
}
.img-wifi{
    width: 25px;
    height: 25px
}
.blogs-container{
    width: 100vw;
    height: auto;
    background-color: #FBF9F8;
    padding-left: 4vw;
    position: relative;
    padding-right: 4vw;
}
.upper-area-img{
    width: 90vw;
    height: auto;
    border-radius: 10px;
    background-color: #fff;
    z-index: 20;
    top: 0vh;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 4vh 6vw;
}
.img-of-one-div-of-uai{
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 5vh;
}
.heading-of-img{
    font-size: 19px;
    line-height: 28px;
    font-weight: 700;
}
.upper-area-img-sec{
    display: flex;
    width: 100vw;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.upper-area-img-sec-one-img{
    display: flex;
    gap: 15px;
    flex-direction: column;
}
.img-one-uaisoi{
    width: 90%;
    height: auto;
    border-radius: 8px;
    background-size: cover;
}
.img-content{
    padding-top: 5vw;
    position: relative;
}
.search-wrapper-blog{
    width: 100vw;
    height: auto;
    background-color: #FBF9F8;
    padding-left: 4vw;
    padding-right: 4vw;
    margin-bottom: 4vh;
}
.search-area-of-blog{
    width: 100%;
    height: auto;
    background-color: #EDF6F9;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    gap: 10px;
    padding: 2vh 2vw;
}
.search-box-heading{
    font-size: 14.92px;
    line-height: 19.504px;
    font-weight: 800;
    margin-bottom: 1vh;
    margin-top: 2vh;
}
.border-between-blog{
    display: none;
}
.sign-up-blog-1-div{
    width: 50%;
    height: 90%;
    position: relative;
}
.heading-and-side-btn-of-search{
    width: 50%;
    height: 100%;
    padding-left: 2vw;
}
.search-input-of-blog{
    outline: none;
    border: 1px solid #000;
    width: 85vw;
    height: auto;
    padding: 10px;
    padding-left: 10vw;
    border-radius: 5px;
}
.search-icon-blog {
    position: absolute;
    left: 10px;
    margin-top: 1.3vh;
    width: 25px;
    height: 25px;
    filter: brightness(0);
    
  }
  .search-input-of-blog::placeholder {
    color: #aaa;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .search-input-of-blog:focus::placeholder {
    opacity: 1; 
  }
  .search-btn-of-blog{
    width: 70vw;
    height: 4.5vh;
    font-size: 12.544px;
    line-height: 15.0528px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    background-color: var(--primary-color);
    color: #FFF;
  }
  .search-btn-of-blog:hover{
    background-color: #161513;
  }
  .blog-container-all{
    padding-left: 5vw;
    padding-right: 5vw;
    width: 100vw;
    height: auto;
    background-color: #FBF9F8;
  }
  .horizontal-line-of-blog{
    width: 100%;
    height: 0.2vh;
    background-color:  #D7DFE2;
  }
  .fist-blog-of-container {
    width: 100vw;
    height: auto;
    padding: 6vh 0;
  }
  
  .blog-card-all-heading {
    font-size: 17px;
    line-height: 21.8048px;
    font-weight: 800;
  }
  
  .blog-card-all-container {
    width: 85%;
    overflow: hidden;
    position: relative;
  }
  
  .blog-card-all-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .blog-card-all-controls {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .blog-card-all-see-all {
    font-size: 14px;
    color: #0073e6;
    text-decoration: none;
    margin-right: 10px;
    width: 15vw;
  }
  
  .blog-card-all-arrow {
    width: 30px;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .blog-card-all-arrow svg {
    width: 18px;
    height: 18px;
  }
  
  .blog-card-all-arrow.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .blog-card-all-arrow:hover:not(.disabled) {
    border-color: #000000;
  }
  
  .blog-card-all-slider {
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    overflow-x: auto;
  }
  
  .blog-card-all-track {
    display: flex;
    width: calc(100% * 1);
    transition: transform 0.5s ease;
    will-change: transform;
  }
  
  .blog-card-all-item {
    flex: 0 0 100%;
    margin-right: 15px; 
    background-color: #f9f9f9;
  }
  
  .blog-card-all-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .blog-card-all-footer {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    color: #555;
    margin-top: 10px;
  }
  .last-div-of-blog{
    width: 100vw;
    height: auto;
    background-color: #264759;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4vh 4vw;
  }
  .last-heading-of-blog{
    font-size: 20px;
    line-height: 21.8048px;
    font-weight: 800;
    color: #FFF;
  }
  .last-blog-btns{
    font-size:12.544px;
    line-height: 15.0528px;
    font-weight:600;
    width: 75vw;
    height: 4.5vh;
    padding: 4px 4px;
    border: none;
    background-color: #FFF;
    margin-right: 4vw;
    margin-top: 1vh;
    border-radius: 4px;
  }
  .last-img-of-blog{
   margin-left: 4px;
  }
}