.contact-intro-img{
    width: 100vw;
    height: 35vh;
    background-color:#F5F4F2;

}
.contact-intro-img {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    padding: 20px; 
  }
  
  .intro-heading {
    padding-left: 5vw;
    font-size: 32.256px;
    line-height:38.7072px;
    vertical-align:baseline;
    letter-spacing:normal;
    word-spacing:0px;
    font-weight:400;
    flex: 1; 
  }
  
  .intro-heading h1 {
    margin-bottom: 25px;
    font-size: 2rem; 
  }
 
  .contact-btn-1{
    width: 10vw;
    height: 5.5vh;
    margin-right: 10px;  
    font-size:12.544px;
    line-height:15.0528px;
    vertical-align:baseline;
    letter-spacing:normal;
    word-spacing:0px;
    font-weight:500;
    cursor: pointer;
    border: none; 
    border-radius: 5px; 
    background-color: var(--primary-color); 
    color: #fff;
  }
  .contact-btn-2{
    width: 10vw;
    height: 5.5vh;
    margin-right: 10px;  
    font-size:12.544px;
    line-height:15.0528px;
    vertical-align:baseline;
    letter-spacing:normal;
    word-spacing:0px;
    font-weight:500;
    cursor: pointer;
    border: none; 
    border: 1px solid black;
    border-radius: 5px; 
    transition: all 0.5s ease;
  }
  .contact-btn-1:hover{
    background-color: #161513;
  }
  .contact-btn-2:hover{
    background-color: #161513;
    color: white;
  }

  .intro-heading button:last-child {
    margin-right: 0; 
  }
  
  .intro-img {
    flex: 1; 
    height: 300px; 
    background-image: url('./image/ContactPage-BG-img.png'); 
    background-size: contain; 
    background-position: center; 
    background-repeat: no-repeat;
    position: relative;
    bottom: -4vh;
  }
/* Main container for flexbox layout */
.con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40vh;
    width: 100vw;
    padding: 1rem;
    margin-bottom: 5vh;
   
  }
  
  .con1,
  .con2,
  .con3 {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 33.33%;
    text-align: left;
    font-size: 17.92px;
    font-weight: 800;
    line-height: 21.504px;
    padding-left: 5rem;
    padding-right: 5rem;
  }
  
  .con a {
    color:#0098D0;
  }
  
  .con1 {
    border-right: 2.5px solid #E9E7E4;
    height: 70%;
    align-items: center;
  
  }
  
  .con2 {
    border-right: 2.5px solid #E9E7E4;
    height: 70%;
    align-items: flex-start;
  
  }
  .con3{
    height: 70%;
    align-items: flex-start;
    
  }
  
  .message-info,
  .link {
    background-color: rgba(0, 0, 0, 0);
    font-size: 14.336px;
    font-weight: 400;
    line-height: 20.0704px;
    text-decoration: none;
  }
  
  .con1-image {
    align-self: flex-start;
    width: 3rem;
    height: auto;
    margin-bottom: 10px;
  
  }
  
  .con2-image,
  .con3-image {
    width: 3rem;
    height: auto;
    margin-bottom: 10px;
   
  }

  





.heading-of-4-divs{
  font-size:28px;
  line-height:36px;
  vertical-align:baseline;
  letter-spacing:normal;
  word-spacing:0px;  
  font-weight:700;
  font-style:normal;
  font-variant:normal;
  text-transform:none;
  text-decoration:none solid rgb(22, 21, 19);
  text-align:start;
  text-indent:0px;
  color: #000000;
  padding-left: 5vw;
  margin-bottom: 5vh;
  margin-top: 8vh;
}
.contact-image{
    width: 30px;
    height: auto;
    margin-bottom: 15px;
    margin-top: 20px;
}
  .contactpage-logo {
    width: 45px;
    height: auto;
    margin-bottom: 15px;
  }
  
  .contactpage-heading {
    margin-bottom: 10px;
    text-align: left;
    font-size:20px;
    line-height:24px;
    vertical-align:baseline;
    letter-spacing:normal;
    word-spacing:0px;
    font-weight:700;
  }
  
  .contactpage-paragraph {
    color: #333;
    margin-bottom: 15px;
    text-align: start;
    font-size:16px;
    line-height:24px;
    vertical-align:baseline;
    letter-spacing:normal;
    word-spacing:0px;
    font-weight:400;
  }
  
  .contactpage-link {
    color: #0098D0;
    text-decoration: none;
    text-align: start;
    font-size:16px;
    line-height:22.4px;
    vertical-align:baseline;
    letter-spacing: normal;
    word-spacing:0px;
    font-weight:400;
  }
  .contact-heading-of-div{
    padding-left: 4vw;
    position: absolute;
    top: 3.3vh;
    font-size:18px;
    line-height:normal;
    vertical-align:baseline;
    letter-spacing:normal;
    word-spacing:0px;
    font-weight:600;
    text-align: left;
  }
.contact-paragrapg-of-div{
  padding-left: 4vw;
  font-size:16px;
  line-height:normal;
  vertical-align:baseline;
  letter-spacing:normal;
  word-spacing:0px;
  font-weight:400;
  text-align: left;
}

/* Main container */
.contact-container {
  display: flex;
  flex-direction: column;
  padding-left: 5vw;
  padding-right: 5vw;
}

/* Contact item styles */
.contact-item {
  background-color:#47423E;
  color: white;
  padding-left: 4vw;
  padding-right: 2vw;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  margin-bottom: 3vh;
}

/* When the contact item is expanded */
.contact-item.expanded {
  background-color:#5C5651;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none; 
}


/* Arrow styles */
.arrow-container {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.arrow {
  transform: rotate(-90deg);
  transition: transform 0.3s ease;
}

.arrow.up {
  transform: rotate(90deg); /* Rotate to point down */
}

/* Expanded content styles */
.expanded-content {
  background-color: #E4E1DD;
  position: relative;
  width: 100%;
  height: 52vh;
  padding-left: 2vw;
  padding-top: 3vh;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius:  0.5rem;
  top: -3vh;
}
.expand-item:hover{
  transform: scale(1.035);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, .2);
}

.expanded-content .row {
  display: flex;
  gap: 20px;
}

.expand-item {
  flex: 1;
  background-color: white;
  color: black;
  height: 45vh;
  border-radius: 8px;
  transition: transform .25s cubic-bezier(0.4, 0, 0.2, 1), box-shadow .3s cubic-bezier(0.4, 0, 0.2, 1);
  padding-top: 5vh;
  padding-left: 2vw;
  padding-right: 2vw;
}

  





  







  .contact-dpage {
    width: 100vw;
    height: 90vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color:#F5F4F2;
  }
  .contact-A{
    padding-top: 3vh;
    padding-left: 4vw;
    height: 15vh;
    background-color:#F5F4F2;
    font-size:21.504px;
    line-height:25.8048px;
    vertical-align:baseline;
    letter-spacing:normal;
    word-spacing:0px;
    font-weight:800;
    display: flex;
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
  }

  .contact-list-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 14.336px;
    line-height: 20.0704px;
    letter-spacing: normal;
    text-align: left;
    word-spacing: 0px;
    position: relative;
    border-bottom: 3px solid #E9E7E4;
  
  }
  
  .contact-list {
    display: flex;
    list-style-type: none;
    padding-left: 3vw;
    padding-right: 3vw;
    background-color:#F5F4F2;
   
  }
  
  .contact-list-item {
    margin-right: 20px;
    cursor: pointer;
    padding: 5px;
    position: relative;
  
  }
  
  .contact-list-item::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2.8vh;
    width: 100%;
    height: 3px;
    background-color: transparent;
    transition: background-color 0.3s ease;
  }
  
  .contact-list-item:hover::after {
    background-color: #4C825C;
  }
  
  /* Underline effect for the selected item */
  .contact-list-item.active {
    text-decoration: underline;
  }
  
  /* Flex container for the first and second divs */
  .contact-flex-container {
  
    padding-left: 5vw;
    display: flex;
    justify-content: space-between;
    color: #000000;
    width: 100vw;
    height: 100vh;
    position: relative;
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
  }
  
  /* First div (for content) */
  .contact-first-div {

    position: absolute;
    padding-right: 45vw;
    top: 5vh;
    width: 100vw;
    height: 50vh;
    background-color:#F5F4F2;
    text-align: start;
    left: 3vw;
    padding-bottom: 2vh;
    font-size:21.504px;
    line-height:25.8048px;
    vertical-align:baseline;
    letter-spacing:normal;
    word-spacing:0px;
    font-weight:800;
    color: var(--primary-color);
    overflow-y: auto;
  }
  
  .contact-content-div {
    display: none;
    transition: opacity 5s ease-in-out;
  }
  
  .contact-content-div.active {
    display: block; 
    opacity: 1; 
  }
  
  .team {
    display: flex;
    width: 100vw;
    height: 35vh;
    font-size: 14.336px;
    line-height: 20.0704px;
    vertical-align: baseline;
    letter-spacing: normal;
    word-spacing: 0px;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    text-decoration: none solid rgb(22, 21, 19);
    text-align: left;
    text-indent: 0px;
    padding-left: 2vw;
    padding-right: 2vw;
    margin-bottom: 5vh;
  }
  
  .teamA,
  .teamB {
    flex: 1;
    padding: 4rem;
    box-sizing: border-box;
  }
  
  .teamA {
    background-color: transparent;
  }
  
  .teamB {
    background-color: transparent;
  }
  
  .title {
    font-size: 21.504px;
    word-spacing: 0px;
    line-height: 25.8048px;
    vertical-align: baseline;
    letter-spacing: normal;
    font-weight: 800;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    text-decoration: none solid rgb(22, 21, 19);
    text-align: start;
    text-indent: 0px;
  }
  .content-of-contactPage{
    font-size: small;
    font-weight: 600;
  }



  @media (max-width: 768px) {


    body {
      margin: 0;
      padding: 0;
      width: 100vw;
      height: auto;
      overflow-x: hidden; /* Prevent horizontal scroll caused by overflow */
    }

    .contact-intro-img {
   
      height: auto; 
      padding: 10px;
    }
  
    .intro-heading {
      font-size: 24px; 
      text-align: start;
   
    }

    .contact-btn-1, .contact-btn-2 {
      width: 20vw; 
      height: 4.5vh;
      font-size: 12px;
    }

    .contact-dpage {
      height: 110vh; 
      box-sizing: border-box;
     
    }
  
    .contact-A {
      font-size: 16.5px; 
      line-height: 22px;
      margin-left: 2vw;     
      width: 50%;
    }
  
    .contact-list-container {
      flex-direction: column; 
      align-items: center; 
      margin-top: 0;
      padding: 0;
    }
  
    .contact-list {
      display: block;
      list-style: circle;
      width: 100%;
      padding: 2vw;
      padding-left: 8vw;
    }
  
    .contact-list-item {
      margin-bottom: 10px; 
      margin-right: 0; 
      font-size: 14px; 
      border: 2px solid black;
    }
  
    .contact-list-item::after {
      bottom: -5px; 
  
    }
  
    /* Content Flex Container */
    .contact-flex-container {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
     
      width: 100%; 
    }
  
    .contact-first-div {
      width: 90%; /* Fit within mobile width */
      padding: 10px; 
      font-size: 18px; 
      line-height: 22px; 
      height: auto; 
      top: 0;
      position: relative;
    }
  
    .contact-content-div {
      font-size: 16px; 
      line-height: 20px;
    }
  
    /* Buttons */
    .contact-btn-1, .contact-btn-2 {
      width: 90%; 
      margin: 5px 0; 
    }



  /* 6. Adjust layout for team sections */
  .team {
    flex-direction: column;
    height: auto;
  }

  .teamA,
  .teamB {
    width: 95vw;
    padding: 2rem;
    box-sizing: border-box;
  }

  .con {
    flex-direction: column;
    height: auto;
    padding: 1rem 0;
    margin-bottom: 2vh;
  }

  .con1,
  .con2,
  .con3 {
    width: 100vw;
    height: auto;
    padding: 1rem;
    text-align: left;
    border-right: none;
    border-bottom: 2.5px solid #E9E7E4;
  }

  .con1:last-child,
  .con2:last-child,
  .con3:last-child {
    border-bottom: none;
  }

  .con1-image,
  .con2-image,
  .con3-image {
    width: 2.5rem;
    margin-bottom: 10px;
  }

  .message-info,
  .link {
    font-size: 14px;
    text-align: left;
  }
  .expanded-content {
    height: auto;
    padding-bottom: 2vh;
    padding-top: 2vh;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius:  0.5rem;
    top: -3vh;
    padding-right: 0;
  }
  .expand-item {
   width: 94%;
   margin-left: 3vw;
  }
  .contact-heading-of-div{
    padding-left: 9vw;
  }
.contact-paragrapg-of-div{
  padding-left: 9vw;
 margin-top: 1vh;
}
  

}